<template>
  <sw-filters
    :shorts="[
      {
        title: $t('ActivityDate'),
        value: () => filters.timeRange,
        clear: () => {
          filters.timeRange = ''
          updateFilter()
        }
      },
      {
        title: $t('Funnels'),
        value: () => filters.funnel ? filters.funnel.name : null,
        clear: () => {
          filters.funnel = ''
          updateFilter()
        }
      },
      {
        title: $t('AssignedUser'),
        type: 'avatar',
        value: () => assignedUsers,
        clear: () => {
          assignedUsers = []
          filters.contacts = ''
          updateFilter()
        }
      },
      {
        title: $t('Contact'),
        value: () => filters.contacts ? `${filters.contacts.firstName} ${filters.contacts.lastName}` : null,
        clear: () => {
          filters.contacts = ''
          updateFilter()
        }
      },
    ]"
  >
    <b-row>
      <b-col
        sm="12"
        md="4"
      >
        <sw-select :name="$t('ActivityDate')">
          <sw-select-fpr
            :is-empty="filters.timeRange"
            @clear="() => { filters.timeRange = ''; updateFilter() }"
          >
            <flat-pickr
              v-model.trim="filters.timeRange"
              class="form-control"
              :config="{ mode: 'range', dateFormat: 'd-m-Y H:i', enableTime: true, time_24hr: true, locale: getCalendarLocale($i18n.locale) }"
              @input="updateFilter"
            />
          </sw-select-fpr>
        </sw-select>
      </b-col>
      <b-col
        sm="12"
        md="4"
      >
        <sw-select :name="$t('Funnels')">
          <v-select
            v-model="filters.funnel"
            :options="funnels"
            label="name"
            @input="updateFilter"
          />
        </sw-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        sm="12"
        md="4"
      >
        <sw-select :name="$t('AssignedUser')">
          <assigned-users
            :value="assignedUsers"
            :disable-current-user="false"
            @input="changeAssignedUsers"
          />
        </sw-select>
      </b-col>
      <b-col
        sm="12"
        md="4"
      >
        <b-form-group :label="$t('Contact')">
          <sw-select
            :disabled="!contacts.length"
            class="mb-0"
          >
            <v-select
              v-model="filters.contacts"
              :options="contacts"
              :disabled="!contacts.length"
              label="firstName"
              @input="updateFilter"
            >
              <template #option="{ firstName, lastName }">
                {{ firstName }} {{ lastName }}
              </template>
              <template #selected-option="{ firstName, lastName }">
                {{ firstName }} {{ lastName }}
              </template>
            </v-select>
          </sw-select>
        </b-form-group>
      </b-col>
    </b-row>
  </sw-filters>
</template>

<script>
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import moment from 'moment'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import { GET_FUNNELS, GET_USER_CONTACTS } from '@/@constants/mutations'

export default {
  name: 'TimelineFilters',
  components: {
    flatPickr,
    vSelect,
    AssignedUsers,
  },
  props: {
    defaultAssigned: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    filters: {
      timeRange: '',
      contacts: '',
      funnel: '',
    },
    assignedUsers: [],
    contacts: [],
    funnels: [],
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
  },
  mounted() {
    if (Array.isArray(this.defaultAssigned) && this.defaultAssigned.length) {
      this.changeAssignedUsers(this.defaultAssigned)
    }

    this.getFunnels()
    const todayStart = `${moment(new Date()).format('DD-MM-YYYY')} 00:00`
    const todayEnd = `${moment(new Date()).format('DD-MM-YYYY')} 23:59`
    this.filters.timeRange = [todayStart, todayEnd]
    // this.filters.timeRange = `${todayStart} ${this.$i18n.t('To')} ${todayEnd}`
  },
  methods: {
    updateFilter() {
      const filters = {
        timeRange: Array.isArray(this.filters.timeRange) ? this.filters.timeRange.join(' do ') : this.filters.timeRange,
        contacts: this.filters.contacts ? [this.filters.contacts.id] : [...this.contacts.map(contact => contact.id)],
        funnel: typeof this.filters.funnel === 'object' ? this.filters.funnel?.id || '' : this.filters.funnel,
      }
      this.$emit('reload-timeline', filters)
    },
    changeAssignedUsers(payload) {
      this.assignedUsers = payload

      if (!payload.length) {
        this.contacts = []
        this.filters.contacts = ''
        this.updateFilter()
        return
      }

      this.$nextTick(() => {
        this.getAssignedUsersContacts()
      })
    },
    getAssignedUsersContacts() {
      const payload = {
        filters: {
          assignedUsers: this.assignedUsers,
        },
        customFields: this.$fields.CONTACTS_SEARCH,
      }
      this.$store.dispatch(`contacts/${GET_USER_CONTACTS}`, payload)
        .then(res => {
          this.contacts = res.data.items
          this.$nextTick(() => {
            this.updateFilter()
          })
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    getFunnels() {
      this.$store.dispatch(`funnelMain/${GET_FUNNELS}`)
        .then(res => {
          this.funnels = res
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
  },
}
</script>

<style scoped>

</style>
