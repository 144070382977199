<template>
  <component
    :is="isMobile ? 'div' : 'app-timeline-item'"
    variant="success"
    icon="FileIcon"
    class="pb-2 d-flex flex-column justify-content-start align-items-start position-relative"
    style="row-gap: .34rem"
  >
    <slot />

    <component
      :is="'b-card'"
      class="w-100 rounded-lg mb-0 position-relative"
      no-body
    >
      <b-card-body :class="`p-${ isContact ? '0' : '1' }`">
        <div
          class="d-flex mb-50"
          style="column-gap: .5rem"
        >
          <b-avatar
            v-if="isMobile"
            rounded="sm"
            size="sm"
            variant="light-success"
          >
            <span class="d-flex align-items-center">
              <feather-icon
                icon="FileIcon"
                size="14"
              />
            </span>
          </b-avatar>

          <router-link
            v-if="thread && !isContact"
            class="font-small-3"
            :class="{ 'deleted-text': thread.contact.deletedAt }"
            :to="`/contact/${thread.contact.id}/thread/${thread.id}/timeline`"
          >
            {{ thread.contact.firstName }} {{ thread.contact.lastName }} – {{ thread.name }}
          </router-link>

          <p
            v-else
            class="mb-0"
          >
            {{ $t('CurrentContact') }}
          </p>
        </div>

        <div
          v-if="thread"
          class="font-weight-bold w-100 d-flex flex-row flex-wrap justify-content-between"
          style="column-gap: .64rem; row-gap: .34rem;"
        >
          <div
            class="d-flex flex-row flex-wrap align-items-center"
            style="column-gap: .64rem; row-gap: .34rem;"
          >
            <div
              class="d-flex flex-wrap flex-row align-items-center"
              style="column-gap: .5rem; row-gap: .34rem;"
            >
              <b-badge
                v-if="automation && automation.automationProcedure"
                class="mr-25"
                variant="primary"
              >
                <feather-icon icon="CpuIcon" />
                {{ $t('Automation') }}: {{ automation.automationProcedure.name }}
              </b-badge>
              <b-badge
                v-if="data.contactThreadNoteType"
                variant="light-primary"
                class="d-flex flex-wrap flex-row align-items-center"
                style="row-gap: .34rem;"
              >
                <div
                  class="d-flex flex-wrap flex-row align-items-center"
                  style="row-gap: .34rem; column-gap: .34rem;"
                >
                  <feather-icon icon="FileIcon" />
                  {{ $t('Note') }}
                </div>

                <feather-icon
                  icon="ArrowRightIcon"
                  class="ml-25 mr-50"
                />

                <div
                  class="d-flex flex-wrap flex-row align-items-center"
                  style="row-gap: .34rem; column-gap: .34rem;"
                >
                  <feather-icon :icon="data.contactThreadNoteType.icon" />
                  {{ data.contactThreadNoteType.name }}
                </div>
              </b-badge>

              <div
                class="d-flex flex-row align-items-center"
                style="column-gap: .5rem; row-gap: .34rem;"
              >
                <small class="text-lowercase">{{ $t('FromTheDay') }}</small>

                <span class="font-italic">{{ formatDate(timelineDate, { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}</span>
              </div>
            </div>

            <div
              class="d-flex flex-wrap flex-row align-items-center"
              style="column-gap: .34rem; row-gap: .5rem"
            >
              <avatar
                :user="data.createdBy"
                size="sm"
                show-tooltip
                :show-name="false"
                class="mx-n25"
              />

              <template v-if="data.sharedRanks.length">
                <span class="font-small-2 text-lowercase">{{ $t('SharedFor') }}</span>

                <div>
                  <b-avatar-group
                    v-if="data.sharedRanks.length > 1"
                    size="sm"
                  >
                    <b-avatar
                      v-for="(rank, uindex) in data.sharedRanks"
                      :key="`user_${rank.id}_${uindex}_${data.id}`"
                      v-b-tooltip.hover
                      :title="rank.name"
                      class="pull-up"
                      :text="rank.name.slice(0, 2).toUpperCase()"
                    />
                  </b-avatar-group>

                  <avatar
                    v-else-if="data.sharedRanks.length === 1"
                    :user="data.sharedRanks[0]"
                  />
                </div>
              </template>
            </div>
          </div>

          <b-button-group
            v-if="
              (data.createdBy && data.createdBy.id === currentUser.id && isTimeLimitReached(data.createdAt.date, 15)) ||
                checkRequiredPermissions([
                  { role: $roles.CONTACT_THREAD_NOTE_EDIT_SELF, data, field: 'createdBy', userId: currentUser.id }
                ]) && isTimeLimitReached(data.createdAt.date, 15)"
          >
            <b-button
              v-if="checkRequiredPermissions([
                { role: $roles.CONTACT_THREAD_NOTE_EDIT_SELF, data, field: 'createdBy', userId: currentUser.id }
              ]) && isTimeLimitReached(data.createdAt.date, 15)"
              v-b-tooltip.hover.v-warning
              variant="outline-warning"
              size="sm"
              :title="$t('Edit')"
              class="btn-icon"
              @click="editNote(data)"
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>

            <b-button
              v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_NOTE_DELETE_SELF]) && data.createdBy && data.createdBy.id === currentUser.id && isTimeLimitReached(data.createdAt.date, 15)"
              v-b-tooltip.hover.v-danger
              variant="outline-danger"
              size="sm"
              :title="$t('Delete')"
              class="btn-icon"
              @click="deleteNote(data.id)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-button-group>
        </div>

        <hr class="border-light-primary">

        <div
          class="w-100 d-flex flex-column justify-content-start align-items-start px-md-50"
          style="row-gap: .34rem"
        >
          <div
            v-if="data.content"
          >
            <div v-html="data.content" />
          </div>

          <documents-list
            v-if="data.files.length"
            :documents="data.files"
          />
        </div>
      </b-card-body>
    </component>

    <hr
      v-if="isContact"
      class="w-100 mb-0"
    >
  </component>
</template>

<script>
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { BButtonGroup, VBTooltip } from 'bootstrap-vue'
import { DELETE_CONTACT_NOTE, OPEN_MODAL } from '@/@constants/mutations'
import { mapGetters } from 'vuex'
import * as roles from '@/helpers/permissions'
import { formatDate } from '@core/utils/filter'
import useSwUtils from '@/composable/useSwUtils'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import DocumentsList from '../DocumentsList.vue'

export default {
  name: 'Note',
  components: { BButtonGroup, DocumentsList, AppTimelineItem },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    roles,
  }),
  computed: {
    ...mapGetters({
      selectedThread: 'singleContact/getSelectedThread',
      currentUser: 'auth/getCurrentUser',
    }),
  },
  props: ['data', 'timelineDate', 'threadId', 'thread', 'isContact', 'automation'],
  methods: {
    formatDate,
    deleteNote(noteId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`singleContact/${DELETE_CONTACT_NOTE}`, { noteId, threadId: this.selectedThread.id })
            .then(() => {
              this.showToast('success', this.$i18n.t('NoteRemoved'))
              this.$emit('remove-item')
            })
            .catch(() => {
              this.showToast('danger', this.$i18n.t('ProblemOccured'))
            })
        })
    },
    editNote(note) {
      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'note',
          modalTitle: this.$i18n.t('EditNote'),
          okButtonTitle: this.$i18n.t('Save'),
          threadFor: this.threadId,
          editedData: note,
        })
    },
  },
  setup() {
    const { isTimeLimitReached } = useSwUtils()
    const { isMobile, resizeHandler } = useVerticalLayout()

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isTimeLimitReached,
      isMobile,
    }
  },
}
</script>

<style scoped>

</style>
