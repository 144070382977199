<template>
  <app-collapse-item
    :title="$t('Campaigns')"
    :is-title="false"
  >
    <template #subTitle>
      <div
        class="d-flex font-weight-bold flex-wrap align-items-center"
        style="gap: .5rem"
      >
        {{ $t('CampaignsSmsEmail') }}

        <b-badge variant="light-primary">
          <span v-if="isLoading">
            <b-spinner small />
          </span>

          <span v-else>{{ pagination.totalRecords }}</span>
        </b-badge>
      </div>
    </template>

    <sw-table
      table-id="14bc7e3a9c94449cbb95aecae517f560"
      :show-columns="false"
      :show-search="false"
      :busy="isLoading"
      :fields="fields"
      :pagination="pagination"
      @change-pagination="Object.assign(pagination, $event)"
      @change-search-query="searchQuery = $event"
      @reload-content="loadData"
    >
      <template #table>
        <!--      Table      -->
        <b-table
          :items="items"
          class="offer-table"
          :fields="fields"
          striped
          responsive
          :busy="isLoading"
          show-empty
        >
          <!--    Head    -->
          <template #head()="{ label }">
            {{ $t(`${label}`) }}
          </template>

          <template #cell(campaign)="{ item }">
            <b-badge v-if="item.campaign"
                     variant="light-primary"
            >
              {{ item.campaign.name }}
            </b-badge>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>
          <template #cell(email)="{ item }">
            <b-button v-if="item.mail"
                      size="sm"
                      variant="light-primary"
                      @click="modalPreview = item.mail; modalShow = true"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>
          <template #cell(status)="{ item }">
            <b-badge v-if="item.status"
                     variant="light-primary"
            >
              {{ item.status === 'QUEUE' ? $t('InQueue') : $tc(`gEmail.status.${item.status}`, 1) }}
            </b-badge>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>
          <template #cell(startAt)="{ item }">
            <span v-if="item.campaign && item.campaign.startAt">
              {{ formatDate(item.campaign.startAt.date, { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' }, $i18n.locale) }}
            </span>
            <feather-icon
              v-else
              icon="MinusIcon"
            />
          </template>

          <!--    Other    -->
            <!--    Other    -->
            <template #cell()="row">
                <table-default-cell
                        :field="row.field"
                        :value="row.value"
                />
            </template>

            <!--      Empty      -->
            <template #empty>
                <empty-content />
            </template>

            <!--      Table Busy      -->
            <template #table-busy>
                <div class="text-center text-danger my-2">
                    <b-spinner
                            class="align-middle"
                            variant="primary"
                    />
                </div>
            </template>
        </b-table>
      </template>
    </sw-table>

    <b-modal
      v-model="modalShow"
      :title="$t('Details')"
      size="lg"
      static
      hide-footer
      @hide="modalShow = false; modalPreview = null"
    >
      <template #modal-title>
        <div
          class="d-flex flex-wrap align-items-center"
          style="column-gap: .5rem"
        >
          {{ $t('Details') }}
        </div>
      </template>
      <div v-if="modalPreview">
        <p class="d-flex flex-column">
          <strong class="text-primary">{{ modalPreview.subject }}</strong>

          <span v-html="htmlFormat(modalPreview.contentHtml)" />
        </p>

        <p v-if="modalPreview.footer"
           class="d-flex flex-column"
        >
          <strong>{{ $t('Footer') }}</strong>

          <span v-html="(modalPreview.footer || '').replaceAll('\n', '<br>')" />
        </p>
      </div>
    </b-modal>
  </app-collapse-item>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'
import axiosIns from '@/libs/axios'
import { formatDate } from '@core/utils/filter'
import { htmlFormat } from '@core/filters/parts/valueFormat'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    modalPreview: null,
    modalShow: false,
    isLoading: false,

    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },

    pagination: {
      perPage: 25,
      totalRecords: 0,
      currentPage: 1,
    },

    fields: [
      { key: 'email', label: 'Email', visible: true },
      { key: 'createdAt', label: 'CreatedAt', visible: true },
      { key: 'campaign', label: 'CampaignName', visible: true },
      { key: 'status', label: 'Status', visible: true },
      // { key: 'recipientsNumber', label: 'RecipientsNumber', visible: true },
      { key: 'startAt', label: 'PlannedShippingDate', visible: true },
    ],
    items: [],
  }),

  mounted() {
    this.loadData()
  },
  methods: {
    htmlFormat,
    formatDate,
    async loadData() {
      this.isLoading = true
      try {
        const params = {
          fields_load: 'CAMPAIGN_TARGETS',
          orderBy: 'id.desc',
          page: this.pagination.currentPage,
          limit: this.pagination.perPage,
          'eq_contact-id': this.id,
        }

        const response = await axiosIns.get('1/campaigns/targets', { params })
        this.items = response.data.data?.items || []
        this.pagination.totalRecords = response.data.data?.totalItemCount
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.isLoading = false
      }
    },
  },
}
</script>
