<template>
  <div>
    <schedules
      :allow-filters="false"
      :thread-id="thread.id"
      :contact-id="thread.contact.id"
    />
  </div>
</template>

<script>
import Schedules from '@/pages/Schedules.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Payments',
  components: { Schedules },
  computed: {
    ...mapGetters({
      thread: 'contact/thread',
    }),
  },
}
</script>

<style scoped>

</style>
