<template>
  <div>
    <b-button
      v-if="!isGlobalRoute && !hideFilters && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_ADD])"
      variant="primary"
      size="sm"
      class="mb-1"
      @click="openAgreementModal"
    >
      {{ $t('AddDocument') }}
    </b-button>

    <!--    Section: Filters    -->
    <sw-filters
      v-if="!hideFilters"
      :shorts="[
        {
          title: $t('AssignedUsers'),
          type: 'avatar',
          value: () => filters.assignedUsers,
          clear: () => { filters.assignedUsers = []; loadData() }
        },
        {
          title: $t('SignerUser'),
          type: 'avatar',
          value: () => filters.signerUser,
          clear: () => { filters.signerUser = []; loadData() }
        },
        {
          title: $t('CreatedBy'),
          type: 'avatar',
          value: () => filters.createdBy,
          clear: () => { filters.createdBy = []; loadData() }
        },
        {
          title: $t('Contracts.AgreementType'),
          value: () => filters.type ? filters.type.name : null,
          clear: () => { filters.type = ''; loadData() }
        },
        {
          title: $t('offer.AgreementStatus'),
          value: () => filters.agreementStatuses.map(status => $t(`AgreementStatuses.${status}`)).join(', '),
          clear: () => { filters.agreementStatuses = []; loadData() }
        },
        {
          title: $t('offer.OfferStatus'),
          value: () => filters.offerStatuses.map(status => $t(`AgreementStatuses.${status}`)).join(', '),
          clear: () => { filters.offerStatuses = []; loadData() }
        },
        {
          title: $t('offer.CreatedTime'),
          value: () => filters.createdAt,
          clear: () => { filters.createdAt = ''; loadData() }
        },
        {
          variant: 'primary',
          value: () => filters.showSentOffers ? $t('ShowSentOffers') : null,
          clear: () => { filters.showSentOffers = false; loadData() }
        },
        {
          variant: 'primary',
          value: () => filters.electronicSignatureType && $t(`signatureTypes.${filters.electronicSignatureType}`),
          clear: () => { filters.electronicSignatureType = ''; loadData() }
        },
      ]"

      :res="[
        {
          title: $t('All'),
          value: () => agreements.length,
        }
      ]"
    >
      <b-row>
        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('AssignedUsers')">
            <assigned-users
              :value="filters.assignedUsers"
              @input="changeFilterUsers($event, 'assignedUsers')"
            />
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('SignerUser')">
            <assigned-users
              :value="filters.signerUser"
              @input="changeFilterUsers($event, 'signerUser')"
            />
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('CreatedBy')">
            <assigned-users
              :value="filters.createdBy"
              @input="changeFilterUsers($event, 'createdBy')"
            />
          </sw-select>
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('Contracts.AgreementType')">
            <v-select
              v-model="filters.type"
              :options="typeItems"
              label="name"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('offer.AgreementStatus')">
            <v-select
              v-model="filters.agreementStatuses"
              multiple
              :options="agreementStatuses"
            >
              <template #option="{label}">
                {{ $t(`AgreementStatuses.${label}`) }}
              </template>
              <template #selected-option="{label}">
                {{ $t(`AgreementStatuses.${label}`) }}
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('offer.OfferStatus')">
            <v-select
              v-model="filters.offerStatuses"
              multiple
              :options="offerStatuses"
            >
              <template #option="{label}">
                {{ $t(`AgreementStatuses.${label}`) }}
              </template>
              <template #selected-option="{label}">
                {{ $t(`AgreementStatuses.${label}`) }}
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('offer.CreatedTime')">
            <sw-select-fpr
              :is-empty="filters.createdAt"
              @clear="filters.createdAt = ''"
            >
              <flat-pickr
                ref="timeRange"
                v-model="filters.createdAt"
                class="form-control mb-1"
                :config="{ ...flatPickerConfig, locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('TypesOfSignature')">
            <v-select
              v-model="filters.electronicSignatureType"
              :options="electronicSignatureTypes"
            >
              <template #option="{label}">
                {{ $t(`signatureTypes.${label}`) }}
              </template>
              <template #selected-option="{label}">
                {{ $t(`signatureTypes.${label}`) }}
              </template>
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
        >
          <b-form-checkbox
            v-model="filters.showSentOffers"
            variant="primary"
          >
            {{ $t('ShowSentOffers') }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </sw-filters>

    <component
      :is="isGlobalRoute ? 'b-card' : 'div'"
      :class="{ 'mt-25': !isGlobalRoute }"
    >
      <!-- eslint-disable -->
      <sw-table
        table-id="695f907583914ed99678926f3d54a4ad"
        show-columns
        show-search
        @change-search-query="searchQuery = $event"
        :pagination="pagination"
        :fields.async="agreementFields"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="loadData"
        @set-fields="agreementFields = $event"
      >
        <template #table>
          <b-table
          :items="agreements"
          class="table-wrap-words"
          :fields="fields"
          striped
          responsive
          :busy="loading"
          show-empty
          :sort-by.sync="sorter.sortBy"
          :no-local-sorting="true"
          :sort-desc.sync="sorter.sortDesc"
          @sort-changed="Object.assign(sorter, $event); loadData()"
          >
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
              <template #cell(id)="{ index }">
                #{{ getOrderNo(index, pagination, sorter) }}
            </template>
            <template #cell(signerUser)="{ value }">
              <avatar
                :user="value"
                :to="{ name: 'user', params: { userId: value.id } }"
              />
            </template>
            <template #cell(createdBy)="{ value }">
              <avatar
                :user="value"
                :to="{ name: 'user', params: { userId: value.id } }"
              />
            </template>
            <template #cell(assignedTo)="{ item, index }">
              <b-badge
                v-for="(label, typeIndex) in agreementTypes(item)"
                :key="`type_${index}_${typeIndex}`"
                variant="light-primary"
                class="mr-25"
              >
                {{ $t(label) }}
              </b-badge>
            </template>
            <!-- Contact -->
            <template #cell(contact)="{ item }">
              <span
                v-if="item.contactThread"
                class="text-nowrap"
              >
                <router-link :class="{ 'deleted-text': item.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThread.contact.id}`">
                  {{ item.contactThread.contact.firstName }} {{ item.contactThread.contact.lastName }}
                </router-link>
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>

            <!-- Thread -->
            <template #cell(thread)="{ item }">
              <span
                v-if="item.contactThread"
                class="text-nowrap"
              >
                <router-link :class="{ 'deleted-text': item.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/payments`">
                  {{ item.contactThread.name | truncate(20) }}
                </router-link>
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>
            <template #cell(filesPdf)="{ value, item }">
              <documents-list
                v-if="value && value.length"
                :documents="!item.filesSigned && !item.filesSigned.length ? value : [...(value || []), ...(item.filesSigned || [])]"
                icon="FileIcon"
              />
              <documents-list
                v-else-if="item.filesSigned && item.filesSigned.length"
                icon="FileIcon"
                :documents="item.filesSigned"
              />
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <template #cell(offerName)="{ item }">
              <div
                v-if="item.contactThreadOffer && item.contactThreadOffer.contactThreadCart"
                class="text-nowrap"
              >
                {{ item.contactThreadOffer.contactThreadCart.name }}
              </div>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <template #cell(offer)="{ item }">
              <div
                v-if="item.priceGrossEnd"
                class="text-nowrap"
              >
                {{ (item.priceGrossEnd || 0) | priceGrossFormat }} PLN
              </div>
              <span v-else>{{ 0 | priceGrossFormat }} PLN</span>
            </template>
            <template #cell(contactThreadAgreementPaymentScheduleItems)="{ value }">
              <div
                v-if="value.find(el => el.type === 'R')"
                class="text-nowrap"
              >
                {{ (value.find(el => el.type === 'R').value || 0) | priceFormat }} PLN
              </div>
              <span v-else>0.00 PLN</span>
            </template>

            <!--     Types Of Signature     -->
            <template #cell(typesOfSignature)="{ value }">
              <template v-if="value.length">
                <b-badge
                    v-for="type in value"
                    :key="type.name"
                    variant="primary"
                >
                  {{ $t(type.name) }}
                </b-badge>
              </template>

              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>

            <!--    isWWW    -->
            <template #cell(isWWW)="{ item }">
              <div v-if="item.agreementTemplate && item.allowElectronicSignature && item.electronicSignatureType === 'WWW_SIGNATURE'" class="d-flex justify-content-center">
                <div>
                  <a
                      v-if="item.agreementTemplate"
                      v-b-tooltip.hover
                      class="badge badge-success"
                      :title="item.agreementTemplate.name"
                      :href="`${item.agreementWebUrlLoggedUser}`"
                      target="_blank"
                  >
                    <feather-icon icon="ExternalLinkIcon" />
                  </a>
                  <b-badge
                      v-else
                      variant="light-danger"
                      pill
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-badge>
                </div>

                <div>
                  <span
                      v-if="item.agreementTemplate"
                      v-b-tooltip.hover
                      class="badge badge-primary ml-25 d-flex align-items-center cursor-pointer"
                      :title="$t('Copy')"
                      @click="copy(item.agreementWebUrlContact)"
                  >
                    <feather-icon icon="Link2Icon" />
                    <div class="ml-25">{{ $t('ClientLink') }}</div>
                  </span>

                  <b-badge
                      v-else
                      variant="light-danger"
                      pill
                  >
                    <feather-icon icon="MinusIcon" />
                  </b-badge>
                </div>
              </div>

              <feather-icon v-else icon="MinusIcon" />
            </template>

            <!--    electronicSignatureType    -->
            <template #cell(electronicSignatureType)="{ value }">
              <b-badge
                v-if="value"
                variant="primary"
                class="d-inline-flex align-items-center"
                style="column-gap: .34rem"
              >
                <feather-icon :icon="value === 'WWW_SIGNATURE' ? 'Edit3Icon' : 'ShieldIcon'" />

                {{ $t(`signatureTypes.${value}`) }}
              </b-badge>

              <feather-icon
                  v-else
                  icon="MinusIcon"
              />
            </template>

              <template #cell(orderDate)="{ item }">
                  <span v-if="item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartOrder && item.contactThreadCartBox.contactThreadCartOrder.orderDate">{{ formatDate(item.contactThreadCartBox.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</span>
                  <feather-icon
                          v-else
                          icon="MinusIcon"
                  />
              </template>
            <!--        offerContent         -->
            <template #cell(offerContent)="{ item, index }">
              <div v-if="item.contactThreadCartBox && item.contactThreadCartBox.contactThreadCartBoxProducts">
                <b-button
                  :id="`popover-target_${index}`"
                  href="#"
                  tabindex="0"
                  size="sm"
                  variant="outline-primary"
                  style="min-width: 110px"
                  @click="offerSpec = item"
                >
                  {{ $t('Offer.Products') + ': ' + item.contactThreadCartBox.contactThreadCartBoxProducts.length }}
                </b-button>
              </div>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <template #cell(status)="{ value }">
              <b-badge variant="light-primary">
                {{ value ? $t(`AgreementStatuses.${value}`) : '-' }}
              </b-badge>
            </template>
            <template #cell(type)="{ item }">
              <b-badge
                v-if="item.agreementTemplate"
                variant="light-primary"
              >
                {{ item.agreementTemplate.agreementType.name }}
              </b-badge>
                <b-badge
                v-else-if="item.agreementType"
                variant="light-primary"
              >
                {{ item.agreementType.name }}
              </b-badge>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <!--    Other    -->
            <template #cell()="row">
                <table-default-cell
                        :field="row.field"
                        :value="row.value"
                />
            </template>

            <!--      Empty      -->
            <template #empty>
                <empty-content />
            </template>

            <template #cell(action)="{ item, index }">
              <div class="text-center">
                  <b-dropdown
                          class="dropdown-icon-wrapper text-decoration-none"
                          dropleft
                          no-caret
                          variant="flat"
                  >
                      <template
                              #button-content
                              class="sr-only"
                      >
                          <feather-icon icon="MoreVerticalIcon" />
                      </template>

                      <span v-if="item.status === 'NEW' && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_EDIT])">
                          <!--    Action: Sign    -->
                          <b-dropdown-item @click="changeStatus(item, 'SIGNED_MANUALLY', index)">
                              <dropdown-item-label icon="CheckIcon" :label="$t('MarkAsSigned')" />
                          </b-dropdown-item>

                                  <!--    Action: Cancel    -->
                          <b-dropdown-item @click="changeStatus(item, 'CANCELLED', index)">
                            <dropdown-item-label icon="XIcon" :label="$t('Cancel')" />
                          </b-dropdown-item>

                          <b-dropdown-item
                                  v-if="item.allowElectronicSignature && item.electronicSignatureType === 'AUTENTI'"
                                  @click="changeStatus(item, 'SIGNING', index)"
                          >
                              <dropdown-item-label icon="Edit2Icon" :label="$t('InitializeElectronicSignature')" />
                          </b-dropdown-item>

                          <b-dropdown-item
                                  v-if="item.allowElectronicSignature && item.electronicSignatureType === 'WWW_SIGNATURE' && ['NEW', 'SIGNING'].includes(item.status)"
                                  @click="sendEmailSignature(item.id)"
                          >
                              <dropdown-item-label icon="SendIcon" :label="$t('SendEmail')" />
                          </b-dropdown-item>

                          <b-dropdown-divider v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_DELETE])" />
                      </span>

                  <!--    Action: Delete    -->
                  <b-dropdown-item
                    v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_DELETE])"
                    @click="deleteDocument(item)"
                  >
                      <dropdown-item-label icon="TrashIcon" :label="$t('Delete')" />
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner
                  class="align-middle"
                  variant="primary"
                />
              </div>
            </template>
          </b-table>
        </template>
      </sw-table>
    </component>
    <!-- Add Agreement -->
    <b-modal
      size="lg"
      :visible="!!offerAgreement"
      :title="$t('Contracts.AddContract')"
      hide-footer
      no-close-on-backdrop
      @hide="offerAgreement = null"
    >
      <b-tabs>
        <b-tab :title="$t('SystemAgreement')">
          <section-agreement-item
            v-if="offerAgreement"
            :agreement="agreement"
            :autenti-enabled="autentiEnable"
            :is-cart="false"
            :agreement-types="agreementTypeItems.filter(e => e.agreementTemplates && e.agreementTemplates.length)"
            :cart-item="offerAgreement"
            @close-modal="offerAgreement = null; agreement = {...emptyAgreement}; loadData()"
          />
        </b-tab>
        <b-tab :title="$t('File')">
            <b-form-group :label="$t('Contracts.AgreementType')">
                <v-select
                        v-model="agreement.agreementType"
                        :options="agreementTypeItems"
                        :reduce="item => item.id"
                        label="name"
                />
            </b-form-group>
          <sw-select :name="$t('Contracts.DateOfSigningContract')">
            <sw-select-fpr>
              <flat-pickr
                ref="timeRange"
                v-model="agreement.signedAt"
                class="form-control"
                :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>

          <drag-drop-upload
            @onChangeFiles="(e) => { $set(agreement, 'filesSigned', e) }"
          />

          <b-button
            size="sm"
            class="mt-50"
            variant="primary"
            :disabled="!agreement.signedAt || !agreement.filesSigned.length || agreementLoading"
            @click="updateAgreement({ ...agreement, contactThread: offerAgreement.contactThread }, offerAgreement.offerId, 'add')"
          >
            {{ $t('Save') }}
          </b-button>
        </b-tab>
      </b-tabs>
    </b-modal>

    <b-modal
      size="lg"
      :visible="!!offerSpec"
      :title="offerSpec ? `${$t('Offer.Cart')}: ${offerSpec.contactThreadCartBox.name}` : $t('Specification')"
      button-size="sm"
      ok-only
      :ok-title="$t('Close')"
      ok-variant="secondary"
      @hide="offerSpec = null"
    >
      <product-specification
        v-if="offerSpec"
        :type="agreementTypes(offerSpec).includes('Orders') ? 'ORDER' : 'OFFER'"
        :offer="agreementTypes(offerSpec).includes('Orders') ? { ...offerSpec, ...offerSpec.contactThreadCartBox.contactThreadCartOrder } : {...offerSpec, ...offerSpec.contactThreadCartBox.contactThreadCartOffer}"
      />
    </b-modal>

    <b-modal
      size="lg"
      :visible="!!agreementSignManually"
      :title="$t('AddFile')"
      hide-footer
      no-close-on-backdrop
      @hide="agreementSignManually = null"
    >
      <div v-if="agreementSignManually">
        <sw-select :name="$t('Contracts.DateOfSigningContract')">
          <sw-select-fpr>
            <flat-pickr
              ref="timeRange"
              v-model="agreementSignManually.signedAt"
              class="form-control"
              :config="{ dateFormat: 'd-m-Y H:i', enableTime: true, time_24hr: true, locale: getCalendarLocale($i18n.locale) }"
            />
          </sw-select-fpr>
        </sw-select>

        <drag-drop-upload
          @onChangeFiles="(e) => { agreementSignManually.filesSigned.push(...e) }"
        />

        <b-button
          size="sm"
          class="mt-50"
          variant="primary"
          :disabled="!agreementSignManually.signedAt || !agreementSignManually.filesSigned.length || agreementLoading"
          @click="updateAgreement(agreementSignManually, agreementSignManually.index)"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import {BModal, BPagination, BPopover, BTab, BTable, BTabs, VBTooltip} from 'bootstrap-vue'
import { formatDate } from '@core/utils/filter'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import {
  DELETE_CONTACT_OFFER_AGREEMENT,
  GET_AGREEMENTS,
  GET_PRIVATE_SETTINGS,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import DocumentsList from '@/views/components/DocumentsList.vue'
import {agreementStatuses, electronicSignatureTypes, offerStatuses} from '@/@data/contact'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import flatPickr from 'vue-flatpickr-component'
import axiosIns from '@/libs/axios'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import ProductSpecification from '@/views/offers/product/ProductSpecification.vue'
import SectionAgreementItem from '@/views/offers/checkout/SectionAgreementItem.vue'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

const emptyAgreement = {
  agreementTemplate: '',
  agreementType: '',
  allowElectronicSignature: false,
  anonymised: false,
  signerUser: '',
  contactThreadCartDraftAgreementAssignedContacts: [],
  signedAt: '',
  filesSigned: [],
}

export default {
  name: 'Agreements',
  components: {
    BTab,
    BTabs,
    BTable,
    BPagination,
    vSelect,
    DocumentsList,
    BPopover,
    AssignedUsers,
    flatPickr,
    ProductSpecification,
    BModal,
    DragDropUpload,
    SectionAgreementItem,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    hideFilters: {
      type: Boolean,
      default: false,
    },
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    agreementStatus: {
      type: Array,
      required: false,
      default: () => [],
    },
    contact: {
      type: Object,
      required: false,
      default: null,
    },
    thread: {
      required: false,
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
  },
  data: () => ({
    emptyAgreement,
    agreement: { ...emptyAgreement },
    autentiEnable: false,
    offerAgreement: null,
    agreementStatuses,
    offerStatuses,
    electronicSignatureTypes,
    agreementFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { visible: true, key: 'createdAt', label: 'CreatedAt' },
      { visible: true, key: 'contact', label: 'Contact' },
      { visible: true, key: 'thread', label: 'Thread' },
      { visible: true, key: 'assignedTo', label: 'AssignedTo' },
      { visible: true, key: 'orderDate', label: 'OrderDate' },
      { visible: true, key: 'status', label: 'offer.AgreementStatus' },
      { visible: true, key: 'signedAt', label: 'Contracts.DateOfSigningContract', hideTime: true },
      { visible: true, key: 'signerUser', label: 'SignerUser' },
      { visible: true, key: 'type', label: 'Contracts.AgreementType' },
      { visible: true, key: 'agreementTemplate', label: 'contact.agreement' },
      { visible: true, key: 'offerContent', label: 'Offer.Products' },
      { visible: true, key: 'offer', label: 'Offer.TotalGrossPrice' },
      { visible: true, key: 'isWWW', label: 'WWW' },
      // { visible: true, key: 'allowElectronicSignature', label: 'contact.electronicSignature' },
      { visible: true, key: 'electronicSignatureType', label: 'TypesOfSignature' },
      { visible: true, key: 'agreementWebViews', label: 'contact.views' },
      { visible: true, key: 'filesPdf', label: 'Document' },
      { visible: true, key: 'createdBy', label: 'CreatedBy' },
      { visible: true, key: 'action', label: 'Action' },
    ],
    agreements: [],
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    filters: {
      assignedUsers: '',
      signerUser: '',
      createdBy: '',
      agreementStatuses: [],
      offerStatuses: [],
      type: '',
      createdAt: '',
      showSentOffers: false,
      electronicSignatureType: null,
    },
    searchQuery: '',
    status: [],
    loading: false,
    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
    typeItems: [],
    agreementTypeItems: [],
    offerSpec: null,
    agreementSignManually: null,
    agreementLoading: false,
  }),
  computed: {
    ...mapGetters({
      modalConfig: 'modal/getModalState',
      currentContact: 'contact/contact',
      currentThread: 'contact/thread',
    }),
    isGlobalRoute() {
      return this.$route.name === 'agreements' || this.$route.name === 'organizationsPreview'
    },
    locale() {
      return this.$i18n.locale
    },
    fields() {
      return this.agreementFields.filter(field => field.visible)
    },
  },
  watch: {
    funnel(n, o) { if (n !== o) this.loadData() },
    funnelPoint(n, o) { if (n !== o) this.loadData() },
    thread(n, o) { if (n !== o) this.loadData() },
    contacts(n, o) { if (n !== o) this.loadData() },
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
    modalConfig: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
  },
  async mounted() {
    await this.loadSettings()
    await this.loadAgreementTypes()
    this.loadTypes()

    if (!this.isGlobalRoute) {
      const contactFieldIndex = this.fields.findIndex(field => field.key === 'contact')
      this.fields.splice(contactFieldIndex, 2)
    }

    if (this.agreementStatus) {
      this.filters.status = this.agreementStatus
    }

    if (this.propsUser) {
      this.changeFilterUsers([this.propsUser], 'assignedUsers')
      return
    }

    if (this.propsUser === false) {
      this.changeFilterUsers([], 'assignedUsers')
      return
    }

    if (this.funnel) {
      this.filters.funnel = this.getObjectId(this.funnel)
    }
    if (this.funnelPoint) {
      this.filters.funnelPoint = this.getObjectId(this.funnelPoint)
    }
    if (this.thread) {
      this.filters.thread = this.getObjectId(this.thread)
    }
    if (this.currentThread) {
      this.filters.thread = this.getObjectId(this.currentThread)
    }
    if (this.contacts) {
      this.filters.contacts = (this.contacts || []).mapKeys('id')
    }

    this.loadData()
  },
  methods: {
    priceGrossFormat,
    formatDate,
    agreementTypes(agreement) {
      const types = []
      if (agreement?.contactThreadCartBox) {
        if (agreement?.contactThreadCartBox?.contactThreadCartOrder) types.push('Orders')
        if (agreement?.contactThreadCartBox?.contactThreadCartOffer) types.push('Offers')
      }

      return types
    },
    copy(txt) {
      this.$copyText(txt, this.$refs.contact_container).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
    },
    changeFilterUsers(value, place) {
      this.$set(this.filters, place, value)

      this.loadData()
    },
    async loadSettings() {
      try {
        const resp = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        this.autentiEnable = resp?.data?.item?.autentiEnabled || false
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadAgreementTypes() {
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.agreementTypeItems = resp.data.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async openAgreementModal() {
      const agreement = {}

      const threadId = agreement.contactThread?.id || this.thread?.id || this.currentThread?.id
      const contactId = agreement.contactThread?.contact?.id || this.thread?.contact?.id || this.contact?.id || this.currentContact?.id

      const contact = await axiosIns.get(`1/contacts/${contactId}`, { params: { fields_load: this.$fields.SINGLE_CONTACT } })
      const thread = await axiosIns.get(`1/contacts/threads/${threadId}`, { params: { fields_load: this.$fields.CONTACT_SINGLE_THREAD } })

      agreement.contactThread = thread.data?.data?.item || {}
      agreement.contactThread.contact = contact.data?.data?.item || null

      this.offerAgreement = { ...agreement }
      this.agreement = { ...emptyAgreement }
    },
    getStatus(name) {
      const stat = this?.status.filter(el => el.status === name)[0] ?? 0

      return stat?.totalGrossPrice ?? 0
    },
    async loadTypes() {
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.typeItems = resp.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    loadData() {
      this.loading = true
      this.$nextTick(() => {
        const payload = {
          pagination: this.pagination,
          filters: this.filters,
          searchQuery: this.searchQuery,
          sorter: this.sorter,
        }

        if (!this.isGlobalRoute && this.$route.name !== 'organizationsPreview') {
          const { contact, id } = this.thread || this.currentThread
          payload.contactId = contact.id
          payload.threadId = id
        }

        this.$store.dispatch(`agreements/${GET_AGREEMENTS}`, payload)
          .then(res => {
            // this.agreements = res.data.items.filter(el => el.contactThreadOffer)
            this.agreements = res.data.items.map(agreement => {
              if (!agreement.contactThreadCartBox) {
                return { ...agreement, priceGrossEnd: 0, price: 0 }
              }
              // eslint-disable-next-line
              agreement.priceGrossEnd = agreement.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount || 1), 0) || 0
              // eslint-disable-next-line
              agreement.price = agreement.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount || 1), 0) || 0
              return agreement
            })
            this.pagination.totalRecords = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      })
    },
    changeStatus(item, status, index) {
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          const payload = {
            id: item.id,
            status,
            contactThread: item.contactThread,
          }

          if (status !== 'CANCELLED' && status !== 'SIGNING' && status !== 'SIGNED_MANUALLY') {
            payload.signedAt = ''
            payload.filesSigned = []
            payload.index = index

            this.agreementSignManually = payload
            return
          }

          this.updateAgreement(payload, index)
        })
    },
    /* eslint-disable */
    async updateAgreement(payload, index, action = 'edit') {
      this.agreementLoading = true
      const { contact, id } = payload.contactThread

      if (payload?.filesSigned?.length) {
        const filesPayload = { uploadedFiles: payload.filesSigned, type: 'building' }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

        payload.filesSigned = files || []
      }

      if (action == 'add') {
        payload.agreementTemplate = ''
        payload.allowElectronicSignature = false
        payload.anonymised = false
        payload.signerUser = ''
        payload.contactThreadCartDraftAgreementAssignedContacts = []
        payload.contactThreadCartBox = payload.id
        delete payload.id
      }

      delete payload.index
      delete payload.contactThread

        if (!payload.electronicSignatureType) {
            payload.electronicSignatureType = null
        }

      if (action == 'add') {
        try {
          await axiosIns.post(`1/contacts/${contact.id}/threads/${id}/agreements`, payload)
          this.showToast('success', this.$i18n.t('success.contactUpdated'))

          this.loadData()

          this.offerAgreement = null
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        } finally {
          this.agreementLoading = false
          this.agreementSignManually = null
        }
      } else {
        axiosIns.patch(`1/contacts/${contact.id}/threads/${id}/agreements`, payload)
            .then(() => {
              this.showToast('success', this.$i18n.t('success.contactUpdated'))
              this.$set(this.agreements[index], 'status', payload.status)

              this.getSingleAgreement(payload.id)
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            }).finally(() => {
          this.agreementLoading = false
          this.agreementSignManually = null
        })
      }
    },
    async getSingleAgreement(id, index = -1) {
      if (id) {
        try {
          const resp = await axiosIns.get(`1/contacts/threads/agreements/${id}`, { params: { fields_load: this.$fields.AGREEMENTS } })
          const agreement = resp?.data?.data?.item

          if (!agreement.contactThreadCartBox) {
            return { ...agreement, priceGrossEnd: 0, price: 0 }
          }
          agreement.priceGrossEnd = agreement.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount || 1), 0) || 0
          agreement.price = agreement.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount || 1), 0) || 0

          if (index !== -1) {
            this.$set(this.agreements, index, agreement)
          } else {
            // eslint-disable-next-line no-underscore-dangle
            const _index = this.agreements.findIndex(item => item.id === id)
            if (_index >= 0) this.$set(this.agreements, _index, agreement)
          }
        } catch (err) {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        }
      }
    },
    deleteDocument(item) {
      this.$nextTick(() => {
        this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          this.$store.dispatch(`offer/${DELETE_CONTACT_OFFER_AGREEMENT}`, { threadId: item.contactThread.id, contactId: item.contactThread.contact.id, agreementId: item.id })
              .then(() => {
                const deleteObjectIndex = this.agreements.findIndex(el => el.id === item.id)

                this.agreements.splice(deleteObjectIndex, 1)
                this.showToast('success', this.$i18n.t('alerts.RemovedSuccessfully'))
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
              .finally(() => {
                this.loading = false
              })
        })

      })
    },
    async sendEmailSignature(id) {
      try {
        await axiosIns.post(`1/contacts/threads/agreements/${id}/sendEmail`)

        this.showToast('success', this.$i18n.t('task.Labels.AddedNewMail'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    }
  },
}
</script>

<style scoped>

</style>
