<template>
  <component :is="isContact ? 'b-row' : 'div'"
             style="row-gap: 1rem"
  >
    <!-- Filters, Stats, Activities -->
    <b-col sm="12">
      <timeline-filters
        v-if="!hideFilters"
        :default-assigned="defaultUser"
        @reload-timeline="setFilters"
      />

      <div
        class="d-flex flex-column flex-md-row"
        style="column-gap: 1rem; row-gap: .14rem;"
      >
        <h4>
          {{ $t('Filter') }}:
        </h4>

        <timeline-activities
          :activities="visibleTabs"
          :stats="stats"
          @change-activitie-status="changeActivitiesStatus"
        />
      </div>
    </b-col>

    <!-- Timeline Components -->
    <b-col sm="12">
      <div class="d-flex flex-wrap mt-50">
        <div
          style="width: max-content"
        >
          <sw-select>
            <v-select
              v-model="pagination.limit"
              class="per-page-selector d-inline-block mr-50 pb-50"
              :options="[10, 50, 100, 250]"
              :clearable="false"
              style="min-width: 100px; max-width: 200px"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>
            </v-select>
          </sw-select>
        </div>

        <div class="mr-1 flex-grow-1">
          <b-input-group class="input-group-merge search-input-group mx-25 mb-25">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="search"
              :placeholder="$t('Search')"
              class="input-search"
              @change="reloadTimeline"
            />
          </b-input-group>
        </div>
      </div>

      <timeline-addon-filters
        :selected-type="timelineTabs.find(e => e.isActive)"
        :filters="addonFilters"
        @assign-filters="addonFilters[$event.key][$event.field] = $event.value; reloadTimeline()"
      />

      <div v-if="!loading">
        <app-timeline class="ml-0">
          <component
            :is="`timeline-${option.type}`"
            v-for="(option, index) in timeline"
            :key="option + '__' + index"
            :is-contact="isContact"
            v-bind="{ data: {...option.data}, timelineDate: option.timelineDate, threadId: option.thread ? option.thread.id : null, action: option.action, thread: option.thread, automation: option.automation }"
          />

          <div
            v-if="!timeline.length"
            class="p-2 text-center text-primary"
          >
            <feather-icon icon="XCircleIcon" />
            {{ $t('NoData') }}
          </div>
        </app-timeline>

        <b-pagination
          v-model="pagination.currentPage"
          :total-rows="pagination.totalItemCount"
          :per-page="pagination.limit"
          align="center"
        />
      </div>

      <div
        v-else
        class="d-flex justify-content-center"
      >
        <b-spinner
          variant="primary"
          class="m-5"
        />
      </div>
    </b-col>
  </component>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import vSelect from 'vue-select'
// TimeLine sections
import TimelineActivities from '@/views/core/globalTimeline/TimelineActivities.vue'
import TimelineFilters from '@/views/core/globalTimeline/TimelineFilters.vue'
import TimelineStats from '@/views/core/globalTimeline/TimelineStats.vue'
import Offer from '@/views/components/timeline/Offer.vue'
import OfferMessage from '@/views/components/timeline/OfferMessage.vue'
import { mapGetters } from 'vuex'
import ContactThread from '@/views/components/timeline/ContactThread.vue'
import Order from '@/views/components/timeline/Order.vue'
import Automation from '@/views/components/timeline/Automation.vue'
import TimelineAddonFilters from '@/views/core/globalTimeline/TimelineAddonFilters.vue'
import Mail from '../views/components/timeline/Mail.vue'
import Sms from '../views/components/timeline/Sms.vue'
import Note from '../views/components/timeline/Note.vue'
import Task from '../views/components/timeline/Task.vue'
import Checklist from '../views/components/timeline/Checklist.vue'
import Complaint from '../views/components/timeline/Complaint.vue'
import { GET_TIMELINE, GET_TIMELINE_STATS } from '../@constants/mutations'

export default {
  name: 'SwTimeline',
  components: {
    TimelineAddonFilters,
    AppTimeline,
    vSelect,
    BPagination,
    'timeline-mail': Mail,
    'timeline-contactThreadSms': Sms,
    'timeline-contactThreadNote': Note,
    'timeline-contactThreadTask': Task,
    'timeline-contactThreadChecklistPointDatum': Checklist,
    'timeline-contactThreadCartOffer': Offer,
    'timeline-contactThread': ContactThread,
    'timeline-contactThreadCartOfferMessage': OfferMessage,
    'timeline-contactThreadCartOrder': Order,
    'timeline-automation': Automation,
    'timeline-contactThreadComplaint': Complaint,
    TimelineFilters,
    TimelineActivities,
    TimelineStats,
  },
  props: {
    isContact: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultTabs: {
      type: Array,
      required: false,
      default: () => [],
    },
    propsUser: {
      type: Object,
      required: false,
      default: null,
    },
    hideFilters: {
      type: Boolean,
      default: false,
    },
    thread: {
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
  },
  data: vm => ({
    loading: false,
    timeline: [],
    search: '',
    filters: {
      timeRange: '',
      contacts: '',
      funnel: '',
      funnelPoint: '',
      thread: '',
    },
    addonFilters: {
      contactThreadTask: {
        status: '',
        type: -1,
      },
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      limit: 50,
      currentPage: 1,
      totalItemCount: 0,
    },

    activities: [],
    stats: null,

    timelineTabs: [
      {
        title: 'all',
        key: 'all',
        isActive: true,
      },
      {
        key: 'contactThread',
        title: 'Thread',
        isActive: false,
        requiredPermissions: [
          vm.$roles.CONTACT_THREAD_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_VIEW_STRUCTURE,
          vm.$roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST,
          vm.$roles.CONTACT_THREAD_VIEW_ASSIGNED,
        ],
      },
      {
        key: 'contactThreadNote',
        title: 'timeline.ContactThreadNote',
        isActive: false,
        requiredPermissions: [
          vm.$roles.CONTACT_THREAD_NOTE_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_NOTE_VIEW_SELF,
          vm.$roles.CONTACT_THREAD_NOTE_VIEW_SHARED,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_NOTES],
      },
      {
        key: 'contactThreadTask',
        title: 'timeline.ContactThreadTask',
        isActive: false,
        requiredPermissions: [
          vm.$roles.CONTACT_THREAD_TASK_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
          vm.$roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_TASKS],
      },
      {
        key: 'contactThreadSms',
        title: 'timeline.ContactThreadSms',
        isActive: false,
        requiredPermissions: [
          vm.$roles.CONTACT_THREAD_SMS_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_SMS_VIEW_SHARED,
          vm.$roles.CONTACT_THREAD_SMS_VIEW_SELF,
        ],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_SMS],
      },
      {
        key: 'contactThreadChecklistPointDatum',
        title: 'timeline.ContactThreadChecklistPointDatum',
        isActive: false,
        requiredPermissions: [vm.$roles.CONTACT_THREAD_CHECKLIST_VIEW_ALL],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_CHECKLISTS],
      },
      {
        key: 'mail',
        title: 'timeline.Mail',
        isActive: false,
        requiredPermissions: [vm.$roles.CONTACT_THREAD_MAIL_VIEW_ALL],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_EMAILS],
      },
      {
        key: 'contactThreadCartOffer',
        title: 'timeline.ContactThreadOffer',
        isActive: false,
        requiredModule: 'offerModule',
        requiredPermissions: [vm.$roles.CONTACT_THREAD_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_OFFERS],
      },
      {
        key: 'contactThreadCartOfferMessage',
        title: 'timeline.OfferMessages',
        isActive: false,
        requiredModule: 'offerModule',
        requiredPermissions: [vm.$roles.CONTACT_THREAD_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_OFFERS],
      },
      {
        key: 'contactThreadCartOrder',
        title: 'timeline.ContactThreadOrder',
        isActive: false,
        requiredModule: 'orderModule',
        requiredPermissions: [vm.$roles.CONTACT_THREAD_ORDER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, vm.$roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF],
        requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_ORDERS],
      },
      {
        key: 'automation', title: 'Automation', isActive: false, requiredViews: [vm.$viewRanks.SHOW_CONTACT_CARD_AUTOMATIONS], requiredPermissions: [vm.$roles.AUTOMATION_VIEW_ALL], requiredModule: 'automationModule',
      },
      {
        key: 'contactThreadComplaint',
        title: 'Complaints',
        isActive: false,
        requiredModule: 'complaintModule',
        requiredPermissions: [
          vm.$roles.CONTACT_THREAD_COMPLAINT_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL,
          vm.$roles.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF,
        ],
      },
    ],
  }),
  computed: {
    ...mapGetters({
      system: 'system/getSettings',
      reloadContent: 'modal/getReloadContent',
    }),
    defaultUser() {
      return this.propsUser ? [this.propsUser] : null
    },
    visibleTabs() {
      return this.timelineTabs
        .filter(tab => !tab?.requiredViews || this.checkRequiredViewPermissions(tab.requiredViews))
        .filter(tab => !tab?.requiredModule || this.checkRequiredModule(tab.requiredModule))
        .filter(tab => !tab?.requiredPermissions || this.checkRequiredPermissions(tab.requiredPermissions))
    },
  },
  watch: {
    funnel(n, o) { if (n !== o) this.init() },
    funnelPoint(n, o) { if (n !== o) this.init() },
    thread(n, o) { if (n !== o) this.init() },
    contacts(n, o) { if (n !== o) this.init() },
    reloadContent(newValue) {
      if (newValue) {
        this.reloadTimeline()
        this.getStats()
      }
    },
    pagination: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.reloadTimeline()
        })
      },
    },
    system: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.init()
        })
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.funnel) {
        this.filters.funnel = this.getObjectId(this.funnel)
      }
      if (this.funnelPoint) {
        this.filters.funnelPoint = this.getObjectId(this.funnelPoint)
      }
      if (this.thread) {
        this.filters.thread = this.getObjectId(this.thread)
      }
      if (this.contacts) {
        this.filters.contacts = (this.contacts || []).mapKeys('id')
      }
      if (this.visibleTabs.length) this.reloadTimeline()
    },
    setFilters(filters) {
      this.$set(this, 'filters', filters)

      this.$nextTick(() => {
        this.reloadTimeline()
      })
    },
    getStats() {
      this.$store.dispatch(`timeline/${GET_TIMELINE_STATS}`, { filters: this.filters, search: this.search })
        .then(res => {
          this.stats = res.data.all
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    reloadTimeline() {
      this.loading = true
      this.$nextTick(() => {
        if (!this.visibleTabs.filter(a => a.isActive).length) {
          this.timeline = []
          this.loading = false
          return
        }
        const payload = {
          filters: this.filters,
          addonFilters: this.addonFilters,
          search: this.search,
          pagination: this.pagination,
          // types: this.visibleTabs.filter(a => a.isActive).map(a => a.key),
        }

        let types = this.visibleTabs
        if (types.find(tab => tab.isActive).title !== 'all') {
          types = types.filter(a => a.isActive).map(a => a.key)
        } else {
          types = types.map(a => a.key)
        }

        payload.types = types

        this.$store.dispatch(`timeline/${GET_TIMELINE}`, payload)
          .then(res => {
            this.getStats()

            const timeline = []
            res.data.items.forEach(timelineItem => {
              let { type } = timelineItem
              // eslint-disable-next-line no-self-assign
              if (['contactThreadTask', 'contactThreadChecklistPointDatum'].includes(type)) {
                type += 'Log'
              }

              if (['automation'].includes(type)) {
                type = 'automationProcedureLog'
              }

              if (['contactThread'].includes(type)) {
                type = 'contactThreadFunnelProcessLog'
              }

              const item = {
                type: timelineItem.type,
                data: timelineItem[type],
                timelineDate: timelineItem.createdAt.date.split('.')[0],
                action: timelineItem.action,
                thread: timelineItem?.contactThread,
                automation: timelineItem?.fromAutomationProcedureLog || null,
              }

              timeline.push(item)
            })
            // eslint-disable-next-line no-nested-ternary
            this.timeline = timeline
            this.pagination.totalItemCount = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.loading = false

            // eslint-disable-next-line no-prototype-builtins
            if (err.hasOwnProperty('message')) {
              return
            }

            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          })
      })
    },
    changeActivitiesStatus(key, value) {
      const activityIndex = this.timelineTabs.findIndex(a => a.key === key)

      if (activityIndex > -1) {
        this.timelineTabs.forEach((_, i) => { this.timelineTabs[i].isActive = false })

        this.$set(this.timelineTabs[activityIndex], 'isActive', value)
      }

      this.$nextTick(() => {
        this.reloadTimeline()
      })
    },
  },
}
</script>

<style scoped>

</style>
