<template>
  <div>
    <div
      v-if="!loading"
      class="email-application"
    >
      <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
      <div class="content-area-wrapper">
        <email-left-sidebar
          :class="{'show': mqShallShowLeftSidebar}"
          :active-status="filters.status"
          :per-page="pagination.perPage"
          @close-left-sidebar="mqShallShowLeftSidebar = false"
          @set-message-status="setMessagesStatus"
          @set-per-page="setMessagesPerPage"
        />

        <div class="content-right">
          <div class="content-wrapper">
            <div class="content-body">
              <div style="height: inherit">
                <div
                  class="body-content-overlay"
                  :class="{'show': mqShallShowLeftSidebar}"
                  @click="mqShallShowLeftSidebar = false"
                />

                <!-- Email List -->
                <div class="email-app-list">

                  <!-- App Searchbar Header -->
                  <div class="app-fixed-search d-flex align-items-center">

                    <!-- Toggler -->
                    <div class="sidebar-toggle d-block d-lg-none ml-1">
                      <feather-icon
                        icon="MenuIcon"
                        size="21"
                        class="cursor-pointer"
                        @click="mqShallShowLeftSidebar = true"
                      />
                    </div>

                    <!-- Searchbar -->
                    <div class="d-flex align-content-center justify-content-between w-100">
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="SearchIcon"
                            class="text-muted"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="filters.search"
                          :placeholder="$t('Search')"
                          @change="searchQuery"
                        />
                      </b-input-group>

                      <portal-target
                        name="full-screen-button"
                        slim
                        class="ml-25"
                      />
                    </div>
                  </div>

                  <!-- Emails List -->
                  <vue-perfect-scrollbar
                    :settings="{ maxScrollbarLength: 150 }"
                    class="email-user-list scroll-area"
                  >
                    <ul class="email-media-list">
                      <b-media
                        v-for="email in emails"
                        :key="email.id"
                        tag="li"
                        no-body
                        :class="{ 'mail-read': email.status === 'RECEIVED' }"
                        @click="openEmailDetails(email)"
                      >

                        <b-media-aside class="media-left mr-50">
                          <b-avatar
                            class="avatar"
                            size="40"
                            variant="primary"
                            style="background-color: var(--primary)"
                            text="S"
                            :src="email.createdBy ? email.createdBy.avatar : ''"
                          />
                          <div class="user-action" />
                        </b-media-aside>

                        <b-media-body>
                          <div class="mail-details mb-25">
                            <div class="mail-items">
                              <div class="d-flex align-items-center mb-50">
                                <b-badge
                                  v-if="showThread && email.conversation && email.conversation.contactThread"
                                  v-b-tooltip
                                  variant="light-primary"
                                  class="mr-25"
                                  :title="email.conversation.contactThread.name"
                                >
                                  {{ email.conversation.contactThread.name | truncate(25) }}
                                </b-badge>
                                <span class="mb-0 h5">
                                  {{ email.createdBy ? `${email.createdBy.firstName} ${email.createdBy.lastName}` : email.fromMail }}
                                </span>
                              </div>
                              <span class="text-truncate">{{ email.subject }}</span>
                            </div>
                            <div class="mail-meta-item">
                              <!-- Single Email Files Icon -->
                              <feather-icon
                                v-if="email.files.length"
                                icon="PaperclipIcon"
                              />
                              <div class="d-none justify-content-end align-items-center d-md-flex">
                                <span
                                  class="mx-50 bullet bullet-sm mt-25"
                                  :class="resolveFilterBadgeColor(email.status)"
                                />

                                <!-- Single Email Date -->
                                <span class="mail-date mt-25">
                                  {{ formatDateToMonthShort(email.createdAt ? email.createdAt.date : '', { hour: 'numeric', minute: 'numeric', }, $i18n.locale) }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div class="mail-message">
                            <p class="text-truncate mb-0">
                              {{ getHtml(email.contentHtml) }}
                            </p>
                          </div>

                          <div class="d-flex justify-content-end align-items-center d-md-none">
                            <span
                              class="mx-50 bullet bullet-sm mt-25"
                              :class="resolveFilterBadgeColor(email.status)"
                            />

                            <!-- Single Email Date -->
                            <span class="mail-date mt-25">
                              {{ formatDateToMonthShort(email.createdAt ? email.createdAt.date : '', { hour: 'numeric', minute: 'numeric', }, $i18n.locale) }}
                            </span>
                          </div>
                        </b-media-body>
                      </b-media>
                    </ul>
                    <div
                      class="no-results"
                      :class="{'show': !emails.length}"
                    >
                      <h5>{{ $t('NoData') }}</h5>
                    </div>
                  </vue-perfect-scrollbar>
                  <b-pagination
                    :value="pagination.page"
                    :total-rows="pagination.total"
                    :per-page="pagination.perPage"
                    aria-controls="table"
                    align="center"
                  />
                </div>

                <!-- Email View/Detail -->
                <!-- Detale -->
                <email-view
                  :class="{'show': showEmailDetails}"
                  :email-view-data="emailViewData"
                  :thread-id="!thread ? $route.params.threadId : thread.id"
                  @close-email-view="showEmailDetails = false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center"
    >
      <b-spinner
        variant="primary"
        class="m-5"
      />
    </div>
  </div>
</template>

<script>
import {
  BMedia, BMediaAside, BMediaBody, BPagination, VBTooltip,
} from 'bootstrap-vue'
import { GET_THREAD_CONVERSATION } from '@/@constants/mutations'
import EmailView from '@/views/components/emails/EmailView.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import EmailLeftSidebar from '@/views/components/emails/EmailLeftSidebar.vue'
import { formatDateToMonthShort } from '@core/utils/filter'
import * as emailStatuses from '@/@constants/emailStatuses'

export default {
  name: 'Emails',
  components: {
    EmailLeftSidebar,
    EmailView,
    VuePerfectScrollbar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    showThread: {
      default: false,
      type: Boolean,
    },
    thread: {
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
  },
  setup() {
    const resolveFilterBadgeColor = filter => {
      if (filter === emailStatuses.RECEIVED) return 'bullet-primary'
      if (filter === emailStatuses.QUEUE) return 'bullet-warning'
      if (filter === emailStatuses.DELIVERED) return 'bullet-success'
      if (filter === emailStatuses.FAILED) return 'bullet-danger'
      if (filter === emailStatuses.OPENED) return 'bullet-info'
      return 'bullet-primary'
    }

    return {
      resolveFilterBadgeColor,
    }
  },
  data: () => ({
    filters: {
      status: 'all',
      search: '',
    },
    sorter: {
      sortBy: 'createdAt',
      sortDesc: true,
    },
    pagination: {
      total: 0,
      perPage: 50,
      page: 1,
    },
    mqShallShowLeftSidebar: false,
    showEmailDetails: false,
    emailViewData: {},
    emails: [],
    loading: false,
  }),
  watch: {
    funnel(n, o) { if (n !== o) this.loadEmails() },
    funnelPoint(n, o) { if (n !== o) this.loadEmails() },
    thread(n, o) { if (n !== o) this.loadEmails() },
    contacts(n, o) { if (n !== o) this.loadEmails() },
  },
  mounted() {
    this.loadEmails()
  },
  methods: {
    formatDateToMonthShort,
    loadEmails() {
      this.$nextTick(() => {
        if (this.$route.params?.threadId) {
          const payload = { threadId: this.$route.params.threadId, pagination: this.pagination, filters: this.filters }
          this.$store.dispatch(`emails/${GET_THREAD_CONVERSATION}`, payload)
            .then(res => {
              this.pagination.total = res.data.totalItemCount
              this.emails = res.data.items ? res.data.items : []
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        } else {
          const payload = { pagination: this.pagination }

          if (this.funnel) {
            this.filters.funnel = this.getObjectId(this.funnel)
          }
          if (this.funnelPoint) {
            this.filters.funnelPoint = this.getObjectId(this.funnelPoint)
          }
          if (this.thread) {
            this.filters.thread = this.getObjectId(this.thread)
          }
          if (this.contacts) {
            this.filters.contacts = (this.contacts || []).mapKeys('id')
          }

          payload.filters = this.filters

          this.$store.dispatch('emails/GET_CONVERSATION', payload)
            .then(res => {
              this.pagination.total = res.data.totalItemCount
              this.emails = res.data.items ? res.data.items : []
            })
            .catch(err => {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            })
        }
      })
    },
    openEmailDetails(email) {
      this.showEmailDetails = true
      this.emailViewData = email
    },
    setMessagesStatus(status) {
      this.filters.status = status
      this.emailViewData = {}
      this.showEmailDetails = false
      this.loadEmails()
    },
    setMessagesPerPage(perPage) {
      this.pagination.perPage = perPage
      this.loadEmails()
    },
    searchQuery() {
      this.loadEmails()
    },
    getHtml(content = '') {
      const tmp = document.createElement('div')
      tmp.innerHTML = content
      return tmp.textContent || tmp.innerText
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-email.scss";

.email-application {
  .sidebar-content {
    padding: 6px 0 !important;
  }
  .vs__dropdown-toggle {
    border-radius: unset;
    border: unset;
    border-bottom: 1px solid #d8d6de;
    padding-bottom: 10px;
    margin-bottom: 5px;
  }
}
</style>
