var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sw-filters',{attrs:{"shorts":[
    {
      title: _vm.$t('ActivityDate'),
      value: function () { return _vm.filters.timeRange; },
      clear: function () {
        _vm.filters.timeRange = ''
        _vm.updateFilter()
      }
    },
    {
      title: _vm.$t('Funnels'),
      value: function () { return _vm.filters.funnel ? _vm.filters.funnel.name : null; },
      clear: function () {
        _vm.filters.funnel = ''
        _vm.updateFilter()
      }
    },
    {
      title: _vm.$t('AssignedUser'),
      type: 'avatar',
      value: function () { return _vm.assignedUsers; },
      clear: function () {
        _vm.assignedUsers = []
        _vm.filters.contacts = ''
        _vm.updateFilter()
      }
    },
    {
      title: _vm.$t('Contact'),
      value: function () { return _vm.filters.contacts ? ((_vm.filters.contacts.firstName) + " " + (_vm.filters.contacts.lastName)) : null; },
      clear: function () {
        _vm.filters.contacts = ''
        _vm.updateFilter()
      }
    } ]}},[_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('ActivityDate')}},[_c('sw-select-fpr',{attrs:{"is-empty":_vm.filters.timeRange},on:{"clear":function () { _vm.filters.timeRange = ''; _vm.updateFilter() }}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ mode: 'range', dateFormat: 'd-m-Y H:i', enableTime: true, time_24hr: true, locale: _vm.getCalendarLocale(_vm.$i18n.locale) }},on:{"input":_vm.updateFilter},model:{value:(_vm.filters.timeRange),callback:function ($$v) {_vm.$set(_vm.filters, "timeRange", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"filters.timeRange"}})],1)],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('Funnels')}},[_c('v-select',{attrs:{"options":_vm.funnels,"label":"name"},on:{"input":_vm.updateFilter},model:{value:(_vm.filters.funnel),callback:function ($$v) {_vm.$set(_vm.filters, "funnel", $$v)},expression:"filters.funnel"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('sw-select',{attrs:{"name":_vm.$t('AssignedUser')}},[_c('assigned-users',{attrs:{"value":_vm.assignedUsers,"disable-current-user":false},on:{"input":_vm.changeAssignedUsers}})],1)],1),_c('b-col',{attrs:{"sm":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Contact')}},[_c('sw-select',{staticClass:"mb-0",attrs:{"disabled":!_vm.contacts.length}},[_c('v-select',{attrs:{"options":_vm.contacts,"disabled":!_vm.contacts.length,"label":"firstName"},on:{"input":_vm.updateFilter},scopedSlots:_vm._u([{key:"option",fn:function(ref){
  var firstName = ref.firstName;
  var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}},{key:"selected-option",fn:function(ref){
  var firstName = ref.firstName;
  var lastName = ref.lastName;
return [_vm._v(" "+_vm._s(firstName)+" "+_vm._s(lastName)+" ")]}}]),model:{value:(_vm.filters.contacts),callback:function ($$v) {_vm.$set(_vm.filters, "contacts", $$v)},expression:"filters.contacts"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }