<template>
  <div>
    <!--    Section: Filters    -->
    <sw-filters
      v-if="allowFilters"
      mt
      :shorts="[
        {
          title: $t('PaymentStatus'),
          value: () => filters.status ? $t(`PaymentStatuses.${filters.status}`) : null,
          clear: () => { filters.status = ''; loadData() }
        },
        {
          title: $t('contact.assignedUsers'),
          type: 'avatar',
          value: () => filters.assignedUsers,
          clear: () => { filters.assignedUsers = []; loadData() }
        },
        {
          title: $t('PaymentMethod'),
          value: () => filters.paymentMethod ? $t(filters.paymentMethod.toLowerCase()) : null,
          clear: () => { filters.paymentMethod = ''; loadData() },
        },
        {
          value: () => filters.unpaid ? $t('NotPaid') : null,
          clear: () => { filters.unpaid = false; loadData() },
          variant: 'primary'
        },
        {
          value: () => filters.unpaidAfterTerm ? $t('AfterDeadline') : null,
          clear: () => { filters.unpaidAfterTerm = false; loadData() },
          variant: 'primary'
        },
        {
          value: () => filters.orderCreatedAt ? $t('OrderCreationDate') : null,
          clear: () => { filters.orderCreatedAt = ''; loadData() },
          variant: 'primary'
        },
        {
          value: () => filters.orderDate ? $t('OrderDate') : null,
          clear: () => { filters.orderDate = ''; loadData() },
          variant: 'primary'
        },
      ]"

      :res="[
        {
          title: $t('All'),
          value: () => pagination.totalRecords,
        }
      ]"
    >
      <b-row>
        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('PaymentStatus')">
            <v-select
              v-model="filters.status"
              :options="[
                'NEW',
                'COLLECTED',
                'PARTLY_COLLECTED',
                'NO_COLLECTED',
                'PARTLY_NO_COLLECTED',
              // 'NO_COLLECTED',
              // 'PARTLY_NO_COLLECTED',
              ]"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                {{ $t(`PaymentStatuses.${label}`) }}
              </template>

              <template #selected-option="{ label }">
                {{ $t(`PaymentStatuses.${label}`) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('contact.assignedUsers')">
            <assigned-users
              :value="filters.assignedUsers"
              @input="filters.assignedUsers = $event"
            />
          </sw-select>
        </b-col>

        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('PaymentMethod')">
            <v-select
              v-model="filters.paymentMethod"
              :options="[
                'INSTALLMENT',
                'BANK_INSTALLMENT',
                'TRANSFER',
                'CASH',
              ]"
            >
              <template #no-options>
                {{ $t('NoOptions') }}
              </template>

              <template #option="{ label }">
                {{ $t(label.toLowerCase()) }}
              </template>

              <template #selected-option="{ label }">
                {{ $t(label.toLowerCase()) }}
              </template>
            </v-select>
          </sw-select>
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('OrderCreationDate')">
            <sw-select-fpr
              :is-empty="filters.orderCreatedAt"
              @clear="filters.orderCreatedAt = ''"
            >
              <flat-pickr
                v-model="filters.orderCreatedAt"
                class="form-control"
                :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <sw-select :name="$t('OrderDate')">
            <sw-select-fpr
              :is-empty="filters.orderDate"
              @clear="filters.orderDate = ''"
            >
              <flat-pickr
                v-model="filters.orderDate"
                class="form-control"
                :config="{ mode: 'range', locale: getCalendarLocale($i18n.locale) }"
              />
            </sw-select-fpr>
          </sw-select>
        </b-col>
        <b-col
          sm="12"
          md="4"
        >
          <div class="d-flex justify-content-center flex-column h-100">
            <b-form-checkbox
              v-model="filters.unpaid"
              class="custom-control-primary"
            >
              {{ $t('ShowUnPaid') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="filters.unpaidAfterTerm"
              class="custom-control-primary mt-50"
            >
              {{ $t('ShowAfterDeadline') }}
            </b-form-checkbox>
          </div>
        </b-col>
      </b-row>
    </sw-filters>

    <b-row
      class="mb-50 mb-50"
    >
      <b-col
        sm="12"
        md="4"
      >
        <b-alert
          show
          variant="primary"
          class="px-50 py-1 d-flex align-items-center justify-content-start"
        >
          <!--          <b-avatar variant="primary">-->
          <!--            <feather-icon icon="CreditCardIcon" />-->
          <!--          </b-avatar>-->

          <div class="d-flex flex-column ml-75">
            <h4 class="mb-0 text-primary">
              {{ stats.left || 0 | priceGrossFormat }} PLN
            </h4>

            <p class="text-dark">
              {{ $t('LeftToPay') }}:
            </p>
          </div>
        </b-alert>
      </b-col>

      <b-col
        sm="12"
        md="4"
        class="mt-25 mt-md-0"
      >
        <b-alert
          show
          variant="primary"
          class="px-50 py-1 d-flex align-items-center justify-content-start"
        >
          <!--          <b-avatar variant="primary">-->
          <!--            <feather-icon icon="CreditCardIcon" />-->
          <!--          </b-avatar>-->

          <div class="d-flex flex-column ml-75">
            <h4 class="mb-0 text-primary">
              {{ stats.collected || 0 | priceGrossFormat }} PLN
            </h4>

            <p class="text-dark">
              {{ $t('Costs.Paid') }}:
            </p>
          </div>
        </b-alert>
      </b-col>

      <b-col
        sm="12"
        md="4"
        class="mt-25 mt-md-0"
      >
        <b-alert
          show
          variant="primary"
          class="px-50 py-1 d-flex align-items-center justify-content-start"
        >
          <!--          <b-avatar variant="primary">-->
          <!--            <feather-icon icon="CreditCardIcon" />-->
          <!--          </b-avatar>-->

          <div class="d-flex flex-column ml-75">
            <h4 class="mb-0 text-primary">
              {{ stats.all || 0 | priceGrossFormat }} PLN
            </h4>

            <p class="text-dark">
              {{ $t('TotalPaid') }}:
            </p>
          </div>
        </b-alert>
      </b-col>
    </b-row>

    <div class="my-50">
      <b-button
        v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_ADD])"
        size="sm"
        variant="primary"
        @click="openNewScheduleModal"
      >
        <feather-icon icon="PlusIcon" />
        {{ $t('Add') }}
      </b-button>
    </div>

    <component
      :is="allowFilters ? 'b-card' : 'div'"
      :class="{ 'mt-1': !allowFilters }"
    >
      <!-- eslint-disable -->
      <sw-table
        table-id="0126d80a02964fa0bda37f8fe4f152b3"
        show-columns
        show-search
        :pagination="pagination"
        :fields.async="paymentFields"
        @change-search-query="searchQuery = $event"
        @change-pagination="Object.assign(pagination, $event)"
        @reload-content="loadData"
        @set-fields="paymentFields = $event"
      >
        <template #table>
          <b-table
            ref="selectableTable"
            :items="payments"
            class="table-wrap-words mx-0 px-0"
            :fields="fields"
            striped
            responsive
            :busy="loading"
            show-empty
            :sort-by.sync="sorter.sortBy"
            :sort-desc.sync="sorter.sortDesc"
            :no-local-sorting="true"
            @sort-changed="Object.assign(sorter, $event); loadData()"
          >
            <template #head()="{ label }">
              {{ $t(`${label}`) }}
            </template>
              <template #cell(id)="{ index }">
                #{{ getOrderNo(index, pagination, sorter) }}
            </template>
            <!--        thread         -->
            <template #cell(thread)="{ value }">
              <span
                v-b-tooltip.hover
                class="text-nowrap"
                :title="value"
              >
                {{ value | truncate(10, '…') }}
              </span>
            </template>

            <!--        toPay         -->
            <template #cell(toPay)="{ value }">
              <span class="text-nowrap">{{ value | priceGrossFormat }} PLN</span>
            </template>

            <!--        paid         -->
            <template #cell(paid)="{ item }">
              <div class="d-flex justify-content-center align-items-center">
                <span class="text-nowrap">
                  {{ gePaidAndLeftToPaid(item.contactThreadPaymentCollects, item.value).paid | priceGrossFormat }} PLN
                </span>

                <b-button
                  v-if="item.contactThreadPaymentCollects && item.contactThreadPaymentCollects.length"
                  v-b-tooltip.focus.v-primary.html="getCollects(item.contactThreadPaymentCollects)"
                  class="btn-icon p-0"
                  style="margin-left: .5rem"
                  variant="flat-primary"
                  size="sm"
                >
                  <feather-icon
                    icon="HelpCircleIcon"
                    size="18"
                  />
                </b-button>
              </div>
            </template>

            <!-- Template -->
<!--            <template #cell(daysLeftToBePaid)="{ item }">-->
<!--              {{ getDaysLeft(item.deadlineDate) }}-->
<!--            </template>-->

            <!--        To Pay         -->
            <template #cell(value)="{ item }">
              <span class="text-nowrap">{{ item.value | priceGrossFormat }} PLN</span>
            </template>

            <!--        leftToPay         -->
            <template #cell(leftToPay)="{ item }">
              <span class="text-nowrap">{{ gePaidAndLeftToPaid(item.contactThreadPaymentCollects, item.value).leftToPaid | priceGrossFormat }} PLN</span>
            </template>

            <!--        typeOfPayment         -->
            <template #cell(type)="{ value }">
              <b-badge v-if="value" variant="light-primary">
                {{ $t(paymentTypes[value]) }}
              </b-badge>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(lastPayment)="{ item }">
              <span v-if="item.contactThreadPaymentCollects.length">
                {{ formatDate(getLastCollect(item.contactThreadPaymentCollects), { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
              </span>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!-- Payer -->
            <template #cell(payer)="{ value }">
              <span
                v-if="value"
                class="text-nowrap"
              >
                <router-link :class="{ 'deleted-text': value.deletedAt }" :to="`/contact/${value.id}`">
                  {{ value.firstName }} {{ value.lastName }}
                </router-link>
                <!--                <span class="text-primary font-weight-bolder">({{ item.part || 0 }}%)</span>-->
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>

            <template #cell(orderDate)="{ item }">
                <span v-if="item.contactThreadCartOrder && item.contactThreadCartOrder.orderDate && item.contactThreadCartOrder.orderDate.date">{{ formatDate(item.contactThreadCartOrder.orderDate.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</span>
                <feather-icon
                        v-else
                        icon="MinusIcon"
                />
            </template>

            <template #cell(paymentMethod)="{ item }">
              <b-badge :variant="paymentTypeColors[item.contactThreadCartOrder.contactThreadCartBox.paymentType]" v-if="item.contactThreadCartOrder">
                {{ $t(getPaymentTypeLang(item.contactThreadCartOrder.contactThreadCartBox.paymentType)) }}
              </b-badge>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>
            <template #cell(contactThreadCartOrder)="{ value }">
              <b-badge variant="light-primary" v-if="value">
                {{ value.number }}
              </b-badge>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!-- Contact -->
            <template #cell(contact)="{ item }">
              <span
                v-if="item.contactThread"
                class="text-nowrap"
              >
                <router-link :class="{ 'deleted-text': item.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThread.contact.id}`">
                  {{ item.contactThread.contact.firstName }} {{ item.contactThread.contact.lastName }}
                </router-link>
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>

            <!-- Thread -->
            <template #cell(thread)="{ item }">
              <span
                v-if="item.contactThread"
                class="text-nowrap"
              >
                <router-link :class="{ 'deleted-text': item.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThread.contact.id}/thread/${item.contactThread.id}/payments`">
                  {{ item.contactThread.name | truncate(20) }}
                </router-link>
              </span>
              <span v-else>
                <feather-icon icon="MinusIcon" />
              </span>
            </template>

              <template #cell(note)="{ item }">
                  <div
                          v-if="item.note"
                          class="text-primary cursor-pointer"
                          @click="noteModal.item = { ...item }; noteModal.show = true; noteModal.isPreview = true"
                  >
                      <feather-icon icon="EyeIcon" />
                      {{ item.note | truncate(50) }}
                  </div>
                  <sw-icon icon="MinusIcon" v-else />
              </template>

            <!--        contentOfOffer         -->
            <template #cell(products)="{ item, index }">
              <div v-if="item.contactThreadAgreement && item.contactThreadAgreement.contactThreadOffer && item.contactThreadAgreement.contactThreadOffer.contactThreadOfferProducts">
                <b-button
                  :id="`popover-target-${index}`"
                  variant="outline-primary"
                  class="text-nowrap"
                  size="sm"
                >
                  {{ $t('Offer.Products') }}: {{ item.contactThreadAgreement.contactThreadOffer.contactThreadOfferProducts.length }}
                </b-button>
                <b-popover
                  :target="`popover-target-${index}`"
                  triggers="focus"
                >
                  <p
                    v-for="(pro, productIndex) in item.contactThreadAgreement.contactThreadOffer.contactThreadOfferProducts"
                    :key="`product_${index}_${productIndex}`"
                    class="my-0 py-25"
                  >
                    <span v-if="pro.translations && pro.translations.length">{{ pro.translations[0].name }}</span>
                  </p>
                </b-popover>
              </div>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <template #cell(agreement)="{ item }">
              <div
                v-if="item.contactThreadAgreement"
                class="text-nowrap"
              >
                {{ item.contactThreadAgreement.agreementTemplate.name }}
              </div>
            </template>

            <!--        status         -->
            <template #cell(status)="{ value }">
              <b-badge :variant="getPaymentStatus(value)">
                {{ $t(`PaymentStatuses.${value}`) }}
              </b-badge>
            </template>

            <!--     Action     -->
            <template #cell(action)="{ item, index }">
              <b-dropdown
                v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_COLLECT_ADD, $roles.CONTACT_THREAD_PAYMENT_DELETE])"
                id="dropdown-dropleft"
                dropleft
                no-caret
                variant="flat"
              >
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" />
                </template>

                <b-dropdown-item
                  v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_COLLECT_ADD])"
                  @click="openNewCollectModal(item, index)"
                >
                  <dropdown-item-label icon="PlusIcon" :label="$t('AddPayment')" />
                </b-dropdown-item>

                  <b-dropdown-divider v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_DELETE, $roles.CONTACT_THREAD_PAYMENT_COLLECT_ADD])" />

                <b-dropdown-item
                  v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_DELETE])"
                  @click="deletePayment(item, index)"
                >
                  <dropdown-item-label icon="TrashIcon" :label="$t('Delete')" />
                </b-dropdown-item>

              </b-dropdown>
              <feather-icon
                v-else
                icon="MinusIcon"
              />
            </template>

            <!-- Other -->
              <!--    Other    -->
              <template #cell()="row">
                  <table-default-cell
                          :field="row.field"
                          :value="row.value"
                  />
              </template>

              <!--      Empty      -->
              <template #empty>
                  <empty-content />
              </template>

              <!--      Table Busy      -->
              <template #table-busy>
                  <div class="text-center text-danger my-2">
                      <b-spinner
                              class="align-middle"
                              variant="primary"
                      />
                  </div>
              </template>
          </b-table>
        </template>
      </sw-table>
    </component>

      <b-modal
              v-model="noteModal.show"
              :title="noteModal.isPreview ? $t('Preview') : $t('AddNote')"
              hide-footer
              no-close-on-backdrop
      >
          <div v-if="!noteModal.isPreview">
              <b-form-group :label="$t('Content')">
                  <b-form-textarea v-model="noteModal.note" />
              </b-form-group>
          </div>
          <div v-else-if="noteModal.item">
              <span v-html="noteModal.item.note.replaceAll('\n', '<br>')"></span>
          </div>
      </b-modal>

    <b-modal
      :visible="!!collectModalItem"
      hide-footer
      :title="$t('AddPayment')"
      @hide="collectModalItem = null"
    >
      <div v-if="!!collectModalItem">
        <b-form-group :label="$t('Offer.Price')">
          <cleave
            v-model="collectModalItem.payment.value"
            :options="{
              delimiter: ' ',
              numeral: true,
              numeralThousandsGroupStyle: 'thousand',
            }"
            class="form-control"
          />
        </b-form-group>
        <b-form-group :label="$t('PaymentDate')">
          <flat-pickr
            v-model="collectModalItem.payment.paymentDate"
            class="form-control"
            :config="{ locale: getCalendarLocale($i18n.locale) }"
          />
        </b-form-group>
        <b-button
          size="sm"
          :disabled="!collectModalItem.payment.value || !collectModalItem.payment.paymentDate || collectModalItem.payment.value > gePaidAndLeftToPaid(collectModalItem.item.contactThreadPaymentCollects, collectModalItem.item.value).leftToPaid"
          variant="primary"
          @click="saveCollect"
        >
          {{ $t('Save') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable */

import vSelect from 'vue-select'
import { Polish as pl } from '@/libs/i18n/locales/flatpickr/pl'
import { english as en } from '@/libs/i18n/locales/flatpickr/en'
import {
  BAlert, BModal, BPopover, BTable, VBPopover, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import flatPickr from 'vue-flatpickr-component'
import { ADD_PAYMENT_COLLECT, GET_PAYMENTS, GET_PAYMENTS_STATS, OPEN_MODAL, } from '@/@constants/mutations'
import moment from 'moment'
import { formatDate } from '@core/utils/filter'
import Cleave from 'vue-cleave-component'
import BuildingFilters from '@/views/filters/BuildingFilters.vue'
import AssignedUsers from '@/views/components/AssignedUsers.vue'
import axiosIns from '@/libs/axios'
import { mapGetters } from 'vuex'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'

export default {
  name: 'Schedules',
  components: {
    vSelect,
    flatPickr,
    BAlert,
    BTable,
    BPopover,
    BModal,
    Cleave,
    BuildingFilters,
    AssignedUsers,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    hideAction: {
      default: false,
      type: Boolean,
    },
    allowFilters: {
      default: true,
      type: Boolean,
    },
    threadId: {
      default: null,
      type: String,
    },
    contactId: {
      default: null,
      type: String,
    },

    thread: {
      default: null,
    },
    funnel: {
      default: null,
    },
    funnelPoint: {
      default: null,
    },
    contacts: {
      default: () => [],
    },
  },
  data: vm => ({
    mAddSchedule: false,

    noteModal: {
        show: false,
        item: null,
        isPreview: false,
    },

    types: [],
    typeVal: '',
    dateCreate: '',
    unpaid: false,
    unpaidOverdue: false,

    collectModalItem: null,
    payments: [],
    paymentFields: [
      {
        key: 'id', label: '#', visible: true, sortable: true,
      },
      { visible: true, key: 'deadlineDate', label: 'DaysLeftToBePaid', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_DAYS_LEFT] },
      { visible: true, key: 'contactThreadCartOrder', label: 'Order', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_NUMBER] },
      { visible: true, key: 'orderDate', label: 'OrderDate', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_ORDER_DATE] },
      { visible: true, key: 'createdAt', label: 'CreatedAt', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_CREATED_AT] },
      { visible: true, key: 'contact', label: 'Contact', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_CONTACT] },
      { visible: true, key: 'thread', label: 'Thread', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_THREAD] },
      { visible: true, key: 'note', label: 'Note', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_THREAD] },
      { visible: true, key: 'value', label: 'Costs.AmountToPay', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_AMOUNT_TO_PAY] },
      { visible: true, key: 'paid', label: 'Costs.Paid', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_PAID] },
      { visible: true, key: 'leftToPay', label: 'LeftToPay', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_LEFT_TO_PAID] },
      { visible: true, key: 'type', label: 'TypeOfPayment', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_TYPE] },
      { visible: true, key: 'lastPayment', label: 'DateOfLastPayment', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_LAST_PAYMENT_DATE] },
      { visible: true, key: 'status', label: 'PaymentStatus', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_STATUS] },
      { visible: true, key: 'paymentMethod', label: 'PaymentMethod', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_PAYMENT_METHOD] },
      { visible: true, key: 'action', label: 'Action', requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_PAYMENT_COLUMN_ACTION] },
    ],
    searchQuery: '',
    paymentTypes: {
      ADVANCE: 'AdvancePayment',
      INSTALLMENT: 'Installment',
      TRANSFER: 'Przelew',
      CASH: 'Cash',
      LOYALTY_POINTS: 'LoyaltyPoints',
      TOTAL_PAYMENT: 'TotalAmount',
    },
    paymentTypeColors: {
        ADVANCE: 'info',
        TOTAL_PAYMENT: 'light-info',
        BANK_INSTALLMENT: 'warning',
        INSTALLMENT: 'light-warning',
        TRANSFER: 'primary',
        CASH: 'light-primary',
        LOYALTY_POINTS: 'light-success',
    },
    paymentTypeList: [
      'ADVANCE',
      'BANK_INSTALLMENT',
      'INSTALLMENT',
      'TRANSFER',
      'CASH',
      'LOYALTY_POINTS',
      'TOTAL_PAYMENT',
    ],
    stats: { all: 0, left: 0, collected: 0 },
    statsLoading: false,
    loading: false,
    // Pagination
    sorter: {
      sortBy: 'id',
      sortDesc: true,
    },
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
    // Pagination
    filters: {
      status: '',
      type: '',
      createdAt: '',
      orderDate: '',
      unpaid: false,
      unpaidAfterTerm: false,
      structure: '',
      investment: '',
      building: '',
      assignedUsers: '',
      paymentMethod: '',
    },

    flatPickerConfig: {
      mode: 'range',
      locale: [pl, en],
    },
  }),
  computed: {
    locale() {
      return this.$i18n.locale
    },
    fields() {
      return this.paymentFields.filter(field => field.visible)
          .filter(e => !e.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
    ...mapGetters({
      modalState: 'modal/getModalState',
    }),
  },
  watch: {
    funnel(n, o) { if (n !== o) this.loadData() },
    funnelPoint(n, o) { if (n !== o) this.loadData() },
    thread(n, o) { if (n !== o) this.loadData() },
    contacts(n, o) { if (n !== o) this.loadData() },
    filters: {
      deep: true,
      handler() {
        this.loadData()
      },
    },
    modalState: {
      deep: true,
      handler(n) {
        if (n.reloadContent) {
          this.loadData()
        }
      },
    },
  },
  async mounted() {
    this.loadData()

    if (this.contactId && this.threadId) {
      const contactFieldIndex = this.fields.findIndex(field => field.key === 'contact')
      this.fields.splice(contactFieldIndex, 2)
    }
  },
  methods: {
    priceGrossFormat,
    formatDate,
    getPaymentTypeLang(type) {
      const types = {
        INSTALLMENT: 'installment',
        TRANSFER: 'transfer',
        CASH: 'cash',
      }

      return types[type] || '-'
    },
    reloadContent(filters) {
      this.$set(this, 'filters', { ...this.filters, ...filters })
    },
    // Collects
    getCollects(collects = []) {
      let html = ''
      if (collects.length) {
        collects.forEach(collect => {
          html += `<div>${formatDate(collect.paymentDate?.date ?? collect.paymentDate, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)} - ${collect.value} PLN</div>`
        })
      }
      return html
    },
    // Status
    getPaymentStatus(status) {
      switch (status) {
        case 'NEW': return 'light-primary'
        case 'COLLECTED': return 'success'
        case 'PARTLY_COLLECTED': return 'light-warning'
        case 'NO_COLLECTED': return 'danger'
        case 'PARTLY_NO_COLLECTED': return 'light-danger'
        default: return 'primary'
      }
    },
    // daysLeftToBePaid
    getDaysLeft({ date }) {
      moment.locale(this.$i18n.locale)
      return moment(new Date()).to(moment(date).add(23, 'hour').add(59, 'minute'))
    },
    // paid
    gePaidAndLeftToPaid(collects = [], total = 0) {
      const paid = collects.reduce((a, { value }) => +a + +value, 0)
      return {
        paid,
        leftToPaid: total - paid,
      }
    },
    // leftToPay
    getLastCollect(collects) {
      return collects.length ? collects[0]?.paymentDate?.date : null
    },
    openNewCollectModal(item, index) {
      this.collectModalItem = {
        item, index, payment: { value: this.gePaidAndLeftToPaid(item.contactThreadPaymentCollects, item.value).leftToPaid, paymentDate: '' },
      }
    },
    saveCollect() {
      const payment = { ...this.collectModalItem.payment, value: parseFloat(this.collectModalItem.payment.value) }
      const letToPaid = this.gePaidAndLeftToPaid(this.collectModalItem.item.contactThreadPaymentCollects, this.collectModalItem.item.value).leftToPaid

      if ((letToPaid - payment.value) < 1) {
          payment.value += (letToPaid - payment.value)
      }

      this.$store.dispatch(`payments/${ADD_PAYMENT_COLLECT}`, { payment, paymentId: this.collectModalItem.item?.id })
        .then(() => {
          const item = this.payments[this.collectModalItem.index]
          payment.paymentDate = { date: payment.paymentDate }
          item.contactThreadPaymentCollects.push(payment)

          if (item.status === 'NEW') this.$set(item, 'status', 'PARTLY_COLLECTED')
          if (item.status === 'PARTLY_COLLECTED' && this.gePaidAndLeftToPaid(item.contactThreadPaymentCollects, item.value).leftToPaid <= 0) this.$set(item, 'status', 'COLLECTED')

          this.collectModalItem = null

          this.loadData()
          this.loadStatistics()

          this.showToast('success', this.$i18n.t('success.contactUpdated'))
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        })
    },
    loadData() {
      this.$nextTick(() => {
        this.loading = true
        const payload = {
          pagination: this.pagination,
          searchQuery: this.searchQuery,
          sorter: this.sorter,
          filters: {},
        }

        if (this.allowFilters) {
          payload.filters = this.filters
        }

        if (this.funnel) {
          payload.filters.funnel = this.getObjectId(this.funnel)
        }
        if (this.funnelPoint) {
          payload.filters.funnelPoint = this.getObjectId(this.funnelPoint)
        }
        if (this.thread) {
          payload.filters.thread = this.getObjectId(this.thread)
        }
        if (this.contacts) {
          payload.filters.contacts = (this.contacts || []).mapKeys('id')
        }

        if (this.contactId && this.threadId) {
          payload.threadId = this.threadId
          payload.contactId = this.contactId
        }

        this.loadStatistics()

        this.$store.dispatch(`payments/${GET_PAYMENTS}`, payload)
          .then(res => {
            this.payments = res.data.items
            this.pagination.totalRecords = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      })
    },
    loadStatistics() {
      this.statsLoading = true
      const payload = {
        filters: {},
      }
      if (this.contactId && this.threadId) {
        payload.threadId = this.threadId
        payload.contactId = this.contactId
      }

      if (this.funnel) {
        payload.filters.funnel = this.getObjectId(this.funnel)
      }
      if (this.funnelPoint) {
        payload.filters.funnelPoint = this.getObjectId(this.funnelPoint)
      }
      if (this.thread) {
        payload.filters.thread = this.getObjectId(this.thread)
      }
      if (this.contacts) {
        payload.filters.contacts = (this.contacts || []).mapKeys('id')
      }

      this.$store.dispatch(`payments/${GET_PAYMENTS_STATS}`, payload)
        .then(res => {
          this.stats = res.data
          this.statsLoading = false
        })
        .catch(err => {
          this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          this.statsLoading = false
        })
    },
    deletePayment(item, index) {
      if (!item?.id) return

      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(async () => {
          try {
            await axiosIns.delete('1/contacts/threads/payments', { data: JSON.stringify([item.id]) })

            this.$delete(this.payments, index)
            await this.loadStatistics()

            this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
          } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
        })
    },

    openNewScheduleModal() {
      const { contactId } = this

      this.$store.commit(`modal/${OPEN_MODAL}`,
        {
          modalType: 'addSchedule',
          modalTitle: this.$i18n.t('AddSchedule'),
          contactId,
        })
    },
  },
}
</script>
