<template>
  <div ref="contact_container">
    <div v-if="!loading && singleContact">
      <b-card no-body>
        <b-card-body
          class="d-flex flex-column"
          style="row-gap: 1rem"
        >
          <!--    START::Info    -->
          <b-row>
            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="3"
              class="d-flex flex-column"
              style="min-width: 300px"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex flex-column justify-content-start">
                <div class="d-flex flex-wrap">
                  <h5 class="mb-0">
                    {{ singleContact.firstName }} {{ singleContact.lastName }}
                  </h5>

                  <div
                    v-if="Array.isArray(singleContact.contactTypes) && singleContact.contactTypes.length"
                    class="my-25 w-100"
                  >
                    <b-badge
                      v-for="tag in singleContact.contactTypes"
                      :key="`tag_${tag.id}`"
                      variant="light-secondary"
                      class="mr-25 mt-25"
                    >
                      <div
                        class="color-dot mr-25"
                        :style="{ backgroundColor: tag.color || '#fff' }"
                        style="border: 1px solid white"
                      />
                      {{ tag.name }}
                    </b-badge>
                  </div>
                </div>

                <div
                  v-if="singleContact.email"
                  class="d-flex align-items-center"
                  style="gap: .34rem"
                >
                  <a
                    :href="`mailto:${singleContact.email}`"
                    class="card-text font-small-2"
                  >
                    {{ singleContact.email }}
                  </a>

                  <b-button
                    v-b-tooltip.hover.v-success
                    variant="flat-success"
                    size="sm"
                    class="btn-icon p-25"
                    :title="$t('Copy')"
                    @click="copy(singleContact.email)"
                  >
                    <feather-icon icon="CopyIcon" />
                  </b-button>
                </div>
              </div>

              <div class="d-flex flex-column flex-wrap align-items-start">
                <b-badge
                  v-if="singleContact.organization"
                  v-b-tooltip
                  variant="light-dark"
                  :title="$t('Organization')"
                  class="text-left text-wrap"
                >
                  <b-link v-if="checkRequiredPermissions([$roles.ORGANIZATION_VIEW_ALL]) && singleContact.organization.id"
                          :to="`/organizations/${singleContact.organization.id}`"
                          target="_blank"
                  >
                    {{ singleContact.organization.name }}
                  </b-link>
                  <span v-else>{{ singleContact.organization.name }}</span>
                </b-badge>

                <div class="mt-50">
                  <!--  edit btn -->
                  <b-button
                    v-if="checkRequiredPermissions([$roles.CONTACT_EDIT_DETAILS])"
                    size="sm"
                    variant="warning"
                    :to="`/contacts/edit/${singleContact.id}`"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="14"
                    />
                    {{ $t("Edit") }}
                  </b-button>

                  <!-- Delete btn -->
                  <b-button
                    v-if="checkRequiredPermissions([$roles.CONTACT_DELETE])"
                    size="sm"
                    variant="outline-danger"
                    class="ml-50"
                    @click="removeContact(singleContact.id)"
                  >
                    <feather-icon
                      icon="TrashIcon"
                      size="14"
                    />
                    {{ $t("Delete") }}
                  </b-button>
                </div>
              </div>
            </b-col>

            <!-- Right Col: Table -->
            <b-col
              cols="12"
              xl="8"
              sm="12"
            >
              <!-- User Info -->
              <user-info :contact="singleContact"
                         :chats="allowedChats"
              />
            </b-col>

            <b-col
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_FORM_NOTE])"
              sm="12"
              class="mt-50"
            >
              <b-alert
                v-if="singleContact.note"
                show
                variant="primary"
              >
                <h4 class="alert-heading">
                  {{ $t('Note') }}
                </h4>
                <div class="alert-body">
                  <div v-html="(singleContact.note || '').replaceAll('\n', '<br>')" />
                </div>
              </b-alert>
            </b-col>
          </b-row>

          <app-collapse
            v-if="checkRequiredViewPermissions([
              $viewRanks.SHOW_CONTACT_CARD_BASIC_INFORMATION,
              $viewRanks.SHOW_CONTACT_CARD_APPLICATIONS
            ])"
            accordion
            class="mt-n2 mb-n1"
          >
            <app-collapse-item
              v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_BASIC_INFORMATION])"
              :title="$t('contact.basicInformations')"
              :is-title="false"
            >
              <template #subTitle>
                <span class="font-weight-bold">
                  {{ $t('contact.basicInformations') }}
                </span>
              </template>

              <user-details :contact="singleContact" />
            </app-collapse-item>

            <applications
              v-if="singleContact && contactThreads.length && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_APPLICATIONS]) && checkRequiredModule('applicationModule') && checkRequiredPermissions([$roles.APPLICATION_VIEW_ALL, $roles.APPLICATION_VIEW_ASSIGNED_CONTACT_THREAD])"
              :contact="{ ...singleContact, contactThreads: contactThreads }"
            />

            <additional-information
              v-if="singleContact && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_ADDITIONAL_FIELDS])"
              :contact-id="singleContact ? singleContact.id : null"
              :thread="selectedThread"
            />

            <campaigns
              v-if="singleContact && checkRequiredModule('campaignModule')"
              :id="singleContact.id"
            />
          </app-collapse>
          <!--    END::Info    -->

          <hr
            class="separator w-100 mt-75"
            style="background: rgba(0,0,0,0.5);"
          >

          <!--    START::Process    -->
          <div
            class="d-flex flex-column mt-n25"
            style="row-gap: .64rem"
          >
            <!--    START::Process -> Header    -->
            <div
              class="d-flex flex-wrap align-items-center justify-content-between"
              style="row-gap: .34rem; column-gap: .5rem;"
            >
              <div
                class="d-flex flex-wrap flex-column flex-md-row align-items-md-center"
                style="row-gap: .34rem; column-gap: .34rem; flex: 1;"
              >
                <div
                  class="d-flex align-items-center text-primary"
                  style="row-gap: .34rem; column-gap: .5rem;"
                >
                  <feather-icon
                    icon="FilterIcon"
                    size="18"
                  />

                  <h4 class="mb-0 text-primary text-capitalize">
                    {{ $t('YourProcesses') }}
                  </h4>
                </div>

                <div
                  v-if="contactThreads && contactThreads.length && selectedThread"
                  id="container-task"
                  class="d-flex flex-wrap align-items-center px-50"
                  style="row-gap: .34rem; column-gap: .64rem; flex: 1;"
                >
                  <div>
                    <sw-select
                      style="max-width: min(300px, 100%); margin-top: -.2rem; min-width: 275px"
                      class="mb-0"
                    >
                      <v-select
                        :options="contactThreads"
                        :value="selectedThread || ''"
                        label="name"
                        item-value="id"
                        item-text="name"
                        :placeholder="contactThreads.length ? '' : $t('NoData')"
                        class="select-size-sm bg-primary rounded-lg"
                        @input="onSelectThread"
                      >
                        <template #no-options>
                          {{ $t('NoOptions') }}
                        </template>

                        <template #selected-option="value">
                          <span class="text-white">
                            {{ value.name | textIsland(18, '…') }}
                          </span>
                        </template>

                        <template #option="value">
                          <div class="d-flex align-items-center">
                            <div v-if="value && value.contactThreadTasksOpened && value.contactThreadTasksOpened.length">
                              <feather-icon :id="`user_arrears_${value.id}`"
                                            class="mr-50 mt-25 text-danger"
                                            icon="ListIcon"
                              />
                            </div>

                            <b-popover
                              v-if="value"
                              :key="value.id"
                              ref="user_arrears_popover"
                              :target="`user_arrears_${value.id}`"
                              :container="'container-task'"
                              triggers="hover"
                              no-fade
                            >
                              <template #title>
                                <span>
                                  {{ $t('funnel.OverdueTasks') }}
                                </span>
                              </template>

                              <div
                                v-if="value.contactThreadTasksOpened && !value.contactThreadTasksOpened.length"
                                class="text-center"
                              >
                                <feather-icon
                                  icon="CheckSquareIcon"
                                  class="text-success"
                                  style="margin: 3px auto 5px"
                                  size="20"
                                />
                                <div class="px-1">
                                  {{ $t('funnel.NoArrears') }}
                                </div>
                              </div>
                              <div v-else>
                                <b-badge
                                  variant="primary"
                                  class="d-flex justify-content-between align-items-center mx-50"
                                  style="margin-top: -5px"
                                >
                                  {{ $t('All') }}
                                  <b-badge variant="light">
                                    <span class="text-primary">
                                      {{ value.contactThreadTasksOpenedTotalItemCount }}
                                    </span>
                                  </b-badge>
                                </b-badge>
                                <b-list-group flush>
                                  <b-list-group-item
                                    v-for="(item, index) in value.contactThreadTasksOpened"
                                    :key="`user_${value.id}_task_${index}`"
                                    class="cursor-pointer"
                                    @click="openModal('viewModal', $t('PreviewTask'), '', item)"
                                  >
                                    <div class="d-flex justify-content-between align-items-center">
                                      <div>
                                        <h5>
                                          <feather-icon
                                            v-if="item.outdated"
                                            v-b-tooltip
                                            class="text-danger"
                                            icon="AlertCircleIcon"
                                            :title="$t('funnel.OverdueTask')"
                                          />
                                          {{ item.contactThreadTaskType.name }}
                                        </h5>
                                        <small class="d-flex align-items-center">
                                          {{ item.endAt.date.split('.')[0] }}
                                        </small>
                                      </div>
                                    </div>
                                    <div class="mt-25">
                                      <small v-if="item.content">{{ item.content | truncate(100, '...') }}</small>
                                    </div>
                                  </b-list-group-item>
                                </b-list-group>
                              </div>
                            </b-popover>

                            <span v-if="value && value.assigned">
                              <feather-icon
                                icon="AlertCircleIcon"
                                class="text-primary mr-25"
                              />
                              {{ value.contact.firstName }} {{ value.contact.lastName }} -
                            </span>

                            <div>
                              {{ value.name | textIsland(18, '…') }}
                              <div v-if="value.contactThreadFunnelProcessLast">
                                <b-badge variant="primary">
                                  {{ (value.contactThreadFunnelProcessLast.contactThreadFunnelType.name + ' - ' + value.contactThreadFunnelProcessLast.contactThreadFunnelTypePoint.name) | truncate(40) }}
                                </b-badge>
                              </div>
                            </div>
                          </div>
                        </template>
                      </v-select>
                    </sw-select>
                    <!--                    <b-form-checkbox-->
                    <!--                      v-if="checkRequiredPermissions([-->
                    <!--                        $roles.CONTACT_THREAD_EDIT_ALL,-->
                    <!--                        { role: $roles.CONTACT_THREAD_EDIT_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'assignedUsers' },-->
                    <!--                        { role: $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'createdBy' }-->
                    <!--                      ])"-->
                    <!--                      v-model="selectedThread.isPrimary"-->
                    <!--                      @change="setSelectedThreadAsPrimary"-->
                    <!--                      class="mt-25"-->
                    <!--                    >{{ $t('SetAsPrimaryThread') }}</b-form-checkbox>-->
                  </div>

                  <feather-icon
                    v-if="
                      singleContact.contactThreadAssignedContacts
                        && singleContact.contactThreadAssignedContacts.length
                        && checkRequiredPermissions([$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_STRUCTURE, $roles.CONTACT_THREAD_VIEW_ASSIGNED])
                    "
                    v-b-tooltip
                    :title="$t('ThisContactIsAssigned')"
                    icon="AlertCircleIcon"
                    class="text-primary ml-25"
                  />

                  <b-button-group
                    class="d-flex flex-row flex-wrap"
                    style="row-gap: .34rem"
                  >
                    <b-button
                      v-if="checkRequiredPermissions([
                        $roles.CONTACT_THREAD_EDIT_ALL,
                        { role: $roles.CONTACT_THREAD_EDIT_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'assignedUsers' },
                        { role: $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'createdBy' }
                      ])"
                      v-b-tooltip.hover.v-primary
                      :variant="selectedThread.isPrimary ? 'primary' : 'outline-primary'"
                      class="d-flex align-items-center justify-content-center"
                      style="padding: .45rem .6rem"
                      size="sm"
                      :title="selectedThread.isPrimary ? $t('PrimaryThread') : $t('SetThreadAsPrimary')"
                      @click="setSelectedThreadAsPrimary(!selectedThread.isPrimary)"
                    >
                      <sw-icon
                        :icon="selectedThread.isPrimary ? 'HeartIcon' : 'CircleIcon'"
                      />
                    </b-button>
                    <b-button
                      v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_ADD])"
                      v-b-tooltip.hover.v-success
                      variant="outline-success"
                      class="d-flex align-items-center justify-content-center"
                      style="padding: .45rem .6rem"
                      size="sm"
                      :title="$t('AddNewProcess')"
                      @click="openModal()"
                    >
                      <feather-icon
                        icon="PlusIcon"
                      />
                    </b-button>

                    <b-button
                      v-if="
                        checkRequiredPermissions([
                          $roles.CONTACT_THREAD_EDIT_ALL,
                          { role: $roles.CONTACT_THREAD_EDIT_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'assignedUsers' },
                          { role: $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'createdBy' }
                        ])
                      "
                      v-b-tooltip.hover.v-warning
                      variant="outline-warning"
                      size="sm"
                      class="flex-grow-1 d-flex align-items-center justify-content-center btn-icon"
                      style="padding: .45rem .55rem"
                      :disabled="checkRequiredPermissions([$roles.CONTACT_THREAD_EDIT_ALL, $roles.CONTACT_THREAD_EDIT_ASSIGNED, $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED]) && (!selectedThread || !selectedThread.id)"
                      :title="$t('Edit')"
                      @click="editThread(false)"
                    >
                      <feather-icon
                        icon="EditIcon"
                      />
                    </b-button>

                    <b-button
                      v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_DELETE])"
                      v-b-tooltip.hover.v-danger
                      variant="outline-danger"
                      size="sm"
                      class="flex-grow-1 d-flex align-items-center justify-content-center btn-icon"
                      style="padding: .45rem .55rem"
                      :disabled="checkRequiredPermissions([$roles.CONTACT_THREAD_ADD]) && (!selectedThread || !selectedThread.id)"
                      :title="$t('Remove')"
                      @click="deleteThread"
                    >
                      <feather-icon
                        icon="TrashIcon"
                      />
                    </b-button>

                    <b-button
                      v-if="selectedThread.mailConversationFirst"
                      v-b-tooltip.hover.v-primary
                      variant="outline-primary"
                      size="sm"
                      class="flex-grow-1 d-flex align-items-center justify-content-center btn-icon"
                      style="padding: .45rem .55rem"
                      :title="$t('Copy') + ' email'"
                      @click="copy(selectedThread.mailConversationFirst.fromMail)"
                    >
                      <feather-icon
                        icon="CopyIcon"
                      />
                    </b-button>
                  </b-button-group>

                  <div
                    class="d-flex flex-column"
                    style="row-gap: .34rem"
                  >
                    <div class="d-flex">
                      <div
                        class="d-flex align-items-center mr-50"
                        style="column-gap: .5rem"
                      >
                        <strong class="mb-0">
                          {{ $t('PrimaryUser') }}
                        </strong>

                        <avatar
                          :user="selectedThread.assignedLeadUser"
                          size="sm"
                          show-tooltip
                          :show-name="false"
                        />
                      </div>
                      <div
                        class="d-flex align-items-center"
                        style="column-gap: .5rem"
                      >
                        <strong class="mb-0">
                          {{ $t('AssignedUsers') }}
                        </strong>

                        <avatar-group
                          :items="selectedThread.assignedUsers"
                          size="sm"
                        />
                      </div>
                    </div>

                    <div
                      class="d-flex align-items-center"
                      style="column-gap: .5rem"
                    >
                      <strong class="mb-0">
                        {{ $t('AssignedContact') }}
                      </strong>
                      <avatar-group
                        v-if="selectedThread.contactThreadAssignedContacts.map(g => g.contact).length"
                        :items="selectedThread.contactThreadAssignedContacts.map(g => g.contact)"
                        size="sm"
                        contact-action-popover
                      />
                      <b-button
                        v-else-if="checkRequiredPermissions([$roles.CONTACT_THREAD_EDIT_ALL, $roles.CONTACT_THREAD_EDIT_ASSIGNED, $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED])"
                        size="sm"
                        variant="flat-success"
                        class="btn-icon"
                        @click="editThread(true)"
                      >
                        <feather-icon icon="PlusIcon" />
                      </b-button>
                      <feather-icon v-else
                                    icon="MinusIcon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--    END::Process -> Header    -->

            <!--    START::Process -> Body    -->
            <div
              v-if="contactThreads && contactThreads.length && selectedThread"
              class="px-1 px-md-2 d-flex flex-column"
              style="row-gap: 1rem"
            >
              <!--    START::Process -> Information    -->
              <b-row style="row-gap: .64rem">
                <b-col
                  md="12"
                  lg="4"
                  class="d-flex flex-wrap flex-column flex-md-row align-items-md-center"
                  :class="{ 'justify-content-between align-items-center': !checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PREDICTED_VALUE]) }"
                >
                  <b-alert
                    v-if="selectedThread && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PREDICTED_VALUE])"
                    variant="primary"
                    style="font-size: 1.1rem"
                    class="d-flex align-items-center justify-content-center flex-wrap text-primary m-0"
                    show
                  >
                    <div class="alert-body d-flex flex-wrap px-50 py-25 text-capitalize">
                      {{ $t('ProcessValue') }}:

                      <strong class="ml-50">
                        {{ (selectedThread.predictedValue ? selectedThread.predictedValue : 0) | priceFormat }}
                        {{ selectedThread.predictedValueCurrency || 'PLN' }}
                      </strong>
                    </div>
                  </b-alert>

                  <user-nav
                    v-if="selectedThread && !checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PREDICTED_VALUE])"
                    class="float-right"
                    :thread="selectedThread"
                    :user="currentUser"
                  />
                </b-col>

                <b-col
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_CHECKLISTS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_CHECKLIST_VIEW_ALL,])"
                  md="6"
                  lg="4"
                  class="d-flex flex-wrap align-items-center justify-content-between"
                  style="column-gap: .5rem; row-gap: .14rem;"
                >
                  <div
                    class="d-flex align-items-center"
                    style="column-gap: .5rem"
                  >
                    <span class="text-primary">
                      <feather-icon icon="BarChart2Icon" />
                    </span>

                    <h5 class="mb-0 text-capitalize">
                      {{ $t('funnel.ChecklistProgress') }}
                    </h5>
                  </div>

                  <b-badge variant="light-primary">
                    <span class="text-primary">
                      {{ `${checklistDetails.done} / ${checklistDetails.all}` }}
                    </span>
                  </b-badge>
                </b-col>

<!--                <b-col-->
<!--                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_THREAD_FUNNEL_PROGRESS])"-->
<!--                  md="6"-->
<!--                  lg="4"-->
<!--                  class="d-flex flex-wrap align-items-center justify-content-between"-->
<!--                  style="column-gap: .5rem; row-gap: .14rem;"-->
<!--                >-->
<!--                  <div-->
<!--                    class="d-flex align-items-center"-->
<!--                    style="column-gap: .5rem"-->
<!--                  >-->
<!--                    <span class="text-primary">-->
<!--                      <feather-icon icon="BarChartIcon" />-->
<!--                    </span>-->

<!--                    <h5 class="mb-0 text-capitalize">-->
<!--                      {{ $t('funnel.StatusesProgress') }}-->
<!--                    </h5>-->
<!--                  </div>-->

<!--                  <b-badge variant="light-primary">-->
<!--                    <span class="text-primary">-->
<!--                      {{ `${progressDetails} %` }}-->
<!--                    </span>-->
<!--                  </b-badge>-->
<!--                </b-col>-->
              </b-row>
              <!--    END::Process -> Information    -->

              <!--    START::Process -> Buttons    -->
              <div
                v-if="contactThreads && contactThreads.length && selectedThread && checkRequiredPermissions([$roles.CONTACT_THREAD_VIEW_ALL, $roles.CONTACT_THREAD_VIEW_STRUCTURE, $roles.CONTACT_THREAD_VIEW_STRUCTURE_FIRST, $roles.CONTACT_THREAD_VIEW_ASSIGNED])"
                class="d-flex flex-row flex-wrap align-items-start justify-content-between"
                style="row-gap: .75rem"
              >
                <div
                  class="d-flex flex-column"
                  style="column-gap: .5rem; row-gap: .14rem;"
                >
                  <!-- Funnel Point -->
                  <div
                    class="d-flex flex-wrap align-items-center justify-content-between"
                    style="column-gap: 1rem; row-gap: .14rem;"
                  >
                    <p class="mb-0 h5 text-capitalize">
                      {{ $t('ProcessFunnel') }}
                    </p>

                    <div
                      class="d-flex align-items-center"
                      style="column-gap: .5rem"
                    >
                      <b-badge
                        id="status_point_popover"
                        v-b-popover.hover:status_point_popover_ref
                        variant="primary"
                      >
                        <span v-if="selectedThread.contactThreadFunnelTypePoint && selectedThread.contactThreadFunnelTypePoint.contactThreadFunnelType">
                          {{ selectedThread.contactThreadFunnelTypePoint.contactThreadFunnelType.name | textIsland(28, '…') }}
                        </span>
                        <feather-icon
                          v-else
                          icon="MinusIcon"
                        />
                      </b-badge>
                    </div>
                  </div>

                  <div
                    class="d-flex flex-wrap align-items-center justify-content-between"
                    style="column-gap: .5rem; row-gap: .14rem;"
                  >

                    <div
                      class="d-flex flex-wrap align-items-center"
                      style="column-gap: .34rem; row-gap: .34rem;"
                    >
                      <p
                        v-if="(selectedThread.status === 'OPEN' || selectedThread.status === 'PAUSED') && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_END_STATUS])"
                        class="mb-0"
                      >
                        {{ $t('FinishAs') }}
                      </p>

                      <b-button-group
                        v-if="(selectedThread.status === 'OPEN' || selectedThread.status === 'PAUSED') && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_END_STATUS])"
                      >
                        <b-button
                          variant="flat-success"
                          size="sm"
                          class="p-0"
                          @click="setStatus(statusList.COMPLETED)"
                        >
                          {{ $t('funnel.status.Success') }}
                        </b-button>

                        <span class="text-lowercase mx-25">{{ $t('Or') }}</span>

                        <b-button
                          variant="flat-danger"
                          size="sm"
                          class="p-0"
                          @click="setStatus(statusList.REJECTED)"
                        >
                          {{ $t('funnel.status.Failure') }}
                        </b-button>
                      </b-button-group>

                      <!-- Status -->
                      <b-popover
                        v-if="checkRequiredPermissions([
                          $roles.CONTACT_THREAD_EDIT_ALL,
                          { role: $roles.CONTACT_THREAD_EDIT_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'assignedUsers' },
                          { role: $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'createdBy' }
                        ]) && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_STATUS_EDIT])"
                        ref="status_point_popover_ref"
                        triggers="hover"
                        no-fade
                        target="status_point_popover"
                        container="customizedTable"
                        @show="onGetFunnelPoints"
                      >
                        <template #title>
                          <span>{{ $t("ChangeProcess") }}</span>
                        </template>
                        <b-list-group flush>
                          <b-list-group-item
                            v-for="funnelType in funnels"
                            :key="`status_point_${funnelType.id}`"
                            class="cursor-pointer"
                            :class="{ 'text-muted cursor-not-allowed': selectedThread.contactThreadFunnelTypePoint.contactThreadFunnelType && (selectedThread.contactThreadFunnelTypePoint.contactThreadFunnelType.id === funnelType.id) }"
                            @click="selectedThread.contactThreadFunnelTypePoint.contactThreadFunnelType && (selectedThread.contactThreadFunnelTypePoint.contactThreadFunnelType.id === funnelType.id) ? null : changeFunnel(funnelType.id)"
                          >
                            <span
                              v-if="existedFunnelProcess(funnelType.id)"
                              class="mr-25"
                            >
                              <span
                                v-if="existedFunnelProcess(funnelType.id).status === 'CLOSED_POSITIVE'"
                                v-b-tooltip.hover
                                :title="$t('funnel.status.COMPLETED')"
                              >
                                <feather-icon
                                  class="text-success"
                                  icon="CheckCircleIcon"
                                />
                              </span>
                              <span
                                v-else-if="existedFunnelProcess(funnelType.id).status === 'CLOSED_NEGATIVE'"
                                v-b-tooltip
                                :title="$t('funnel.status.REJECTED')"
                              >
                                <feather-icon
                                  class="text-danger"
                                  icon="XCircleIcon"
                                />
                              </span>
                              <span
                                v-else-if="existedFunnelProcess(funnelType.id).status === 'PAUSED'"
                                v-b-tooltip
                                :title="$t('Paused')"
                              >
                                <feather-icon
                                  class="text-warning"
                                  icon="PauseCircleIcon"
                                />
                              </span>
                              <feather-icon
                                v-else
                                class="text-primary"
                                icon="CircleIcon"
                              />
                            </span>
                            {{ funnelType.name }}
                          </b-list-group-item>
                        </b-list-group>
                      </b-popover>
                    </div>
                  </div>
                </div>

                <user-nav
                  v-if="selectedThread && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PREDICTED_VALUE])"
                  :thread="selectedThread"
                  :user="currentUser"
                />
              </div>
              <!--    END::Process -> Buttons    -->

              <!--    START::Process -> Status    -->
              <sw-button-breadcrumb
                v-if="checkRequiredPermissions([
                  $roles.CONTACT_THREAD_EDIT_ALL,
                  { role: $roles.CONTACT_THREAD_EDIT_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'assignedUsers' },
                  { role: $roles.CONTACT_THREAD_EDIT_SELF_ASSIGNED, data: selectedThread, userId: currentUser.id, field: 'createdBy' }
                ]) && checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_STATUS_LIST])"
                class="status-bar d-flex flex-wrap"
              >
                <b-col
                  v-for="(funnel, funnelIndex) in funnelPoints"
                  :key="`funnel__${funnel.id}`"
                  cols="12"
                  sm="6"
                  md="4"
                  lg="2"
                  :class="
                    selectedThread.contactThreadFunnelTypePoint && selectedThread.contactThreadFunnelTypePoint.id === funnel.id
                      ? 'active'
                      : (selectedThread.contactThreadFunnelTypePoint ? funnelPoints.findIndex(e => selectedThread.contactThreadFunnelTypePoint.id == e.id) > funnelIndex && 'activated' : '')
                  "
                  @click="selectedThread.contactThreadFunnelTypePoint && selectedThread.contactThreadFunnelTypePoint.id !== funnel.id && changeFunnelPoint(funnel.id)"
                >
                  <div
                    class="d-flex align-items-center flex-nowrap text-nowrap overflow-hidden"
                    style="column-gap: .14rem;"
                  >
                    <!--                    <span style="letter-spacing: .1rem">-->
                    <!--                      0/0-->
                    <!--                    </span>-->

                    <!--                    <feather-icon-->
                    <!--                      icon="ArrowRightIcon"-->
                    <!--                      style="min-width: 1rem"-->
                    <!--                    />-->

                    <span v-b-tooltip.hover
                          :title="funnel.name"
                    >
                      {{ funnel.name | truncate(16) }}
                    </span>
                    <sw-icon v-if="funnel.description"
                             v-b-tooltip
                             class="ml-25"
                             icon="AlertCircleIcon"
                             :title="funnel.description"
                    />
                  </div>

                  <svg
                    width="15"
                    height="50"
                    viewBox="0 0 15 50"
                    class="ic-chevron"
                  >
                    <polygon
                      points="0,0 10,25 0,50"
                      class="ic-chevron-icon-bg"
                    />

                    <polygon
                      points="0,0 5,0 15,25 5,50 0,50 10,25"
                      class="ic-chevron-icon"
                    />
                  </svg>
                </b-col>
              </sw-button-breadcrumb>
              <!--    END::Process -> Status    -->
              <b-alert
                v-if="selectedThread.status !== 'OPEN' && selectedThread.status !== 'PAUSED'"
                show
                :variant="selectedThread.status === 'CLOSED_POSITIVE' ? 'success' : 'danger'"
              >
                <div class="alert-heading">
                  <div class="d-flex justify-content-between">
                    {{ selectedThread.status === 'CLOSED_POSITIVE' ? $t('funnel.status.COMPLETED') : $t('funnel.status.REJECTED') }}
                    <div v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_END_STATUS])">
                      <b-button
                        v-b-tooltip
                        size="sm"
                        class="font-weight-bolder text-uppercase"
                        :variant="selectedThread.status === 'CLOSED_POSITIVE' ? 'flat-success' : 'flat-danger'"
                        :title="$t('OpenFunnelProcessButtonTooltip')"
                        @click="openThread"
                      >
                        {{ $t('Open') }} {{ $t('Thread').toLowerCase() }}
                      </b-button>

                      <b-button
                        v-b-tooltip
                        size="sm"
                        class="font-weight-bolder text-uppercase ml-25"
                        :variant="selectedThread.status === 'CLOSED_POSITIVE' ? 'flat-success' : 'flat-danger'"
                        :title="$t('ContinueFunnelProcessButtonTooltip')"
                        @click="changeCloseStatus(false, true)"
                      >
                        {{ $t('Continue') }} {{ $t('Thread').toLowerCase() }}
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="alert-body">
                  <div><span class="font-weight-bolder">{{ $t('CloseReason') }}</span>:
                    <span v-if="selectedThread.contactThreadStatusReason">
                      {{ selectedThread.contactThreadStatusReason.name }}
                    </span>
                    <feather-icon
                      v-else
                      icon="MinusIcon"
                    />
                  </div>
                  <div>
                    <span class="font-weight-bolder">{{ $t('Description') }}</span>:
                    <span v-if="selectedThread.statusNote">
                      {{ selectedThread.statusNote }}
                    </span>
                    <feather-icon
                      v-else
                      icon="MinusIcon"
                    />
                  </div>
                </div>
              </b-alert>
            </div>

            <b-alert
              v-else
              variant="primary"
              show
            >
              <div
                class="alert-body d-flex flex-column align-items-center py-2 py-md-3 px-md-3 py-lg-5 px-lg-5"
                style="row-gap: 1rem"
              >
                <div
                  class="d-flex flex-column flex-lg-row align-items-center align-items-lg-start"
                  style="column-gap: .5rem"
                >
                  <feather-icon
                    icon="FilterIcon"
                    size="24"
                    class="mb-1 mb-lg-0"
                  />

                  <h4
                    class="text-primary text-center"
                    style="gap: .5rem"
                  >
                    <template v-if="contactThreads && contactThreads.length">
                      {{ $t('ChooseOneOfExistingThreads') }}
                    </template>

                    <template v-else>
                      {{ $t('OpenNewThreadToStartNewProcessWithContact') }}
                    </template>
                  </h4>
                </div>

                <div id="container-no-select-task"
                     class="d-flex flex-column align-items-center w-100"
                >
                  <sw-select
                    v-if="contactThreads && contactThreads.length"
                    style="max-width: min(410px, 100%); width: 40rem !important;"
                  >
                    <v-select
                      :options="contactThreads"
                      :value="selectedThread || ''"
                      label="name"
                      item-value="id"
                      item-text="name"
                      :placeholder="contactThreads.length ? '' : $t('NoData')"
                      @input="onSelectThread"
                    >
                      <template #no-options>
                        {{ $t('NoOptions') }}
                      </template>

                      <template #selected-option="value">
                        {{ value.name | textIsland(18, '…') }}
                      </template>

                      <template #option="value">
                        <div class="d-flex align-items-center">
                          <feather-icon v-if="value && value.contactThreadTasksOpened && value.contactThreadTasksOpened.length"
                                        :id="`user_no_select_arrears_${value.id}`"
                                        class="mr-50 mt-25 text-danger"
                                        icon="ListIcon"
                          />

                          <feather-icon v-if="value && value.isPrimary"
                                        :id="`user_${value.id}`"
                                        class="mr-50 mt-25 text-danger"
                                        icon="HeartIcon"
                          />

                          <b-popover
                            v-if="value"
                            :key="value.id"
                            ref="user_no_select_arrears_popover"
                            :target="`user_no_select_arrears_${value.id}`"
                            :container="'container-no-select-task'"
                            triggers="hover"
                            no-fade
                          >
                            <template #title>
                              <span>
                                {{ $t('funnel.OverdueTasks') }}
                              </span>
                            </template>

                            <div
                              v-if="value.contactThreadTasksOpened && !value.contactThreadTasksOpened.length"
                              class="text-center"
                            >
                              <feather-icon
                                icon="CheckSquareIcon"
                                class="text-success"
                                style="margin: 3px auto 5px"
                                size="20"
                              />
                              <div class="px-1">
                                {{ $t('funnel.NoArrears') }}
                              </div>
                            </div>
                            <div v-else>
                              <b-badge
                                variant="primary"
                                class="d-flex justify-content-between align-items-center mx-50"
                                style="margin-top: -5px"
                              >
                                {{ $t('All') }}
                                <b-badge variant="light">
                                  <span class="text-primary">
                                    {{ value.contactThreadTasksOpenedTotalItemCount }}
                                  </span>
                                </b-badge>
                              </b-badge>
                              <b-list-group flush>
                                <b-list-group-item
                                  v-for="(item, index) in value.contactThreadTasksOpened"
                                  :key="`user_${value.id}_task_${index}`"
                                  class="cursor-pointer"
                                  @click="openModal('viewModal', $t('PreviewTask'), '', item)"
                                >
                                  <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                      <h5>
                                        <feather-icon
                                          v-if="item.outdated"
                                          v-b-tooltip
                                          class="text-danger"
                                          icon="AlertCircleIcon"
                                          :title="$t('funnel.OverdueTask')"
                                        />
                                        {{ item.contactThreadTaskType.name }}
                                      </h5>
                                      <small class="d-flex align-items-center">
                                        {{ item.endAt.date.split('.')[0] }}
                                      </small>
                                    </div>
                                  </div>
                                  <div class="mt-25">
                                    <small v-if="item.content">{{ item.content | truncate(100, '...') }}</small>
                                  </div>
                                </b-list-group-item>
                              </b-list-group>
                            </div>
                          </b-popover>

                          <span v-if="value && value.assigned">
                            <feather-icon
                              icon="AlertCircleIcon"
                              class="text-primary mr-25"
                            />
                            {{ value.contact.firstName }} {{ value.contact.lastName }} -
                          </span>

                          <div>
                            {{ value.name | textIsland(18, '…') }}
                            <div v-if="value.contactThreadFunnelProcessLast">
                              <b-badge variant="primary">
                                {{ (value.contactThreadFunnelProcessLast.contactThreadFunnelType.name + ' - ' + value.contactThreadFunnelProcessLast.contactThreadFunnelTypePoint.name) | truncate(40) }}
                              </b-badge>
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-select>
                  </sw-select>

                  <b-button
                    v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_ADD])"
                    id="popover-reactive-1"
                    variant="primary"
                    size="lg"
                    :class="{ 'mt-1': contactThreads && contactThreads.length }"
                    @click="openModal"
                  >
                    {{ $t('AddNewThread') }}

                    <feather-icon icon="PlusIcon" />
                  </b-button>
                </div>
              </div>
            </b-alert>
            <!--    END::Process -> Body    -->

            <hr
              v-if="selectedThread"
              class="separator w-100 my-75"
              style="background: rgba(0,0,0,0.5);"
            >

            <!--    START::Stats    -->
            <div
              v-if="selectedThread"
              class="d-flex flex-column"
              style="row-gap: 1rem"
            >
              <!--    START::Process -> Body    -->
              <b-tabs
                v-if="selectedThread && !threadLoading"
                class="tabs-scroll mt-n25"
                style="row-gap: .5rem"
                nav-class="btn-group btn-group-row-gap-0.34"
                content-class="mt-1"
                pills
                no-nav-style
              >
                <b-tab
                  v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_TIMELINE_VIEW_ALL])"
                  :title="$t('contactTabs.Timeline')"
                  lazy
                  :active="activeTab === 'timeline'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'timeline' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'timeline' && 'text-white'}`"
                  @click="changeRouter('timeline')"
                >
                  <template #title>
                    <feather-icon icon="GitPullRequestIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('contactTabs.Timeline') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="GitPullRequestIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('contactTabs.Timeline') }}
                    </h4>
                  </div>

                  <timeline
                    class="px-md-2"
                    is-contact
                    :selected-thread="selectedThread"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_EMAILS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_MAIL_VIEW_ALL])"
                  :title="$t('Emails')"
                  lazy
                  :active="activeTab === 'emails'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'emails' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'emails' && 'text-white'}`"
                  @click="changeRouter('emails')"
                >
                  <template #title>
                    <feather-icon icon="MailIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Email') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="MailIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Email') }}
                    </h4>
                  </div>

                  <Emails
                    class="px-md-2"
                    :contact-thread="selectedThread.id"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_CHECKLISTS]) && checkRequiredPermissions([
                    $roles.CONTACT_THREAD_CHECKLIST_VIEW_ALL,
                  ])"
                  class="mt-50"
                  :title="$t('Checklist')"
                  lazy
                  :active="activeTab === 'checklist'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'checklist' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'checklist' && 'text-white'}`"
                  @click="changeRouter('checklist')"
                >
                  <template #title>
                    <feather-icon icon="CheckSquareIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('contactTabs.Checklist') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('contactTabs.Checklist') }}
                    </h4>
                  </div>

                  <Checklist
                    class="px-md-2"
                    :thread="selectedThread"
                    @changeChecklist="changeCheckList"
                    @check-point="reloadCharts"
                    @reload="loadThreadItem"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_TASKS]) && checkRequiredPermissions([$roles.CONTACT_THREAD_TASK_VIEW_ALL, $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE, $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED])"
                  :title="$t('Tasks')"
                  lazy
                  :active="activeTab === 'tasks'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'tasks' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'tasks' && 'text-white'}`"
                  @click="changeRouter('tasks')"
                >
                  <template #title>
                    <feather-icon icon="ListIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Tasks') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="ListIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Tasks') }}
                    </h4>
                  </div>

                  <Tasks
                    class="px-md-2"
                    :thread="selectedThread"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_DOCUMENTS]) && checkRequiredPermissions([
                    $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
                    $roles.CONTACT_THREAD_TASK_VIEW_STRUCTURE,
                    $roles.CONTACT_THREAD_TASK_VIEW_ALL,
                    $roles.CONTACT_THREAD_NOTE_VIEW_SHARED,
                    $roles.CONTACT_THREAD_NOTE_VIEW_SELF,
                    $roles.CONTACT_THREAD_NOTE_VIEW_ALL,
                    $roles.CONTACT_THREAD_TASK_VIEW_ASSIGNED,
                    $roles.CONTACT_THREAD_TASK_VIEW_ALL,
                    $roles.CONTACT_THREAD_MAIL_VIEW_ALL,
                  ])"
                  class="mt-50"
                  :title="$t('contactTabs.Documents')"
                  lazy
                  :active="activeTab === 'documents'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'documents' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'documents' && 'text-white'}`"
                  @click="changeRouter('documents')"
                >
                  <template #title>
                    <feather-icon icon="HardDriveIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Documents') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="HardDriveIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Documents') }}
                    </h4>
                  </div>

                  <Documents
                    class="px-md-2"
                    :user-documents="currentUser.documents"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_OFFERS]) && checkRequiredModule('offerModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_OFFER_VIEW_SELF])"
                  :title="$t('contact.offer')"
                  lazy
                  :active="activeTab === 'offers'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'offers' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'offers' && 'text-white'}`"
                  @click="changeRouter('offers')"
                >
                  <template #title>
                    <feather-icon icon="CreditCardIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Offers') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="CreditCardIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Offers') }}
                    </h4>
                  </div>

                  <Offer
                    class="px-md-2"
                    :thread-id="selectedThread.id"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_ORDERS]) && checkRequiredModule('orderModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_ORDER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_ORDER_VIEW_SELF])"
                  :title="$t('contact.offer')"
                  lazy
                  :active="activeTab === 'orders'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'orders' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'orders' && 'text-white'}`"
                  @click="changeRouter('orders')"
                >
                  <template #title>
                    <feather-icon icon="ShoppingBagIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Orders') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="ShoppingBagIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Orders') }}
                    </h4>
                  </div>

                  <Orders
                    class="px-md-2"
                    :thread-id="selectedThread.id"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_LOYALTY_POINTS]) && checkRequiredModule('loyaltyModule') && checkRequiredPermissions([
                    $roles.USER_LOYALTY_PLAN_VIEW_ALL,
                    $roles.USER_LOYALTY_PLAN_VIEW_SELF,
                    $roles.USER_LOYALTY_PLAN_VIEW_STRUCTURE,
                    $roles.USER_LOYALTY_PLAN_VIEW_STRUCTURE_FIRST,
                    $roles.LOYALTY_PLAN_POINT_VIEW_ALL,
                    $roles.LOYALTY_PLAN_POINT_VIEW_SELF,
                    $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE,
                    $roles.LOYALTY_PLAN_POINT_VIEW_STRUCTURE_FIRST,
                  ])"
                  :title="$t('LoyaltyPoints')"
                  lazy
                  :active="activeTab === 'loyalty-points'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'loyalty-points' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'loyalty-points' && 'text-white'}`"
                  @click="changeRouter('loyalty-points')"
                >
                  <template #title>
                    <feather-icon icon="StarIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('LoyaltyPoints') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="StarIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('LoyaltyPoints') }}
                    </h4>
                  </div>

                  <LoyaltyPoints
                    class="px-md-2"
                    :thread-id="selectedThread.id"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_LOYALTY_POINTS]) && checkRequiredModule('marketingModule')"
                  :title="$t('LoyaltyPoints')"
                  lazy
                  :active="activeTab === 'marketing-points'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'marketing-points' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'marketing-points' && 'text-white'}`"
                  @click="changeRouter('marketing-points')"
                >
                  <template #title>
                    <feather-icon icon="StarIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('MarketingPlans') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="StarIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('MarketingPlans') }}
                    </h4>
                  </div>

                  <SwCommissionTable
                    class="px-md-2"
                    :contact-thread="selectedThread"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_AGREEMENTS]) && checkRequiredModule('agreementModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF])"
                  :title="$t('ListContracts')"
                  lazy
                  :active="activeTab === 'agreements'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'agreements' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'agreements' && 'text-white'}`"
                  @click="changeRouter('agreements')"
                >
                  <template #title>
                    <feather-icon icon="BookOpenIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('ranks.sections.agreements') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="BookOpenIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('ranks.sections.agreements') }}
                    </h4>
                  </div>

                  <Agreements
                    class="px-md-2"
                    :contact="singleContact"
                    :thread="selectedThread"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_POLLS]) && checkRequiredModule('pollModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_POLL_VIEW_ALL])"
                  :title="$t('Polls')"
                  lazy
                  :active="activeTab === 'polls'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'polls' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'polls' && 'text-white'}`"
                  @click="changeRouter('polls')"
                >
                  <template #title>
                    <feather-icon icon="EditIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Polls') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="EditIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Polls') }}
                    </h4>
                  </div>

                  <Polls
                    class="px-md-2"
                    :thread-id="selectedThread.id"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PAYMENTS]) && checkRequiredModule('paymentModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_PAYMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_ALL, $roles.CONTACT_THREAD_ASSIGNED_PAYMENT_VIEW_SELF])"
                  :title="$t('contactTabs.Payments')"
                  lazy
                  :active="activeTab === 'payments'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'payments' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'payments' && 'text-white'}`"
                  @click="changeRouter('payments')"
                >
                  <template #title>
                    <feather-icon icon="DollarSignIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Schedules') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="DollarSignIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Schedules') }}
                    </h4>
                  </div>

                  <Payments
                    class="px-md-2"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredViewPermissions([$viewRanks.SHOW_CONTACT_CARD_PROJECTS]) && checkRequiredModule('projectModule') && checkRequiredPermissions([$roles.PROJECT_VIEW_ALL])"
                  :title="$t('contactTabs.Projects')"
                  lazy
                  :active="activeTab === 'projects'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'projects' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'projects' && 'text-white'}`"
                  @click="changeRouter('projectsPanel')"
                >
                  <template #title>
                    <feather-icon icon="BookmarkIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Projects') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="BookmarkIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Projects') }}
                    </h4>
                  </div>

                  <projects
                    class="px-md-2"
                    :start-filters="{ contactThreads: [selectedThread] }"
                    :thread="selectedThread"
                    :show-filters="false"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredModule('complaintModule') && checkRequiredPermissions([
                    $roles.CONTACT_THREAD_COMPLAINT_VIEW_ALL,
                    $roles.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_ALL,
                    $roles.CONTACT_THREAD_ASSIGNED_COMPLAINT_VIEW_SELF,
                  ])"
                  :title="$t('Complaints')"
                  lazy
                  :active="activeTab === 'complaints'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'complaints' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'complaints' && 'text-white'}`"
                  @click="changeRouter('complaints')"
                >
                  <template #title>
                    <feather-icon icon="FrownIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Complaints') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="FrownIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Complaints') }}
                    </h4>
                  </div>

                  <complain-list
                    :contact-thread="selectedThread"
                    tag="div"
                  />
                </b-tab>

                <b-tab
                  v-if="checkRequiredModule('callModule') && checkRequiredPermissions([$roles.PHONE_CALLS_VIEW_ALL,])"
                  :title="$t('Calls')"
                  lazy
                  :active="activeTab === 'calls'"
                  :title-item-class="`
                      btn btn-sm px-50 py-25
                      ${isMobile && 'btn-icon'}
                      ${activeTab !== 'calls' ? 'btn-outline-primary' : 'btn-primary'}
                    `"
                  :title-link-class="`${activeTab === 'calls' && 'text-white'}`"
                  @click="changeRouter('calls')"
                >
                  <template #title>
                    <feather-icon icon="PhoneIcon" />

                    <span class="d-none d-lg-inline-block ml-50">
                      {{ $t('Calls') }}
                    </span>
                  </template>

                  <div
                    class="d-flex align-items-center text-primary mb-1"
                    style="row-gap: .34rem; column-gap: .5rem;"
                  >
                    <feather-icon
                      icon="PhoneIcon"
                      size="18"
                    />

                    <h4 class="mb-0 text-primary text-capitalize">
                      {{ $t('Calls') }}
                    </h4>
                  </div>

                  <Calls
                    :thread="selectedThread"
                    tag="div"
                  />
                </b-tab>
              </b-tabs>

              <div
                v-else-if="threadLoading"
                class="text-center"
              >
                <b-spinner variant="primary" />
              </div>
              <!--    END::Process -> Body    -->
            </div>
            <!--    END::Stats    -->
          </div>
          <!--    END::Process    -->
        </b-card-body>
      </b-card>
    </div>
    <div v-else>
      <b-overlay
        :show="loading"
        no-wrap
        spinner-variant="primary"
      />
    </div>

    <b-modal
      v-model="showCloseNote"
      hide-footer
      :title="$t('FinishAs') + ' ' + (closeNote.status !== 'REJECTED' ? $t('funnel.status.Success') : $t('funnel.status.Failure'))"
      no-close-on-backdrop
    >
      <!-- Edited name  -->
      <sw-select :name="$t('CloseReason')">
        <v-select
          v-model="closeNote.reason"
          :options="closeNote.status !== 'REJECTED' ? getPositive : getNegative"
          label="name"
          :reduce="reason => reason.id"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>
        </v-select>
      </sw-select>
      <b-form-group :label="$t('note.Description')">
        <b-form-textarea
          v-model="closeNote.note"
          rows="5"
        />
      </b-form-group>
      <b-button
        variant="success"
        :disabled="!closeNote.reason || !closeNote.note"
        @click="changeCloseStatus(false)"
      >
        {{ $t('Finish') }}
      </b-button>
    </b-modal>

    <b-modal
      v-model="showOpenProcess"
      hide-footer
      :title="$t('Open') + ' ' + $t('ContactThread').toLowerCase()"
      no-close-on-backdrop
    >
      <!-- Edited name  -->
      <sw-select :name="$t('funnel.SelectFunnel')">
        <v-select
          v-model="newProcessFunnel"
          :options="funnels"
          :selectable="f => f.contactThreadFunnelTypePoints && f.contactThreadFunnelTypePoints.length"
          label="name"
        >
          <template #no-options>
            {{ $t('NoOptions') }}
          </template>

          <template #option="item">
            <span
              v-if="existedFunnelProcess(item.id)"
              class="mr-25"
            >
              <span
                v-if="existedFunnelProcess(item.id).status === 'CLOSED_POSITIVE'"
                v-b-tooltip
                :title="$t('funnel.status.COMPLETED')"
              >
                <feather-icon
                  class="text-success"
                  icon="CheckCircleIcon"
                />
              </span>
              <span
                v-else-if="existedFunnelProcess(item.id).status === 'CLOSED_NEGATIVE'"
                v-b-tooltip
                :title="$t('funnel.status.REJECTED')"
              >
                <feather-icon
                  class="text-danger"
                  icon="XCircleIcon"
                />
              </span>
              <span
                v-else-if="existedFunnelProcess(item.id).status === 'PAUSED'"
                v-b-tooltip
                :title="$t('Paused')"
              >
                <feather-icon
                  class="text-warning"
                  icon="PauseCircleIcon"
                />
              </span>
              <feather-icon
                v-else
                class="text-primary"
                icon="CircleIcon"
              />
            </span>
            {{ item.name }}
          </template>
        </v-select>
      </sw-select>

      <b-alert
        v-if="selectedThread && newProcessFunnel && selectedThread.contactThreadFunnelProcesses.find(fp => fp.contactThreadFunnelType.id === newProcessFunnel.id)"
        show
        variant="primary"
      >
        <div class="alert-body">
          <!--          Wybrany lejk został już zamknięty, kontynuowanie spowoduje jego ponowne otwarcie-->
          {{ $t('SelectedProcessIsClosedAlready') }}
        </div>
      </b-alert>

      <b-button
        variant="success"
        :disabled="!newProcessFunnel"
        @click="createdFunnelProcess"
      >
        {{ $t('Open') }}
      </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BAlert,
  BButtonGroup,
  BFormTextarea,
  BModal,
  BPopover,
  BTab,
  BTabs,
  VBPopover,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import axiosIns from '@/libs/axios'
import * as roles from '@/helpers/permissions'
import UserDetails from '@/views/core/singleContact/parts/UserDetails.vue'
import Agreements from '@/pages/offer/Agreements.vue'
import Payments from '@/views/core/singleContact/tabs/Payments.vue'
import Applications from '@/pages/applications/Applications.vue'
import Campaigns from '@/pages/campaigns/List.vue'
import useVerticalLayout from '@core/layouts/layout-vertical/useVerticalLayout'
import { onUnmounted } from '@vue/composition-api'
import Orders from '@/pages/orders/List.vue'
import List from '@/pages/loyaltyPoints/List.vue'
import SwCommissionTable from '@/pages/commissions/List.vue'
import AdditionalInformation from '@/views/core/singleContact/tabs/AdditionalInformation.vue'
import SwButtonBreadcrumb from '@/components/ButtonBreadcrumb.vue'
import Timeline from '@/pages/contact.old/tabs/Timeline.vue'
import Calls from '@/pages/calls/List.vue'
import UserInfo from '../../views/core/singleContact/parts/UserInfo.vue'
import UserNav from '../../views/core/singleContact/parts/UserNav.vue'
import Tasks from '../../views/core/singleContact/tabs/Tasks.vue'
import Emails from '../../views/core/singleContact/tabs/Emails.vue'
import Checklist from '../../views/core/singleContact/tabs/Checklist.vue'
import Documents from '../../views/core/singleContact/tabs/Documents.vue'
import Offer from '../../views/core/singleContact/tabs/Offer.vue'
import Polls from '../../views/core/singleContact/tabs/Polls.vue'
import Projects from '../projects/projects/List.vue'
import ComplainList from '../complaints/List.vue'
import {
  DELETE_THREAD,
  GET_CONTACT_SINGLE_TASK,
  GET_CONTACT_SINGLE_THREAD,
  GET_CONTACT_THREADS,
  GET_FUNNEL_POINTS,
  GET_FUNNELS,
  GET_SINGLE_CONTACT,
  GET_THREAD_TAGS,
  OPEN_MODAL,
  REMOVE_CONTACTS, UPDATE_CONTACT_TASK,
  UPDATE_CONTACT_THREAD,
} from '../../@constants/mutations'

export default {
  components: {
    BFormTextarea,
    BModal,
    ComplainList,
    LoyaltyPoints: List,
    SwCommissionTable,
    AdditionalInformation,
    Orders,
    UserDetails,
    Checklist,
    Timeline,
    Tasks,
    Emails,
    Documents,
    Offer,
    Polls,
    Agreements,
    Payments,
    Applications,
    Campaigns,
    vSelect,
    Projects,
    BPopover,
    BTabs,
    BTab,
    BAlert,
    BButtonGroup,
    SwButtonBreadcrumb,
    // User info sections
    'user-info': UserInfo,
    'user-nav': UserNav,
    Calls,
  },
  directives: {
    'b-popover': VBPopover,
    'b-tooltip': VBTooltip,
  },
  data: () => ({
    showCloseNote: false,
    closeNote: {
      status: 'CLOSED_POSITIVE',
      reason: '',
      note: '',
    },
    reasons: [],
    checklistDetails: {
      all: 0,
      done: 0,
    },
    progressDetails: 0,
    statusList: {
      COMPLETED: 'COMPLETED',
      REJECTED: 'REJECTED',
    },
    allowedChats: [],
    roles,
    newProcessFunnel: '',
    isCompressedView: true,
    funnels: [],
    funnelPoints: [],
    checklists: [],
    newThreadLoading: false,
    popoverShow: false,
    showOpenProcess: false,
    newThreadName: '',
    newThreadFunnel: '',
    newThreadChecklist: '',
    threadLoading: false,
    loading: true,
    isEdited: false,
    user: null,
    selectedOffer: '',
    userFields: [
      'firstName',
      'lastName',
      'email',
      'companyName',
      'vatId',
      'address',
      'city',
      'phone',
    ],
    activeTab: 'timeline',
  }),
  computed: {
    ...mapGetters({
      singleContact: 'singleContact/getContact',
      contactThreads: 'singleContact/getContactThreads',
      contactStatuses: 'typeSettings/geContactStatuses',
      selectedThread: 'singleContact/getSelectedThread',
      reloadFlag: 'singleContact/getReloadThread',
      currentUser: 'auth/getCurrentUser',
    }),

    getNegative() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_NEGATIVE')
    },
    getPositive() {
      return this.reasons.map((a, index) => ({ ...a, index })).filter(t => t.status === 'CLOSED_POSITIVE')
    },
  },
  watch: {
    reloadFlag: {
      deep: true,
      handler(newValue) {
        if (newValue.flag && newValue.threadId) {
          this.reloadSingleContact()
          this.selectSingleThread(newValue.threadId)
        }
      },
    },
    'singleContact.contactThreadAssignedContacts': {
      deep: true,
      handler(newValue) {
        if (newValue?.length) {
          this.contactThreads.push(
            ...newValue
              .filter(({ contactThread }) => this.contactThreads
                .find(item => item.id !== contactThread.id) || !this.contactThreads.length)
              .map(({ contactThread }) => ({ ...contactThread, assigned: true })),
          )
        }
      },
    },
    '$route.params.tab': {
      deep: true,
      handler(newValue) {
        this.activeTab = newValue
      },
    },
    '$route.params.contactId': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) this.init()
      },
    },
  },
  setup() {
    const { isMobile, resizeHandler } = useVerticalLayout()

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      perfectScrollbarSettings,
      isMobile,
    }
  },
  async mounted() {
    this.init()
  },
  methods: {
    async setSelectedThreadAsPrimary(event) {
      // const accepted = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      // if (!accepted) {
      //   await this.$nextTick()
      //   this.selectedThread.isPrimary = !event
      //   return
      // }

      try {
        const { contactId } = this.$route.params
        await axiosIns.patch(`1/contacts/${contactId}/threads`, { isPrimary: event, id: this.selectedThread.id })

        this.selectedThread.isPrimary = event

        this.contactThreads.forEach((thread, index) => {
          if (thread.id === this.selectedThread.id) {
            this.contactThreads[index].isPrimary = event
          } else {
            this.contactThreads[index].isPrimary = false
          }
        })

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    copy(txt) {
      this.$copyText(txt, this.$refs.contact_container).then(() => {
        this.showToast('primary', this.$i18n.t('CopiedToClipboard'))
      }, e => {
        this.showToast('danger', e)
      })
      // this.showToast('success', this.$i18n.t('CopiedToClipboard'))

      // navigator.clipboard.writeText(txt)
    },
    setStatus(type) {
      this.closeNote.status = type
      this.showCloseNote = true
    },
    async changeStatus(typeList, type) {
      const accepted = await this.showAlert('warning', this.$t(`funnel.status.${type}`), this.$t('funnel.setStatusWarning'))
      if (!accepted) return

      const funnelPoint = typeList[Number(type !== this.statusList.COMPLETED)]
      this.switchFunnelPoint(funnelPoint.id)
    },
    async onGetReasons() {
      try {
        const params = { fields_load: this.$fields.CONTACT_THREAD_CLOSE_REASONS, orderBy: 'position.asc' }
        const resp = await axiosIns.get('1/settings/contactThreadStatusReasons', { params })

        if (resp?.data?.data?.items) this.reasons = resp.data?.data?.items || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    existedFunnelProcess(processId) {
      return this.selectedThread.contactThreadFunnelProcesses.find(p => p.contactThreadFunnelType.id === processId)
    },
    init() {
      this.onGetContactTags()
      this.onGetFunnels()
      this.onGetReasons()

      const { threadId, tab, contactId } = this.$route.params
      this.$nextTick(() => {
        this.reloadSingleContact()

        if (
          this.checkRequiredPermissions([
            this.$roles.CONTACT_THREAD_VIEW_ALL,
            this.$roles.CONTACT_THREAD_VIEW_STRUCTURE,
            this.$roles.CONTACT_THREAD_VIEW_ASSIGNED,
          ])
        ) {
          this.$store
            .dispatch(`singleContact/${GET_CONTACT_THREADS}`, contactId)
            .then(res => {
              this.setClientChats(res)
              if (res.length === 1) {
                this.selectSingleThread(res[0].id)
              }
            })
          if (tab) this.activeTab = tab

          if (threadId) {
            this.selectSingleThread(threadId)
          } else if (this.selectedThread && this.selectedThread.id) {
            this.activeTab = 'timeline'
            this.selectSingleThread(threadId)
            // this.$router.push(`/contact/${contactId}/thread/${this.selectedThread.id}/timeline`)
          }
        }
      })
    },
    setClientChats(threads = []) {
      threads.forEach(thread => {
        if (thread.clientChats) {
          thread.clientChats.forEach(({ source }) => {
            if (!this.allowedChats.find(e => e.key === source)) {
              if (source === 'WHATSAPP' && this.checkRequiredPermissions([this.$roles.CLIENT_CHAT_WHATSAPP_VIEW_ALL, this.$roles.CLIENT_CHAT_WHATSAPP_VIEW_ASSIGNED_CONTACT_THREAD])) {
                this.allowedChats.push({ label: 'WhatsApp', key: 'WHATSAPP' })
              } else if (source === 'MOBILE_APP' && this.checkRequiredPermissions([this.$roles.CLIENT_CHAT_MOBILE_VIEW_ALL, this.$roles.CLIENT_CHAT_MOBILE_VIEW_ASSIGNED_CONTACT_THREAD])) {
                this.allowedChats.push({ label: 'MobileApp', key: 'MOBILE_APP' })
              } else if (source === 'MESSENGER' && this.checkRequiredPermissions([this.$roles.CLIENT_CHAT_MESSENGER_VIEW_ALL, this.$roles.CLIENT_CHAT_MESSENGER_VIEW_ASSIGNED_CONTACT_THREAD])) {
                this.allowedChats.push({ label: 'Messenger', key: 'MESSENGER' })
              }
            }
          })
        }
      })
    },
    reloadContactThread() {

    },
    openThread() {
      this.changeCloseStatus(true)
    },
    async createdFunnelProcess() {
      const threadId = this.selectedThread?.id
      try {
        const existed = this.selectedThread.contactThreadFunnelProcesses.find(fp => fp.contactThreadFunnelType.id === this.newProcessFunnel.id)
        if (existed) {
          const payload = {
            id: existed.id,
            statusNote: null,
            contactThreadStatusReason: null,
            status: 'OPEN',
          }

          await axiosIns.patch(`1/contacts/threads/${threadId}/funnelProcesses`, payload)
        } else {
          const payload = {
            contactThreadFunnelTypePoint: this.newProcessFunnel.contactThreadFunnelTypePoints[0].id.toString(),
            status: 'OPEN',
          }

          await axiosIns.post(`1/contacts/threads/${threadId}/funnelProcesses`, payload)
        }

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
        this.selectSingleThread(threadId)
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.newProcessFunnel = ''
        this.showOpenProcess = false
      }
    },
    loadThreadItem() {
      const threadId = this.selectedThread?.id
      this.$store.dispatch(`singleContact/${GET_CONTACT_SINGLE_THREAD}`, { id: threadId, skip: true })
    },
    async changeCloseStatus(create = false, open = false, targetId = null) {
      const status = this.closeNote.status !== 'REJECTED' ? 'CLOSED_POSITIVE' : 'CLOSED_NEGATIVE'
      const threadId = this.selectedThread?.id
      const openedId = targetId || this.selectedThread?.openedId

      try {
        let payload = {
          status,
          statusNote: this.closeNote.note,
          contactThreadStatusReason: this.getObjectId(this.closeNote.reason),
          id: openedId,
        }

        if (create) {
          this.showOpenProcess = true
          return
        }

        if (open) {
          payload = {
            status: 'OPEN',
            statusNote: null,
            contactThreadStatusReason: null,
            id: openedId,
          }
        }

        await axiosIns.patch(`1/contacts/threads/${threadId}/funnelProcesses`, payload)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))

        const reason = this.reasons.find(r => r.id === payload.contactThreadStatusReason)

        this.selectedThread.status = payload.status
        this.selectedThread.statusNote = payload.statusNote
        this.selectedThread.contactThreadStatusReason = open ? null : reason
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.selectSingleThread(threadId)
        this.showCloseNote = false
        this.closeNote = {
          status: 'CLOSED_POSITIVE',
          reason: '',
          note: '',
        }
      }
    },
    reloadSingleContact() {
      const { contactId } = this.$route.params

      this.loading = true
      this.$store
        .dispatch(`singleContact/${GET_SINGLE_CONTACT}`, contactId)
        .then(() => {
          this.loading = false
          const contactName = `${this.singleContact.firstName} ${this.singleContact.lastName}`
          document.title = `${contactName} | ${this.$store.getters['system/getSettings'].name}`
        })
        .catch(err => {
          this.loading = false
          this.showToast(
            'danger',
            this.$i18n.t(
              `errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`,
            ),
            err,
          )
        })
    },
    tooltipContent(title, date) {
      return `
        <span>${title}</span>
        <small>${this.$i18n.t('From')}: ${date.date.split('.')[0]}</small>
      `
    },
    deleteThread() {
      this.showAlert(
        'error',
        this.$i18n.t('alerts.AreYouSure'),
        this.$i18n.t('alerts.WontbeAble'),
      ).then(() => {
        const threadId = this.selectedThread.id.toString()
        const { contactId, tab } = this.$route.params
        this.$store
          .dispatch(`singleContact/${DELETE_THREAD}`, { threadId, contactId })
          .then(() => {
            this.showToast(
              'success',
              this.$i18n.t('alerts.SuccessfullyDeletedThread'),
              this.$i18n.t('Removed'),
            )
            this.$store.dispatch(
              `singleContact/${GET_CONTACT_SINGLE_THREAD}`,
              null,
            )
            // this.reloadSingleContact()
            const threadIndex = this.contactThreads.findIndex(
              thread => thread.id === threadId,
            )
            if (threadIndex > -1) this.$delete(this.contactThreads, threadIndex)
            this.$set(this, 'selectedThread', null)
            if (tab) this.$router.push(`/contact/${contactId}`)
          })
        // eslint-disable-next-line no-unused-vars
          .catch(err => {
            this.showToast('danger', this.$i18n.t('ProblemOccured'))
          })
      })
    },
    async onGetFunnels() {
      try {
        this.funnels = await this.$store.dispatch(`funnelMain/${GET_FUNNELS}`) || []
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async onGetContactTags() {
      await this.$store.dispatch(`typeSettings/${GET_THREAD_TAGS}`)
    },
    onGetChecklists() {
      axiosIns
        .get('1/settings/contactThreadChecklistTypes', {
          params: { fields_load: this.$fields.SETTINGS_CHECKLIST_TYPES, orderBy: 'position.asc' },
        })
        .then(res => {
          this.checklists = res.data.data.items
        })
    },
    onGetFunnelPoints(point = null) {
      this.$nextTick(() => {
        if ((this.selectedThread && this.selectedThread.contactThreadFunnelTypePoint) || point) {
          const funnelId = this.selectedThread?.contactThreadFunnelTypePoint?.contactThreadFunnelType?.id || point
          this.$store.dispatch(`funnelMain/${GET_FUNNEL_POINTS}`, { funnelId })
            .then(res => {
              this.$set(this, 'funnelPoints', res)
            })
        }
      })
    },
    removeContact(contactId) {
      this.showAlert(
        'error',
        this.$i18n.t('alerts.AreYouSure'),
        this.$i18n.t('alerts.WontbeAble'),
      ).then(() => {
        this.$store
          .dispatch(`contacts/${REMOVE_CONTACTS}`, [contactId])
          .then(() => {
            this.$router.push('/contacts')
          })
      })
    },
    onSelectThread(thread) {
      if (thread?.id) {
        const { id } = thread
        if (thread.assigned) {
          this.showAlert('warning', `${this.$i18n.t('Warning')}!`, this.$i18n.t('ThisContactIsAssignedWithOtherThread'))
            .then(() => {
              this.$router.push(`/contact/${thread.contact.id}/thread/${id}`)
            })
        } else this.selectSingleThread(id)
      } else this.selectSingleThread(null)
    },
    selectSingleThread(threadId, point = null) {
      this.$nextTick(() => {
        const { contactId, tab, itemId } = this.$route.params
        const currentPath = this.$route.path
        let routePath = ''

        if (!threadId) routePath = `/contact/${contactId}`
        else if (threadId && !tab) routePath = `/contact/${contactId}/thread/${threadId}/timeline`
        else if (threadId && tab) routePath = `/contact/${contactId}/thread/${threadId}/${tab}`

        if (routePath !== currentPath) {
          this.$router.push(routePath)
        }

        this.threadLoading = true
        this.$store.dispatch(`singleContact/${GET_CONTACT_SINGLE_THREAD}`, threadId)
          .then(() => {
            this.onGetFunnelPoints()
            this.threadLoading = false
            this.reloadCharts()
            this.$forceUpdate()
            if (itemId) {
              this.$store
                .dispatch(`singleContact/${GET_CONTACT_SINGLE_TASK}`, {
                  itemId,
                  threadId,
                })
                .then(res => {
                  this.openDetailModal(res.data.item)
                })
                .finally(() => {
                  this.$nextTick(() => {
                    this.onGetFunnelPoints(point)
                  })
                })
            }
          })
          .catch(() => {
            this.threadLoading = false
          })
      })
    },
    changeCheckList(newChecklist) {
      this.selectedThread.contactThreadChecklistType.id = newChecklist.id
      this.selectedThread.contactThreadChecklistType.name = newChecklist.name
    },
    // eslint-disable-next-line no-unused-vars
    reloadCharts(_progress = null) {
      if (this.selectedThread?.contactThreadChecklistDetails) {
        let numberOfChecklist = 0
        let numberOfDoneChecklist = 0

        this.selectedThread.contactThreadChecklistDetails.forEach(detail => {
          numberOfChecklist += detail.contactThreadChecklistTypePointsCount
          numberOfDoneChecklist += detail.contactThreadChecklistPointDataProgress
        })

        if (numberOfDoneChecklist > numberOfChecklist) console.error(`[SW -> Checklist Item] The number of completed Checklist Items cannot exceed the number of all Checklist Items. Done ${numberOfDoneChecklist} out of ${numberOfChecklist}`)

        this.checklistDetails = {
          all: numberOfChecklist,
          done: numberOfDoneChecklist,
        }
        this.progressDetails = this.selectedThread.contactThreadFunnelTypePoint.progressPercent

        this.$refs.checklistApex.updateSeries([numberOfDoneChecklist, numberOfChecklist - numberOfDoneChecklist])
        this.$refs.statusApex.updateSeries([
          this.selectedThread.contactThreadFunnelTypePoint.progressPercent,
        ])
      }
    },
    // Change funnel from popover
    async changeFunnel(newFunnelId) {
      const threadId = this.selectedThread.id.toString()

      const isCurrentOpen = this.selectedThread.contactThreadFunnelProcesses.find(t => t.status === 'OPEN' && t.contactThreadFunnelType.id === newFunnelId)
      const isTargetExist = this.selectedThread.contactThreadFunnelProcesses.find(t => t.contactThreadFunnelType.id === newFunnelId)
      // contunue when (current is closed and target exists)
      // if ( && !isTargetExist) {
      //   this.showAlert('warning', this.$i18n.t('AreYouSureToCloseStatus'), this.$i18n.t('OpenFunnelProcessButtonTooltipAndPauseCurrent')).then(async () => {
      //     await this.changeCloseStatus(false, true, isTargetExist.id)
      //   })
      //   return
      // }

      if ((!isCurrentOpen && isTargetExist) || isCurrentOpen) {
        this.showAlert('warning', this.$i18n.t('AreYouSureToCloseStatus'), this.$i18n.t(
            isTargetExist?.status === 'PAUSED' ? 'FromOpenToPaused' : 'ContinueFunnelProcessButtonTooltip',
        )).then(async () => {
          await this.changeCloseStatus(false, true, isTargetExist.id)
        })
        return
      }
      // create when (current is closed and target NOT exists)
      if ((!isCurrentOpen && !isTargetExist) || ['OPEN', 'PAUSED'].includes(this.selectedThread.contactThreadFunnelProcessLast?.status)) {
        this.showAlert('warning', this.$i18n.t('AreYouSureToCloseStatus'), this.$i18n.t(
          ['OPEN', 'PAUSED'].includes(this.selectedThread.contactThreadFunnelProcessLast?.status) ? 'OpenFunnelProcessButtonTooltipAndPauseCurrent'
            : 'OpenFunnelProcessButtonTooltip',
        )).then(async () => {
          try {
            const payload = {
              contactThreadFunnelTypePoint: this.funnels.find(a => a.id === newFunnelId).contactThreadFunnelTypePoints[0].id.toString(),
              status: 'OPEN',
            }

            await axiosIns.post(`1/contacts/threads/${threadId}/funnelProcesses`, payload)
            this.showToast('success', this.$i18n.t('success.contactUpdated'))
          } catch (err) {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          } finally {
            this.selectSingleThread(threadId)
          }
        })
      }

      // this.showAlert('warning', this.$i18n.t('alerts.AreYouSureChangeStatus'), '').then(() => {
      //   this.$store
      //     .dispatch(`funnelMain/${GET_FUNNEL_POINTS}`, {
      //       funnelId: newFunnelId,
      //     })
      //     .then(res => {
      //       const { contactId } = this.$route.params
      //
      //       const payload = {
      //         contactId: contactId.toString(),
      //         forceOld: true,
      //         data: {
      //           id: this.selectedThread.id.toString(),
      //           openedId: this.selectedThread.openedId.toString(),
      //           contactThreadFunnelTypePoint: res[0].id.toString(),
      //         },
      //       }
      //
      //       this.$store
      //         .dispatch(`singleContact/${UPDATE_CONTACT_THREAD}`, payload)
      //         .then(() => {
      //           this.showToast(
      //             'success',
      //             this.$i18n.t('FunnelUpdated'),
      //             this.$i18n.t('alert.Success'),
      //           )
      //           this.selectSingleThread(payload.data.id, res[0].id.toString())
      //         })
      //         .catch(() => {
      //           this.showToast('danger', this.$i18n.t('ProblemOccured'))
      //         })
      //     })
      //     .catch(() => {
      //       this.showToast('danger', this.$i18n.t('ProblemOccured'))
      //     })
      // })
    },
    // Change funnel point from popover
    changeFunnelPoint(newFunnelPointId) {
      this.showAlert(
        'warning',
        this.$i18n.t('alerts.AreYouSureChangeStatus'),
        '',
      ).then(() => this.switchFunnelPoint(newFunnelPointId))
    },
    switchFunnelPoint(newFunnelPointId) {
      const { contactId } = this.$route.params

      const payload = {
        contactId: contactId.toString(),
        forceOld: true,
        data: {
          id: this.selectedThread.id.toString(),
          openedId: this.selectedThread.openedId.toString(),
          contactThreadFunnelTypePoint: newFunnelPointId.toString(),
        },
      }

      this.$store
        .dispatch(`singleContact/${UPDATE_CONTACT_THREAD}`, payload)
        .then(() => {
          this.showToast(
            'success',
            this.$i18n.t('StatusUpdated'),
            this.$i18n.t('alert.Success'),
          )
          this.selectSingleThread(payload.data.id)
        })
        .catch(() => {
          this.showToast('danger', this.$i18n.t('ProblemOccured'))
        })
    },
    onShow() {
      this.onGetFunnels()
      this.onGetChecklists()
      this.newThreadName = ''
    },
    editThread(isAssignedContactsEdit = false) {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'thread',
        modalTitle: this.$i18n.t('EditThread'),
        okButtonTitle: this.$i18n.t('Save'),
        editedData: this.selectedThread,
        modalClass: 'thread-modal',
        isAssignedContactsEdit,
      })
    },
    openModal() {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'thread',
        modalTitle: this.$i18n.t('AddThread'),
        okButtonTitle: this.$i18n.t('Save'),
        modalClass: 'thread-modal',
      })
    },
    openDetailModal(item) {
      this.$store.commit(`modal/${OPEN_MODAL}`, {
        modalType: 'viewModal',
        modalTitle: this.$i18n.t('Preview'),
        okButtonTitle: '',
        data: item,
      })
    },
    changeRouter(tab) {
      const { contactId, threadId } = this.$route.params
      this.$router.push(`/contact/${contactId}/thread/${threadId}/${tab}`)
    },
    changeTaskStatus(taskId, statusValue, taskIndex, item) {
      const data = {
        id: taskId.toString(),
        status: statusValue,
      }
      this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
        .then(() => {
          if (item.contactThreadTaskType.isReportRequired) {
            this.$store.commit(`modal/${OPEN_MODAL}`,
              {
                modalType: 'report',
                modalTitle: this.$i18n.t('NewReport'),
                okButtonTitle: this.$i18n.t('AddReport'),
                threadFor: this.target.id,
                data: { ...item, statusValue, taskIndex },
              })
          } else {
            this.$store.dispatch(`singleContact/${UPDATE_CONTACT_TASK}`, { updateTaskData: data, threadId: this.target.id })
              .then(() => {
                this.target.contactThreadTasksOpened.splice(taskIndex, 1)
                this.showToast('success', this.$i18n.t('StatusUpdated'))
              })
              .catch(err => {
                this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
.avatar-badge {
  position: absolute;
  top: 0;
  right: -2px;
  height: 13px;
  width: 13px;
  border-radius: 50%;
}
.pb-0 {
  .card-header {
    padding-bottom: 0 !important;
  }
}

.thread-modal {
  .modal-dialog {
    max-width: 700px !important;
  }
}

.btn-group-row-gap-0\.34 {
  row-gap: .34rem;
}

.status-bar {
  display: flex;
}

.status-bar .feather {
  font-size: 4rem;

  color: rgba(0, 0, 0, .3);
}

.status-bar > div:last-of-type > .feather { display: none }

.status-bar .btn-status-success {
  background-color: #008b00;

  color: white;
}
.status-bar .btn-status-secondary { background-color: rgba(0, 0, 0, .1) }

#container-task, #container-no-select-task {
  .popover {
    min-width: 280px;
    max-width: 350px !important;
    .popover-body {
      padding: 0.65rem 0 !important;
    }
  }
}
</style>
