<template>
  <div>
    <div v-if="checkRequiredPermissions([$roles.FULL_ACCESS]) || checkRequiredViewPermissions([$viewRanks.SHOW_ORDERS_EXPORT])">
      <b-button
        variant="primary"
        size="sm"
        class="mb-50 mr-50"
        :disabled="exportLoading"
        @click="getRecordsToExport"
      >
        <span v-if="!exportLoading"><feather-icon icon="DownloadIcon" />Export</span>
        <span v-else>
          <b-spinner
            small
            variant="light"
          />
        </span>
      </b-button>
      <b-button
        v-if="checkRequiredViewPermissions([$viewRanks.SHOW_ORDER_EDIT])"
        variant="primary"
        size="sm"
        class="mb-50 mr-50"
        :disabled="massUpdateLoading || !selectedItems.length"
        @click="showMassUpdate = true"
      >
        <feather-icon icon="DatabaseIcon" />
        {{ $t('RecordsMassUpdate') }}

        <span v-if="selectedItems.length">({{ selectedItems.length }})</span>
      </b-button>
    </div>
    <component :is="threadId ? 'div' : 'b-card'">
      <div>
        <b-modal
          v-model="showMassUpdate"
          :title="$tc('RecordsCountMassUpdate', selectedItems.length, { length: selectedItems.length })"
          hide-footer
          no-close-on-backdrop
          @hide="showMassUpdate = false; newStatus = ''"
        >

          <b-row>
            <b-col sm="12">
              <transition name="fade">
                <sw-select :name="$t('StatusOfOrder')">
                  <v-select
                    v-model="newStatus"
                    :options="[
                      'NEW',
                      'REALIZED',
                      'CANCELLED',
                      'IN_REALIZATION',
                    ]"
                  >
                    <template #no-options>
                      {{ $t('NoOptions') }}
                    </template>

                    <template #option="option">
                      {{ $tc(`offer.status.${option.label}`, 2) }}
                    </template>
                    <template #selected-option="option">
                      {{ $tc(`offer.status.${option.label}`, 2) }}
                    </template>
                  </v-select>
                </sw-select>
              </transition>
            </b-col>

            <b-col>
              <b-button
                variant="primary"
                size="sm"
                :disabled="!newStatus || massUpdateLoading"
                @click="updateSelectedOrders"
              >
                {{ $t('Save') }}
              </b-button>
            </b-col>
          </b-row>
        </b-modal>
        <!-- eslint-disable -->
                <sw-table
                        table-id="e91ca61e0bce41ec8425b72c92220546"
                        show-columns
                        show-search
                        :fields.async="orderFields"
                        :pagination="pagination"
                        @change-pagination="Object.assign(pagination, $event)"
                        @change-search-query="searchQuery = $event"
                        @reload-content="loadOrders"
                        @set-fields="orderFields = $event"
                >
                    <template #table>
                        <b-table
                                ref="selectableTable"
                                :items="orders"
                                class="table-wrap-words mx-0 px-0"
                                :fields="fields"
                                striped
                                responsive
                                :busy="loading"
                                show-empty
                                :sort-by.sync="sorter.sortBy"
                                :sort-desc.sync="sorter.sortDesc"
                                :no-local-sorting="true"
                                @sort-changed="Object.assign(sorter, $event); loadOrders()"
                        >
                            <template #head()="{ label }">
                                {{ $t(`${label}`) }}
                            </template>
                            <template #cell(id)="{ index }">
                                #{{ getOrderNo(index, pagination, sorter) }}
                            </template>

                            <template #head(select)="{ label }">
                                <b-form-checkbox v-model="selectAll"
                                                 @change="selectAllItems"
                                />
                            </template>
                            <template #cell(select)="{ item }">
                                <b-form-checkbox v-model="selectedItems" :value="item.id" />
                            </template>

                            <template #cell(orderDate)="{ value }">
                                <span v-if="value && value.date">{{ formatDate(value.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}</span>
                                <feather-icon
                                        v-else
                                        icon="MinusIcon"
                                />
                            </template>
                            <template #cell(assignedUser)="{ value }">
                                <avatar :user="value" />
                            </template>

                            <template #cell(contact)="{ value }">
                                <avatar :user="value" />
                            </template>

                            <template #cell(createdBy)="{ value }">
                                <avatar :user="value" />
                            </template>

                            <template #cell(whoGetsCommissions)="{ value }">
                                <avatar :user="value" />
                            </template>

                            <template #cell(products)="{ item, index }">
                                <b-button
                                        :id="`popover-target_${index}`"
                                        href="#"
                                        tabindex="0"
                                        size="sm"
                                        variant="outline-primary"
                                        style="min-width: 125px"
                                        @click="orderSpec = item"
                                >
                                    {{ $t('Offer.Products') + ': ' + (item.contactThreadCartBox.contactThreadCartBoxProducts || []).length }}
                                </b-button>
                            </template>

                            <template #cell(nettoPrice)="{ value }">
            <span class="text-nowrap">
              {{ value | priceFormat }}
              PLN
            </span>
                            </template>
                            <template #cell(totalSellerDiscount)="{ value }">
            <span class="text-nowrap">
              {{ value | priceFormat }}
              PLN
            </span>
                            </template>

                            <template #cell(priceGrossEnd)="{ item, value }">
            <span
                    v-if="item.contactThreadCartBox && item.contactThreadCartBox.paymentType && item.contactThreadCartBox.paymentType === 'loyalty_points'.toUpperCase()"
                    class="text-nowrap"
            >
              <b-badge variant="light-success">
                {{ item.loyaltyPointsPrice || 0 }}
                {{ $t('Pt') }}
              </b-badge>
            </span>
                                <span
                                        v-else
                                        class="text-nowrap"
                                >
              {{ value || 0 | priceGrossFormat }}
              PLN
            </span>
                            </template>

                            <template #cell(statusOfOrder)="{ value }">
                                <b-badge
                                        variant="light-primary"
                                        class="text-nowrap"
                                >
                                    {{ $t(value) }}
                                </b-badge>
                            </template>

                            <template #cell(paymentStatus)="{ value }">
                                <b-badge
                                        variant="light-primary"
                                        class="text-nowrap"
                                >
                                    {{ $t(value) }}
                                </b-badge>
                            </template>

                            <template #cell(paymentType)="{ item }">
                                <b-badge
                                        v-if="item.contactThreadCartBox && item.contactThreadCartBox.paymentType"
                                        :variant="paymentTypeColors[item.contactThreadCartBox.paymentType]"
                                        class="text-nowrap"
                                >
                                    {{ $t(item.contactThreadCartBox.paymentType.toLowerCase()) }}
                                </b-badge>
                                <feather-icon
                                        v-else
                                        icon="MinusIcon"
                                />
                            </template>

                            <template #cell(status)="{ value }">
                                <!--  && isSomeAgreementAccepted(item.contactThreadAgreements)-->
                                <b-badge
                                        variant="light-primary"
                                >
                                    {{ $tc(`offer.status.${value}`, 2) }}
                                </b-badge>
                            </template>

                            <template #cell(createContract)="{ value }">
                                <feather-icon
                                        :icon="value ? 'FileIcon' : 'MinusIcon'"
                                        size="20"
                                />
                            </template>

                            <!--    Creator    -->
                            <template #cell(createdBy)="{ value }">
                                <avatar
                                        :user="value"
                                        :to="{ name: 'user', params: { userId: value.id } }"
                                />
                            </template>

                            <template #cell(seller)="{ value }">
                                <avatar
                                        :user="value"
                                        :to="{ name: 'user', params: { userId: value.id } }"
                                />
                            </template>

                            <!-- Contact -->
                            <template #cell(contact)="{ item }">
            <span v-if="item.contactThreadCartBox.contactThread">
              <router-link :class="{ 'deleted-text': item.contactThreadCartBox.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThreadCartBox.contactThread.contact.id}`">
                {{ item.contactThreadCartBox.contactThread.contact.firstName }} {{ item.contactThreadCartBox.contactThread.contact.lastName }}
              </router-link>
            </span>
                                <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
                            </template>

                            <!-- Thread -->
                            <template #cell(thread)="{ item }">
            <span v-if="item.contactThreadCartBox.contactThread">
              <router-link :class="{ 'deleted-text': item.contactThreadCartBox.contactThread.contact.deletedAt }" :to="`/contact/${item.contactThreadCartBox.contactThread.contact.id}/thread/${item.contactThreadCartBox.contactThread.id}/offers`">
                {{ item.contactThreadCartBox.contactThread.name | truncate(20) }}
              </router-link>
            </span>
                                <span v-else>
              <feather-icon icon="MinusIcon" />
            </span>
                            </template>

                            <!--     Action     -->
                            <template #cell(action)="{ item, index }">
                                <b-dropdown
                                        class="dropdown-icon-wrapper text-decoration-none"
                                        dropleft
                                        no-caret
                                        variant="flat"
                                >
                                    <template #button-content>
                                        <feather-icon icon="MoreVerticalIcon" />
                                    </template>
                                    <!--    Action: Specification    -->
                                    <span v-if="checkRequiredViewPermissions([$viewRanks.SHOW_ORDER_EDIT]) && item.status !== 'CANCELLED' && item.status !== 'ACCEPTED'">
                    <b-dropdown-item
                            @click="changeStatus(item, 'IN_REALIZATION', index)"
                    >
                        <dropdown-item-label icon="CircleIcon" :label="$tc(`offer.status.IN_REALIZATION`, 2)"/>
                    </b-dropdown-item>
                    <b-dropdown-item
                            @click="changeStatus(item, 'REALIZED', index)"
                    >
                        <dropdown-item-label icon="CheckIcon" :label="$tc(`offer.status.REALIZED`, 2)"/>
                    </b-dropdown-item>
                    <b-dropdown-item
                            @click="changeStatus(item, 'CANCELLED', index)"
                    >
                        <dropdown-item-label icon="XIcon" :label="$tc('offer.status.CANCELLED', 2)"/>
                    </b-dropdown-item>
                </span>


                                    <!--    Action: Add Contract    -->
                                    <span v-if="checkRequiredModule('agreementModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_ADD]) && checkRequiredViewPermissions([$viewRanks.SHOW_ORDER_EDIT])">
                  <b-dropdown-divider v-if="checkRequiredViewPermissions([$viewRanks.SHOW_ORDER_EDIT]) && item.status !== 'CANCELLED' && item.status !== 'ACCEPTED'" />
                  <b-dropdown-item @click="openAgreementModal(item)">
                      <dropdown-item-label icon="FilePlusIcon" :label="$t('Contracts.AddContract')" />
                  </b-dropdown-item>
              </span>

<!--                                      Action: Duplicate    -->
                                  <b-dropdown-item
                                      v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_ORDER_ADD])"
                                      @click="duplicateOrderItem = item"
                                  >
                                    <dropdown-item-label icon="CopyIcon" :label="$t('Duplicate')" />
                                  </b-dropdown-item>

                                    <b-dropdown-divider v-if="checkRequiredViewPermissions([$viewRanks.SHOW_ORDER_EDIT]) || checkRequiredModule('agreementModule') && checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_ADD]) && checkRequiredViewPermissions([$viewRanks.SHOW_ORDER_EDIT])" />

                                    <b-dropdown-item @click="orderSpec = item">
                                        <dropdown-item-label icon="EyeIcon" :label="$t('Specification')" />
                                    </b-dropdown-item>

                                    <!--    Action: Add Contract    -->
                                    <b-dropdown-item
                                            v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_ORDER_DELETE])"
                                            @click="deleteOrder(item, index)"
                                    >
                                        <dropdown-item-label icon="TrashIcon" :label="$t('Delete')"/>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </template>
                            <template #cell(agreements)="row">
                                <!--        <documents-list :documents="item.contactThreadAgreements.map(item => item.filePdf)" />-->
                                <b-button
                                        size="sm"
                                        variant="primary"
                                        class="d-flex align-items-center px-50 py-25"
                                        @click="row.toggleDetails"
                                >
                                    <b-badge
                                            variant="light"
                                            class="text-primary"
                                    >
                                        {{ (row.item.contactThreadCartBox.contactThreadAgreements || []).length }}
                                    </b-badge>
                                    <feather-icon
                                            class="ml-50"
                                            :icon="row.detailsShowing ? 'MinusIcon' : 'PlusIcon'"
                                    />
                                </b-button>
                            </template>
                            <template #row-details="{ item }">
                                <div v-if="item.contactThreadCartBox.contactThreadAgreements">
                                    <b-card
                                            v-for="(agreementItem, index) in item.contactThreadCartBox.contactThreadAgreements"
                                            :key="`agreement_${item.id}_item_${index}`"
                                            class="py-0"
                                    >
                                        <div class="d-flex align-items-center">
                                            <div
                                                    style="min-width: 160px"
                                                    class="text-truncate"
                                            >
                                                <div class="font-weight-bolder">
                                                    {{ $t('Status') }}
                                                </div>
                                                <div>
                                                    <b-badge variant="light-primary">
                                                        {{ $t(`AgreementStatuses.${agreementItem.status}`) }}
                                                    </b-badge>
                                                </div>
                                            </div>
                                            <div
                                                    v-if="agreementItem.signedAt"
                                                    style="min-width: 160px"
                                                    class="text-truncate"
                                            >
                                                <div class="font-weight-bolder">
                                                    {{ $t('Contracts.DateOfSigningContract') }}
                                                </div>
                                                <div>
                                                    <b-badge variant="light-primary">
                                                        {{ formatDate(agreementItem.signedAt.date, { month: 'numeric', day: 'numeric', year: 'numeric' }, $i18n.locale) }}
                                                    </b-badge>
                                                </div>
                                            </div>
                                            <div
                                                    v-if="(agreementItem.agreementTemplate && agreementItem.agreementTemplate.agreementType) || agreementItem.agreementType"
                                                    style="min-width: 200px; max-width: 250px;"
                                                    class="text-truncate"
                                            >
                                                <div class="font-weight-bolder">
                                                    {{ $t('Contracts.AgreementType') }}
                                                </div>
                                                <div v-if="agreementItem.agreementType">{{ agreementItem.agreementType.name }}</div>
                                                <div v-else>{{ agreementItem.agreementTemplate.agreementType.name }}</div>
                                            </div>
                                            <div
                                                    v-if="agreementItem.filesPdf && agreementItem.filesPdf.length"
                                                    style="min-width: 200px; max-width: 300px; margin-right: 20px"
                                                    class="text-truncate"
                                            >
                                                <div class="font-weight-bolder">
                                                    {{ $t('Contracts.FileTypes.Agreement') }}
                                                </div>
                                                <div
                                                        v-for="(filePdf, index) in agreementItem.filesPdf"
                                                        :key="`file_pdf_${index}_${item.id}`"
                                                        class="cursor-pointer mt-25"
                                                        @click="downloadPdf(filePdf.token, filePdf.name)"
                                                        v-b-tooltip
                                                        :title="filePdf.name"
                                                >
                                                    <feather-icon
                                                            icon="FileIcon"
                                                    />
                                                    {{ filePdf.name | truncate(40) }}
                                                </div>
                                            </div>
                                            <div
                                                    style="min-width: 200px"
                                                    class="text-truncate"
                                            >
                                                <div class="font-weight-bolder">
                                                    {{ $t('SignerUser') }}
                                                </div>
                                                <avatar
                                                        v-if="agreementItem.signerUser"
                                                        :user="agreementItem.signerUser"
                                                        :to="{ name: 'user', params: { userId: agreementItem.signerUser.id } }"
                                                />
                                                <feather-icon
                                                        v-else
                                                        icon="MinusIcon"
                                                />
                                            </div>
                                            <div
                                                    style="min-width: 205px"
                                                    class="text-truncate"
                                            >
                                                <div class="font-weight-bolder">
                                                    {{ $t('Signature') }}
                                                </div>
                                                <div>
                                                    <b-form-checkbox
                                                            disabled
                                                            variant="primary"
                                                            :checked="agreementItem.allowElectronicSignature"
                                                    >
                        <span v-if="agreementItem.electronicSignatureType">
                            {{ $t(`signatureTypes.${agreementItem.electronicSignatureType}`) }}
                        </span>
                                                    </b-form-checkbox>
                                                </div>
                                            </div>
                                            <div
                                                    v-if="agreementItem.filesSigned && agreementItem.filesSigned.length"
                                                    style="min-width: 150px"
                                                    class="text-truncate"
                                            >
                                                <div class="font-weight-bolder">
                                                    {{ $t('contact.agreement') }}
                                                </div>
                                                <div
                                                        v-for="(fileSigned, index) in agreementItem.filesSigned"
                                                        :key="`file_signed_${index}_${item.id}`"
                                                        class="cursor-pointer mt-25"
                                                        @click="downloadPdf(fileSigned.token, fileSigned.name)"
                                                >
                                                    <feather-icon
                                                            icon="FileIcon"
                                                    />
                                                    {{ fileSigned.name }}
                                                </div>
                                            </div>
                                            <div class="pr-2">
                                                <!--                              v-if="agreementItem.status === 'SIGNING'"-->
                                                <div
                                                        v-b-tooltip
                                                        :title="agreementItem.allowElectronicSignature && agreementItem.status === 'SIGNING' ? $t('AutentiSignWasInitialized') : ''"
                                                >
                                                    <b-button
                                                            v-if="checkRequiredPermissions([$roles.CONTACT_THREAD_AGREEMENT_EDIT])"
                                                            variant="success"
                                                            size="sm"
                                                            class="mr-25"
                                                            :disabled="agreementItem.status !== 'NEW'"
                                                            @click="signAgreement(agreementItem, item.id)"
                                                    >
                                                        <div v-if="agreementItem.status !== 'NEW'">
                                                            <feather-icon :icon="agreementItem.allowElectronicSignature && agreementItem.status === 'SIGNING' ? 'Edit2Icon' : 'CheckIcon'" />
                                                        </div>
                                                        <div v-else>
                                                            {{ agreementItem.allowElectronicSignature ? $t('SignedManually') : $t('Accept1') }}
                                                        </div>
                                                    </b-button>
                                                </div>
                                                <!--              <b-button-->
                                                <!--                v-if="agreementItem.status === 'SIGNING'"-->
                                                <!--                variant="warning"-->
                                                <!--                size="sm"-->
                                                <!--              >-->
                                                <!--                {{ $t('Remind') }}-->
                                                <!--              </b-button>-->
                                            </div>
                                        </div>
                                    </b-card>
                                </div>
                            </template>
                            <!-- Other -->
                            <!--    Other    -->
                            <template #cell()="row">
                                <table-default-cell
                                        :field="row.field"
                                        :value="row.value"
                                />
                            </template>

                            <!--      Empty      -->
                            <template #empty>
                                <empty-content />
                            </template>

                            <!--      Table Busy      -->
                            <template #table-busy>
                                <div class="text-center text-danger my-2">
                                    <b-spinner
                                            class="align-middle"
                                            variant="primary"
                                    />
                                </div>
                            </template>
                        </b-table>
                    </template>
                </sw-table>

                <b-modal
                        size="lg"
                        :visible="!!offerAgreement"
                        :title="$t('Contracts.AddContract')"
                        hide-footer
                        no-close-on-backdrop
                        @hide="offerAgreement = null"
                >
                    <b-tabs v-if="offerAgreement">
                        <b-tab :title="$t('SystemAgreement')">
                            <section-agreement-item
                                    :agreement="agreement"
                                    :autenti-enabled="autentiEnable"
                                    :is-cart="false"
                                    :agreement-types="agreementTypes.filter(e => e.agreementTemplates  && e.agreementTemplates.length)"
                                    :cart-item="offerAgreement"
                                    @close-modal="offerAgreement = null; agreement = {...emptyAgreement}; getSingleOffer($event.offerId)"
                            />
                        </b-tab>

                        <b-tab :title="$t('File')">
                            <b-form-group :label="$t('Contracts.AgreementType')">
                                <v-select
                                        v-model="agreement.agreementType"
                                        :options="agreementTypes"
                                        :reduce="item => item.id"
                                        label="name"
                                />
                            </b-form-group>
                            <sw-select :name="$t('Contracts.DateOfSigningContract')">
                                <sw-select-fpr>
                                    <flat-pickr
                                            ref="timeRange"
                                            v-model="agreement.signedAt"
                                            class="form-control"
                                            :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale) }"
                                    />
                                </sw-select-fpr>
                            </sw-select>

                            <drag-drop-upload
                                    @onChangeFiles="(e) => { $set(agreement, 'filesSigned', e) }"
                            />

                            <b-button
                                    size="sm"
                                    class="mt-50"
                                    variant="primary"
                                    :disabled="!agreement.signedAt || !agreement.filesSigned.length || agreementLoading"
                                    @click="updateAgreement({ ...agreement, id: offerAgreement.id, contactId: offerAgreement.contactId, threadId: offerAgreement.threadId }, offerAgreement.offerId, 'add')"
                            >
                                {{ $t('Save') }}
                            </b-button>
                        </b-tab>
                    </b-tabs>
                </b-modal>

                <b-modal
                        size="lg"
                        :visible="!!orderSpec"
                        :title="$t('Specification')"
                        button-size="sm"
                        ok-only
                        :ok-title="$t('Close')"
                        ok-variant="secondary"
                        @hide="orderSpec = null"
                >
                    <product-specification
                            v-if="orderSpec"
                            type="ORDER"
                            :offer="orderSpec"
                    />
                </b-modal>

                <b-modal
                        size="lg"
                        :visible="!!offerAgreementSignManually"
                        :title="$t('CreateContract')"
                        hide-footer
                        no-close-on-backdrop
                        @hide="offerAgreementSignManually = null"
                >
                    <div v-if="offerAgreementSignManually">
                        <sw-select :name="$t('Contracts.DateOfSigningContract')">
                            <sw-select-fpr>
                                <flat-pickr
                                        ref="timeRange"
                                        v-model="offerAgreementSignManually.signedAt"
                                        class="form-control"
                                        :config="{ dateFormat: 'd-m-Y', locale: getCalendarLocale($i18n.locale) }"
                                />
                            </sw-select-fpr>
                        </sw-select>

                        <drag-drop-upload
                                v-if="offerAgreementSignManually.status !== 'ACCEPTED'"
                                @onChangeFiles="(e) => { offerAgreementSignManually.filesSigned.push(...e) }"
                        />

                        <b-button
                                size="sm"
                                class="mt-50"
                                variant="primary"
                                :disabled="!offerAgreementSignManually.signedAt || agreementLoading"
                                @click="updateAgreement(offerAgreementSignManually, offerAgreementSignManually.orderId)"
                        >
                            {{ $t('Save') }}
                        </b-button>
                    </div>
                </b-modal>

          <b-modal
                  size="lg"
                  :visible="!!duplicateOrderItem"
                  :title="$t('Duplicate')"
                  hide-footer
                  no-close-on-backdrop
                  @hide="duplicateOrderItem = null"
          >
              <offer-to-order
                      v-if="duplicateOrderItem"
                      :offer="duplicateOrderItem"
                      duplicate-mode
                      @close-modal="duplicateOrderItem = null; loadOrders()"
              />
          </b-modal>
            </div>
        </component>
    </div>
</template>

<script>
import {
  BModal, BTab, BTable, BTabs, VBTooltip,
} from 'bootstrap-vue'
import {
  DOWNLOAD_FILE,
  GET_ORDERS,
  GET_PRIVATE_SETTINGS,
  GET_THREAD_ORDERS,
  UPLOAD_FILES,
} from '@/@constants/mutations'
import { formatDate } from '@core/utils/filter'
import ProductSpecification from '@/views/offers/product/ProductSpecification.vue'
import axiosIns from '@/libs/axios'
import SectionAgreementItem from '@/views/offers/checkout/SectionAgreementItem.vue'
import flatPickr from 'vue-flatpickr-component'
import DragDropUpload from '@/views/components/DragDropUpload.vue'
import vSelect from 'vue-select'
import { priceGrossFormat } from '@core/filters/parts/valueFormat'
import XLSX from 'xlsx'
import OfferToOrder from '@/pages/offer/OfferToOrder.vue'
/* eslint-disable */

const emptyAgreement = {
  agreementType: '',
  agreementTemplate: '',
  allowElectronicSignature: false,
  anonymised: false,
  signerUser: '',
  signedAt: '',
  filesSigned: [],
  contactThreadCartDraftAgreementAssignedContacts: [],
}

export default {
  components: {
    OfferToOrder, BModal,
    vSelect,
    BTabs,
    BTab,
    BTable,
    SectionAgreementItem,
    ProductSpecification,
    flatPickr,
    DragDropUpload,
  },
  emits: ['loadData'],
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    threadId: {
      type: String,
      default: null,
    },
  },
  data: vm => ({
    massUpdateLoading: false,
    showMassUpdate: false,
    newStatus: 'NEW',
    duplicateOrderItem: null,

    emptyAgreement,
    orders: [],
    orderSpec: null,
    loading: false,
    searchQuery: '',
    agreementTypes: [],

    selectAll: false,
    selectedItems: [],

    agreement: { ...emptyAgreement },
    offerAgreement: null,
    offerAgreementSignManually: null,
    agreementLoading: false,
    autentiEnable: false,
    paymentTypeColors: {
        ADVANCE: 'info',
        TOTAL_PAYMENT: 'light-info',
        BANK_INSTALLMENT: 'warning',
        INSTALLMENT: 'light-warning',
        TRANSFER: 'primary',
        CASH: 'light-primary',
        LOYALTY_POINTS: 'light-success',
    },
    orderFields: [
        {
            key: 'select', label: 'Select', visible: true
        },
        {
            key: 'id', label: '#', visible: true, sortable: true,
        },
      { key: 'number', label: 'OrderID', sortable: true, visible: true,requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_NUMBER], },
      { key: 'orderDate', label: 'OrderDate', visible: true,requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_ORDER_DATE], },
      { key: 'contact', label: 'Contact', visible: true,requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_CONTACT], },
      { key: 'thread', label: 'contact.contactThread', visible: true,requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_THREAD], },
      { key: 'products', label: 'Products', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_PRODUCTS], },
      { key: 'priceGrossEnd', label: 'offer.GrossPrice', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_PRICE_GROSS], },
      { key: 'totalSellerDiscount', label: 'UserDiscount', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_TOTAL_SELLER_DISCOUNT], },
      { key: 'status', label: 'StatusOfOrder', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_STATUS], },
      { key: 'paymentType', label: 'PaymentMethod', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_PAYMENT_TYPE], },
      { key: 'seller', label: 'Seller', requiredModule: 'loyaltyModule', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_SELLER], },
      { key: 'agreements', label: 'CreateContract', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_AGREEMENTS], },
      { key: 'createdBy', label: 'contact.creator', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_CREATED_BY], },
      { key: 'createdAt', label: 'CreationTime', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_CREATED_AT], },
      { key: 'action', label: 'Action', visible: true, requiredViewRanks: [vm.$viewRanks.SHOW_TABLE_ORDER_COLUMN_ACTION], },
    ],
      sorter: {
          sortBy: 'id',
          sortDesc: true,
      },
      exportLoading: false,
    pagination: {
      perPage: 25,
      currentPage: 1,
      totalRecords: 100,
    },
  }),
  computed: {
    visibleFields() {
      return this.orderFields.filter(field => !field?.requiredModule || this.checkRequiredModule(field?.requiredModule)).filter(e => !e?.requiredViewRanks || this.checkRequiredViewPermissions(e.requiredViewRanks))
    },
    fields() {
      return this.visibleFields.filter(field => field.visible)
    },
    isHydropure() {
        return window.location.origin.includes('hydro')
    },
  },
  watch: {
      selectedItems: {
          handler() {
              this.selectAll = this.selectedItems.length === this.orders.length
          },
          deep: true,
      },
    filters: {
      deep: true,
      handler() {
        this.loadOrders()
      },
    },
  },
  async mounted() {
    await Promise.all([
        this.loadAgreementTypes(),
        this.loadSettings(),
    ])

    // this.$nextTick(() => {
    //   if (!this.checkRequiredModule('agreementModule')) {
    //     const index = this.orderFields.findIndex(item => item.key === 'agreements')
    //     if (index !== -1) this.$delete(this.orderFields, index)
    //   }
    //
    //   if (!this.checkRequiredPermissions([this.$roles.CONTACT_THREAD_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_ALL, this.$roles.CONTACT_THREAD_ASSIGNED_AGREEMENT_VIEW_SELF])) {
    //     const index = this.orderFields.findIndex(item => item.key === 'agreements')
    //     if (index !== -1) this.$delete(this.orderFields, index)
    //   }
    // })

    this.$nextTick(() => {
        if (this.isHydropure) {
            this.sorter.sortBy = 'number'
        }

      if (this.threadId) {
        this.orderFields.splice(3, 2)
      }
      this.loadOrders()
    })
  },
  methods: {
    async duplicateOrder(order) {
      try {
        await axiosIns.post(`1/contacts/threads/carts/orders/${order.id}/doDuplicate`)
        await this.loadOrders()
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    selectAllItems() {
        this.selectedItems = this.selectAll ? this.orders.map(item => item.id) : []
    },
      async updateSelectedOrders() {
          this.massUpdateLoading = true

          const updatePayload = this.selectedItems.map(id => ({ id, status: this.newStatus }))

          const chunks = updatePayload.splitIntoChunks(25)

          try {
              await Promise.all(chunks.map(chunk => axiosIns.patch('1/contacts/threads/carts/orders', chunk)))

              this.selectedItems.forEach(id => {
                  const index = this.orders.findIndex(e => e.id === id)
                  if (index > -1) {
                      this.$set(this.orders[index], 'status', this.newStatus)
                  }
              })

              this.showToast('success', this.$i18n.t('success.contactUpdated'))
              this.newStatus = 'NEW'
              this.showMassUpdate = false
              this.selectedItems = []
          } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          } finally {
              this.massUpdateLoading = false
          }
      },
    priceGrossFormat,
    async loadSettings() {
      try {
        const resp = await this.$store.dispatch(`system/${GET_PRIVATE_SETTINGS}`)
        this.autentiEnable = resp?.data?.item?.autentiEnabled || false
        return resp.data?.item
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return []
      }
    },
    async changeStatus(item, status, index) {
      const confirmed = await this.showAlert('warning', this.$i18n.t('alerts.AreYouSure'))
      if (!confirmed) return

      try {
        await axiosIns.patch('1/contacts/threads/carts/orders', { id: item.id, status })
        this.$set(this.orders[index], 'status', status)

        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      }
    },
    async loadAgreementTypes() {
      if (!this.checkRequiredModule('agreementModule')) {
          return true
      }
      try {
        const resp = await axiosIns.get('1/settings/agreementTypes', { params: { fields_load: this.$fields.SETTINGS_AGREEMENTS_TYPES } })
        this.agreementTypes = resp.data.data?.items || []
        return resp.data.data?.items
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
        return []
      }
    },
    formatDate,
    getSingleOffer(id, index = -1) {
      if (id) {
        this.$store.dispatch('offer/GET_ORDER', id)
          .then(res => {
            const order = res.data.item

            order.priceGrossEnd = order.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount || 1), 0) || 0
            order.price = order.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount || 1), 0) || 0
            if (index !== -1) {
              this.$set(this.orders, index, order)
            } else {
              // eslint-disable-next-line no-underscore-dangle
              const _index = this.orders.findIndex(item => item.id === id)
              if (_index >= 0) this.$set(this.orders, _index, order)
            }
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      }
    },
    async openAgreementModal(offer) {
      const agreement = offer.contactThreadCartBox

      const threadId = agreement.contactThread.id
      const contactId = agreement.contactThread.contact.id

      const contact = await axiosIns.get(`1/contacts/${contactId}`, { params: { fields_load: this.$fields.SINGLE_CONTACT } })
      const thread = await axiosIns.get(`1/contacts/threads/${threadId}`, { params: { fields_load: this.$fields.CONTACT_SINGLE_THREAD } })

      agreement.contactThread = thread.data?.data?.item || {}
      agreement.contactThread.contact = contact.data?.data?.item || null

      this.offerAgreement = {
        ...agreement, offerId: offer.id, threadId, contactId,
      }
      this.agreement = { ...emptyAgreement }
    },
    signAgreement(agreementItem, orderId) {
      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'))
          .then(() => {
            const { allowElectronicSignature } = agreementItem

            const status = allowElectronicSignature ? 'SIGNED_MANUALLY' : 'ACCEPTED'

            const payload = {
              id: agreementItem.id,
              status,
            }

            payload.signedAt = ''
            payload.filesSigned = []
            payload.orderId = orderId

            this.offerAgreementSignManually = payload
          })
    },
    downloadPdf(fileToken, filename_) {
      this.$store.dispatch(`uploader/${DOWNLOAD_FILE}`, { fileToken, filename: filename_ })
          .then(res => {
            const { data, filename } = res
            const a = document.createElement('a')
            a.href = `data:${data.type};base64,${data.file}`
            a.setAttribute('target', '_top')
            a.download = filename
            setTimeout(() => {
              a.click()
            }, 50)
          })
    },
    /* eslint-disable */
    async updateAgreement(payload, offerId, action = 'edit') {
      this.agreementLoading = true
      if (payload?.filesSigned?.length) {
        const filesPayload = { uploadedFiles: payload.filesSigned, type: 'building' }
        const { files } = await this.$store.dispatch(`uploader/${UPLOAD_FILES}`, filesPayload)

        payload.filesSigned = files || []
      }

      if (action == 'add') {
        payload.agreementTemplate = ''
        payload.allowElectronicSignature = false
        payload.anonymised = false
        payload.signerUser = ''
        payload.contactThreadCartDraftAgreementAssignedContacts = []
        payload.contactThreadCartBox = payload.id
        delete payload.id
      }

      delete payload.offerId

        if (!payload.electronicSignatureType) {
            payload.electronicSignatureType = null
        }

      try {
        if (action !== 'add') await axiosIns.patch('1/contacts/threads/agreements', payload)
        else await axiosIns.post(`1/contacts/${payload.contactId}/threads/${payload.threadId}/agreements`, payload)

        this.getSingleOffer(offerId)
        this.showToast('success', this.$i18n.t('success.contactUpdated'))
      } catch (err) {
        this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
      } finally {
        this.offerAgreement = null
        this.agreement = { ...emptyAgreement }
        this.agreementLoading = false
        this.offerAgreementSignManually = null
      }
    },
    async loadOrders() {
      this.loading = true
      this.$nextTick(async () => {
        const payload = {
          pagination: this.pagination,
          search: this.searchQuery,
          filters: this.filters,
          threadId: this.threadId,
          sorter: this.sorter,
        }
        const action = this.threadId ? GET_THREAD_ORDERS : GET_ORDERS

        this.$store.dispatch(`offer/${action}`, payload)
          .then(res => {
            this.orders = res.data.items.map(order => {
              // eslint-disable-next-line
              order.priceGrossEnd = order.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount || 1), 0) || 0
              // eslint-disable-next-line
              order.price = order.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount || 1), 0) || 0
              return order
            })
            this.pagination.totalRecords = res.data.totalItemCount
            this.loading = false
          })
          .catch(err => {
            this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            this.loading = false
          })
      })
    },
    deleteOrder(item, index) {
      if (!item?.id) return

      this.showAlert('error', this.$i18n.t('alerts.AreYouSure'), this.$i18n.t('alerts.WontbeAble'))
          .then(async () => {
            try {
              await axiosIns.delete('1/contacts/threads/carts/orders', { data: JSON.stringify([item.id]) })

              this.$delete(this.orders, index)

              this.showToast('success', this.$i18n.t('RemovedSuccessfully'))
            } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
            }
          })
    },

      async getRecordsToExport() {
          this.exportLoading = true
          try {
              const chunksToLoad = Math.ceil(this.pagination.totalRecords / this.pagination.perPage)
              const requests = new Array(chunksToLoad).fill(null)
              const response = await Promise.all(requests.map((_, index) => {
                  const payload = {
                      pagination: { ...this.pagination, currentPage: index + 1 },
                      search: this.searchQuery,
                      filters: this.filters,
                      threadId: this.threadId,
                      sorter: this.sorter,
                  }
                  return this.$store.dispatch(`offer/${GET_ORDERS}`, payload)
              }))

              const items = []
              response.forEach(res => {
                  items.push(...res.data?.items.map(order => {
                      order.priceGrossEnd = order.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceGrossWithAllDiscount * b.amount || 1), 0) || 0
                      // eslint-disable-next-line
                      order.price = order.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => a + (b.priceWithAllDiscount * b.amount || 1), 0) || 0

                      return order
                  }))
              })

              this.$nextTick(() => {
                  this.exportRecords(items)
              })
          } catch (err) {
              this.showToast('danger', this.$i18n.t(`errors.${err?.response?.data?.message || 'UNKNOWN_BUG'}`), err)
          }
      },
      /* eslint-disable */
      async exportRecords(recordsList) {
          const wb = XLSX.utils.book_new()

          const order = [
              {
                  key: 'id', label: '#',
              },
              { key: 'number', label: 'OrderID', },
              { key: 'orderDate', label: 'OrderDate', },
              { key: 'contact', label: 'Contact', },
              { key: 'phone', label: 'Phone', },
              { key: 'email', label: 'Email', },
              { key: 'thread', label: 'contact.contactThread', },
              { key: 'products', label: 'Products',  },
              { key: 'quantity', label: 'offer.product.quantity' },
              { key: 'priceGrossEnd', label: 'offer.GrossPrice',  },
              { key: 'totalSellerDiscount', label: 'UserDiscount',  },
              { key: 'status', label: 'StatusOfOrder',  },
              { key: 'paymentType', label: 'PaymentMethod',  },
              { key: 'seller', label: 'Seller' },

              { key: 'deliveryAddressCountry', label: 'Country' },
              { key: 'deliveryAddressCity', label: 'City' },
              { key: 'deliveryAddressPostalCode', label: 'PostalCode' },
              { key: 'deliveryAddressStreet', label: 'Street' },
              { key: 'deliveryAddressNumber', label: 'Number' },
          ]

          const aoaToExport = [
              ...recordsList.map(t => order.map(({ key }) => {
                  if (key === 'quantity' && t.contactThreadCartBox?.contactThreadCartBoxProducts) return t.contactThreadCartBox.contactThreadCartBoxProducts.reduce((a, b) => +a + +b.amount, 0)
                  else if (key === 'contact' && t.contactThreadCartBox?.contactThread?.contact) return this.getUserName(t.contactThreadCartBox.contactThread.contact)
                  else if (key === 'phone' && t.contactThreadCartBox?.contactThread?.contact?.phone) return t.contactThreadCartBox?.contactThread?.contact?.phone || '-'
                  else if (key === 'email' && t.contactThreadCartBox?.contactThread?.contact?.email) return t.contactThreadCartBox?.contactThread?.contact?.email || '-'
                  else if (key === 'thread' && t.contactThreadCartBox?.contactThread) return t.contactThreadCartBox.contactThread.name
                  else if (key === 'products' && t.contactThreadCartBox?.contactThreadCartBoxProducts) return t.contactThreadCartBox.contactThreadCartBoxProducts.map(e => `${e.amount || 0}x ${this.getTranslationsField(e, 'name')}`).join(', ')
                  else if (key === 'seller' && t[key]) return this.getUserName(t[key])
                  else if (key === 'status' && t[key]) return this.$i18n.tc(`offer.status.${t[key]}`, 2)
                  else if (key === 'paymentType' && t.contactThreadCartBox?.paymentType) return this.$i18n.t(t.contactThreadCartBox.paymentType.toLowerCase())
                  else if (typeof t[key] === 'boolean') return t[key] ? 1 : 0
                  else if (t[key]?.date) return this.formatDate(t[key].date, { month: 'numeric', day: 'numeric', year: 'numeric' }, this.$i18n.locale)

                  return t[key] ?? ''
              }).flat(Infinity)),
          ]

          const headers = order.map(({ label }) => this.$i18n.t(label))
          aoaToExport.unshift(headers)

          const ws = XLSX.utils.aoa_to_sheet(aoaToExport);
          XLSX.utils.book_append_sheet(wb, ws, 'Orders');
          XLSX.writeFile(wb, 'Orders.xlsx');

          this.exportLoading = false
      },
  },
}
</script>
